export function isValidUrl(url, httpsNotNecessary = false) {
    if (httpsNotNecessary && !/^https?:\/\//i.test(url)) {
        url = "https://" + url;
    }
    const regex =
        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,})([\/\w\.-]*)*\/?(\?.*)?(#.*)?$/i;
    return regex.test(url);
}
export function isValidUrlWithoutProtocol(url) {
    const regex = /^([\da-z\.-]+)\.([a-z]{2,})([\/\w\.-]*)*\/?(\?.*)?(#.*)?$/i;
    return regex.test(url);
}

export function isValidUrlWithAndWithoutProtocol(url) {
    const regex =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[\w.-]*)*(\?.*)?(#.*)?$/i;
    return regex.test(url);
}

export function isValidUrlWithProtocols(url, checkProtocols = true) {
    // If checkProtocols is true, ensure the URL starts with http:// or https://
    if (checkProtocols && !/^(https?:\/\/)/i.test(url)) {
        return false; // Invalid if the protocol is missing and we are checking for protocols
    }

    const regex =
        /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,})([\/\w\.-]*)*\/?(\?.*)?(#.*)?$/i;
    return regex.test(url);
}

export const isValidWebhookUrl = (url) => {
    const webhookUrlRegex =
        /^(https?:\/\/)([\da-z.-]+\.[a-z.]{2,})(\/[\w\/.-]*)?(\?.*)?$/i;
    return webhookUrlRegex.test(url);
};

export function containsSpecialCharacters(input) {
    // Regular expression to match any character that is not a letter, number, or space
    const regex = /[^a-zA-Z0-9\s]/;
    // Test the input against the regex
    return regex.test(input);
}

export function containsNumericalValues(input) {
    // Regular expression to match any numerical value
    const regex = /\d/;
    // Test the input against the regex
    return regex.test(input);
}

export const checkPassword = (password) => {
    /*
     * password must be 8 to 15 characters length
     * at least one lowercase letter
     * at least one uppercase letter
     * at least one numeric digit
     * at least one special character
     */
    const pattern =
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    return pattern.test(password);
};

export function isValidWebsiteUrl(url) {
    // Check if the protocol is present and valid
    if (url?.length < 5) {
        return [false, "Error: Not valid Url"];
    }

    //--------------------------------------------------------------------------------------
    const protocolRegex = /^(https?:\/\/)/;
    if (!protocolRegex.test(url)) {
        return [
            false,
            "Error: Missing or invalid protocol (http or https is required).",
        ];
    }

    // Check if the domain and extension are valid
    const domainRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}/;
    if (!domainRegex.test(url)) {
        return [
            false,
            "Error: Invalid domain or domain extension (must be a valid domain with at least a 2-character extension).",
        ];
    }

    // Ensure no consecutive slashes after the domain
    const pathRegex =
        /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\/\s]*)?(\/[^\s]*)?$/;
    const consecutiveSlashCheck = /\/\/+/; // Checks for consecutive slashes

    // Extract the part of the URL after the domain (path, query, fragment)
    const domainEnd = url.indexOf("/", url.indexOf("//") + 2);
    const afterDomain = domainEnd !== -1 ? url.substring(domainEnd) : "";

    if (consecutiveSlashCheck.test(afterDomain)) {
        return [false, "Error: Consecutive slashes are not allowed in the path."];
    }

    // Optionally check for query parameters and fragments
    const fullRegex =
        /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\/\s]*)?(\/[^\s]*)?(\?[^\s#]*)?(#[^\s]*)?$/;
    if (!fullRegex.test(url)) {
        return [false, "Error: Invalid query parameters or fragments."];
    }

    // If all checks pass, the URL is valid
    return [true, "Valid URL"];
}