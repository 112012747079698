import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, TextField, Typography } from "@mui/material";
import CustomHintPopover from "../../../CustomHintPopover";
import { useSelector } from "react-redux";
import { GOAL_CONTENT } from "../../../../data/Constants/tooltip";
import get from "lodash/get";
const AssistantGoal = ({ handleChangeAssistantChangedData }) => {
  const assistantGoal = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.goal
  );
  const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const state=useSelector((state)=>state);
  const [goal, setGoal] = useState(assistantGoal ?? "");

  useEffect(() => {
    setGoal(assistantGoal ?? "");
  }, [assistantGoal]);

  return (
    <FormControl margin="dense" fullWidth>
      <FormLabel sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          display="flex"
          alignItems="center"
          variant="subtitle2"
          color="text.secondary"
        >
          Goal
        </Typography>
        <CustomHintPopover
          size="small"
          maxWidth={400}
          // hintTitle={'Bot Temperature Setting'}
          transformHorizontalPosition={"left"}
          transformVerticalPosition={"top"}
          anchorHorizontalPosition={"right"}
          anchorVerticalPosition={"top"}
          hintContent={GOAL_CONTENT}
        />
      </FormLabel>
      <TextField
      disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: "5px", // Applying 10px border-radius inline for the input
            padding: 0, // Setting padding to zero
            margin: 0,
            paddingBlock: 0.5,
            paddingInline: 2,
          },
          "& .MuiInputBase-input": {
            fontSize: "0.9rem", // Setting text size to match 'caption' typography (typically 12px)
          },
        }}
        size="small"
        placeholder="Make sales outbound calls"
        variant="outlined"
        minRows={1}
        multiline
        value={goal ?? ""}
        onChange={(e) => {
          handleChangeAssistantChangedData({ goal: e.target.value });
          setGoal(e.target.value);
        }}
        inputProps={{
          maxLength: 300,
        }}
        required
        fullWidth
      />
      {goal?.trim()?.length >= 300 && (
        <Typography color="error" variant="caption" paddingInline={2}>
          Only 300 characters allowed for the goal.
        </Typography>
      )}
      {goal?.trim().length <= 0 && <Typography color='error' variant="caption" sx={{paddingLeft: 1}}> Goal is missing! </Typography>}
    </FormControl>
  );
};

export default AssistantGoal;
