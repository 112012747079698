const initialState = {
    urls: [],
    totalCount: 0,
    page: 1,
    limit: 10, 
    loading: false,
    error: null,
  };
  
  export default initialState;
  