import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import ScriptExpandingCard from "../../AssistantSetup/AssistantTrain/AssistantTrainScriptCard";
import CallOpeningExpandingCard from "../../AssistantSetup/AssistantTrain/AssistantTrainCallOpeningCard";
import AssistantChooseAccents from "../AssistantGoalCard/AssistantChooseAccents";
import CustomHintPopover from "../../CustomHintPopover";
import AssistantEditableName from "./AssistantEditableName";
import AssistantGoal from "./AssistantGoal";
import AssistantWelcomeMsg from "./AssistantWelcomeMsg";
import {
  getMentionList,
  updateAssistantsChangedData,
} from "../../../data/store/assistantsSlice";
import { useDispatch, useSelector } from "react-redux";
import { log } from "../../../data/configs/utils";
import { LoadingButton } from "@mui/lab";
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import DynamicVariablesDrawer from "../../DynamicVariables";
import CustomTooltip from "../../CustomToolTipForContactList";
import DynamicVariableButton from "../../DynamicVariables/DynamicVariableButton";

const AssistantCOMandScript = ({
  preBot,
  comCorrection,
  setComCorrection,
  mentionList,
  handleChange,
  assistant,
  isSaveClicked,
  setIsSavedClicked,
  fromOnboarding,
  handleSaveChanges,
  applySampleScriptForOnboarding,
  handleTalkToAgentClick,
  setApplySampleScriptForOnboarding,
}) => {
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const { isPreBot } = state || {};
  const [goalExpanded, setGoalExpanded] = useState(false);
  const [scriptExpanded, setScriptExpanded] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const handleCallOpeningExpandClick = () => {
    setGoalExpanded(!goalExpanded);
  };
  const handleScriptExpandClick = () => {
    setScriptExpanded(!scriptExpanded);
  };

  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const showAccent = useSelector(
    (state) => state.assistantsDetails?.showAccent
  );
  const assistantUpdatedInfo = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo
  );

  useEffect(() => {
    if (isPreBot) {
      setApplySampleScriptForOnboarding(true);
      handleTalkToAgentClick();
    }
  }, [isPreBot]);

  const handleChangeAssistantChangedData = (data) => {
    dispatch(
      updateAssistantsChangedData({
        key: "assistantUpdatedInfo",
        data,
      })
    );
  };

  useEffect(() => {
    if (!_.isEmpty(assistantUpdatedInfo)) {
      setSaveLoading(true);
    } else setSaveLoading(false);
  }, [assistantUpdatedInfo]);

  return (
    <Grid container>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "20px",
          padding: 2,
          // boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Grid
          container
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Grid item xs={12}>
            {showAccent ? null : (
              <Typography color={"red"} textAlign={"right"}>
                Please attach an accent to the assistant.
              </Typography>
            )}
          </Grid>{" "}
          <Grid item xs={6}>
            <AssistantEditableName handleSaveChanges={handleSaveChanges} />
          </Grid>
          <Grid
            container
            item
            xs={6}
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}
          >
            <DynamicVariablesDrawer>
              <DynamicVariableButton />
            </DynamicVariablesDrawer>
            {pathname === "/onboarding/updateAssistant" ? (
              <Grid item>
                <Tooltip
                  placement="top"
                  arrow
                  title={
                    !assistantInitialData.script?.trim() ||
                    assistantInitialData.script === "<p><br></p>"
                      ? "Assistant script is required to test"
                      : !assistantInitialData.goal?.trim() ||
                        assistantInitialData.goal === "<p><br></p>"
                      ? "Assistant goal is required to test"
                      : !assistantInitialData.callOpeningMessage?.trim() ||
                        assistantInitialData.callOpeningMessage ===
                          "<p><br></p>"
                      ? "Call opening message is required to test"
                      : ""
                  }
                >
                  <span>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      onClick={handleTalkToAgentClick}
                      disabled={
                        !assistantInitialData?.script?.trim() ||
                        assistantInitialData.script === "<p><br></p>" || // Check for empty or "<p><br></p>"
                        !assistantInitialData?.goal?.trim() ||
                        assistantInitialData.goal === "<p><br></p>" || // Check for empty or "<p><br></p>"
                        !assistantInitialData?.callOpeningMessage?.trim() ||
                        assistantInitialData.callOpeningMessage ===
                          "<p><br></p>" // Check for empty or "<p><br></p>"
                      }
                      loading={saveLoading}
                      loadingIndicator="Saving..."
                      sx={{
                        marginBottom: "20px",
                        borderRadius: "10px",
                        color: "secondary",
                        textTransform: "none",
                      }}
                      disableElevation
                    >
                      Test
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid item>
              <AssistantChooseAccents
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
                handleSaveChanges={handleSaveChanges}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} display="flex" gap={1}>
          <Grid item xs={12}>
            <AssistantGoal
              handleChangeAssistantChangedData={
                handleChangeAssistantChangedData
              }
            />
            <Grid container item xs={12} mb={1}>
              <AssistantWelcomeMsg
                handleChangeAssistantChangedData={
                  handleChangeAssistantChangedData
                }
              />
            </Grid>
            <CallOpeningExpandingCard
              handleChangeAssistantChangedData={
                handleChangeAssistantChangedData
              }
              // script={assistant?.script}
              // comCorrection={comCorrection}
              // setComCorrection={setComCorrection}
              // isExpanded={goalExpanded}
              // handleExpandClick={handleCallOpeningExpandClick}
              // cardTitle=""
              // handleChange={handleChange}
              // assistant={assistant}
              mentionList={mentionList}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ScriptExpandingCard
            applySampleScriptForOnboarding={applySampleScriptForOnboarding}
            handleChangeAssistantChangedData={handleChangeAssistantChangedData}
            handleSaveChanges={handleSaveChanges}
            mentionList={mentionList}
            isExpanded={true}
            handleExpandClick={handleScriptExpandClick}
            cardTitle="SCRIPT"
            handleChange={handleChange}
            script={assistant?.script}
            assistant={assistant}
            isSaveClicked={isSaveClicked}
            setIsSavedClicked={setIsSavedClicked}
            fromOnboarding={fromOnboarding}
            preBot={preBot}
          />
        </Grid>
      </Card>
    </Grid>
  );
};

export default AssistantCOMandScript;
