import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Grid,
  Box,
  Button,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchallCalEventsDetails,
  getIntegrateCalDetails,
} from "../../../../data/store/integrationSlice";
import { log } from "../../../../data/configs/utils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postCreateAction } from "../../../../data/store/assistantsSlice";

const CalAccountsDialog = ({
  isOpen,
  onClose,
  handleSaveChanges,
  setIsLoading,
  bookMeetActions,
  handleDeleteActionFromCurrentAssistant,
}) => {
  const dispatch = useDispatch();
  const calAppointmentScheduling = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.calAppointmentScheduling
  );
  const assistantAllActionIds = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.assistantAllActionIds
  );
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const [isAccountList, setIsAccountList] = useState(true);
  const [accountsList, setAccountList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const calAppointmentData = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.actions
        ?.cal_appointment_scheduling?.availableAssistantActions
  );
  const accountUserId =
    calAppointmentData &&
    calAppointmentData[0]?.data?.calendarSpecificInfo?.accountUserId;

  useEffect(() => {
    if (calAppointmentScheduling) {
      dispatch(getIntegrateCalDetails())
        .unwrap()
        .then((res) => {
          setIsAccountList(true);
          setAccountList(res?.data?.accounts ?? []);
        })
        .catch((err) => {
          // console.log({err});
          // Handle error if necessary
        });
    }
  }, [dispatch, calAppointmentScheduling]);

  function selectAccount(account) {
    dispatch(
      fetchallCalEventsDetails({
        accountUserId: account.accountUserId,
        label: account.label,
      })
    )
      .unwrap()
      .then((res) => {
        setIsAccountList(false);
        setSelectedAccount(account);
        setEventList(res?.data?.events);
      })
      .catch((err) => {
        log("ERR-fetchAllCalEventsDetails", err);
      });
  }

  async function selectEvent(event) {
    const data = {
      type: "cal_appointment_scheduling",
      name: selectedAccount.name,
      description: "Appointment Scheduling for user",
      data: {
        calendar: "cal",
        calendarSpecificInfo: {
          label: selectedAccount.label,
          eventId: event.eventId,
          accountUserId: selectedAccount.accountUserId,
          accountUserName: selectedAccount.accountUserName,
          email: selectedAccount.email,
          meetingLocations: event?.meetingLocations[0],
          attendeeTimeZone: {
            value: selectedAccount.timeZone,
          },
          customBookingLink: event.customBookingLink,
        },
      },
      triggerPrompts: [],
    };

    // Dispatch the action to create the new event
    dispatch(postCreateAction(data))
      .unwrap()
      .then((res) => {
        if (res?.data?.action?._id && assistantAllActionIds) {
          const prevIdsArray = assistantAllActionIds.filter(
            (actionId) => actionId !== bookMeetActions[0]?._id
          );
          handleSaveChanges(
            true,
            {
              actions: [...prevIdsArray, res?.data?.action?._id],
            },
            true
          );
        }
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: "600px",
          width: "100%",
        },
      }}
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography align="center" variant="h6" fontWeight="bold">
          Book a Meeting
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container gap={1}>
          {isAccountList ? (
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              alignItems={"center"}
            >
              {accountsList?.length > 0 ? (
                <Box
                  sx={{ width: "100%" }}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                >
                  <Box
                    my={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    alignItems={"flex-start"}
                    width={"100%"}
                  >
                    <Typography variant="h5" fontWeight={"900"}>
                      Select Account
                    </Typography>
                    <Typography variant="body1">
                      Please choose the account from which you would like to
                      attach events to this meeting.
                    </Typography>
                  </Box>
                  {accountsList?.map((account) => (
                    <Grid
                      item
                      xs={12}
                      key={account.email}
                      p={2}
                      sx={{
                        backgroundColor: accountUserId
                          ? accountUserId === account.accountUserId
                            ? ""
                            : "background.paper"
                          : "",
                        "&:hover": {
                          backgroundColor:
                            themeMode === themeMode.DARK ? "primary.main" : "",
                          cursor: "pointer",
                        },
                        borderRadius: "10px",
                        border: "1px solid gray",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      onClick={() => selectAccount(account)}
                    >
                      <Box>
                        <Typography variant="h6" fontWeight={600}>
                          {account.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          {account.email}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          item
                          xs={1}
                          sx={{
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            aspectRatio: 1,
                            padding: 1,
                            rotate: "180deg",
                          }}
                        >
                          <ArrowBackIcon />
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                  my={8}
                >
                  <Typography variant="h5" fontWeight={"900"}>
                    No accounts found!
                  </Typography>
                  <Typography variant="body1" align="center">
                    Please check your calendar integration.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Grid container gap={3}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box
                  item
                  sx={{
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 2,
                    width: "10px",
                    height: "10px",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      cursor: "pointer",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    setIsAccountList(true);
                    setSelectedAccount({});
                  }}
                >
                  <ArrowBackIcon />
                </Box>
                <Box>
                  <Typography variant="h5">{selectedAccount?.name}</Typography>
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    sx={{ textTransform: "" }}
                  >
                    {selectedAccount?.email}
                  </Typography>
                </Box>
              </Box>
              {eventList?.length > 0 ? (
                <Grid container gap={1}>
                  <Box
                    my={2}
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1}
                    alignItems={"flex-start"}
                    width={"100%"}
                  >
                    <Typography variant="h5" fontWeight={"900"}>
                      Select an event
                    </Typography>
                    <Typography variant="body1">
                      Please choose the event from which you would like to
                      attach.
                    </Typography>
                  </Box>
                  {eventList?.map((event) => (
                    <Grid
                      key={event.eventId}
                      xs={12}
                      border={
                        bookMeetActions &&
                        bookMeetActions[0]?.data?.calendarSpecificInfo
                          ?.eventId === event?.eventId
                          ? 1
                          : 0
                      }
                      boxShadow={2}
                      p={2}
                      borderRadius={"10px"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Typography variant="h5" sx={{ fontWeight: "800" }}>
                          {event?.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={400}
                          sx={{ color: "primary.main" }}
                        >
                          {event?.meetingLength} Minutes
                        </Typography>
                      </Box>
                      <Button
                        variant="outlined"
                        onClick={() => selectEvent(event)}
                        disabled={
                          bookMeetActions &&
                          bookMeetActions[0]?.data?.calendarSpecificInfo
                            ?.eventId === event?.eventId
                        }
                      >
                        {bookMeetActions &&
                        bookMeetActions[0]?.data?.calendarSpecificInfo
                          ?.eventId === event?.eventId
                          ? "Selected"
                          : "Select"}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid xs={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={2}
                    justifyContent={"center"}
                    alignItems={"center"}
                    my={8}
                  >
                    <Typography variant="h5" fontWeight={"900"}>
                      No events found!
                    </Typography>
                    <Typography variant="body1" align="center">
                      Please create event in your cal account.
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CalAccountsDialog;
