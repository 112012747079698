import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import Box from "@mui/material/Box";
import { Button, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
// import Close from "@mui/icons-material/Close"
import { setDataInLocalStorage } from "../../data/configs/utils";
import { updatePageState } from "../../data/store/pageSlice";
// import {
//   LOCAL_STORAGE,
//   ROUTE_PATH,
//   THEME_MODE,
// } from "../../data/configs/constants";
import { LOCAL_STORAGE } from "../../data/Constants/hardData";
import { ROUTE_PATH } from "../../data/Constants/url";
import { THEME_MODE } from "../../data/Constants/theme";
import IMG_BACKGROUND from "../../data/assets/img/background.png";

import Timer from "../Timer";

const Ticker = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showTicker = useSelector((state) => state.pageDetails.showTicker);
  const userId = useSelector((state) => state.authDetails.user?.userId);

  const handleGetDeal = () => {
    if (userId) dispatch(updatePageState({ showModal: true }));
    else {
      setDataInLocalStorage(LOCAL_STORAGE.SHOW_DEAL, true);
      navigate(ROUTE_PATH.LOGIN);
      enqueueSnackbar("Please Login to Continue");
    }
  };

  // const handleCloseTicker = () => {
  //   dispatch(updatePageState({ showTicker: false }))
  // }

  if (showTicker)
    return (
      <Box
        py={2}
        px={2}
        color={
          theme.palette.mode === THEME_MODE.LIGHT
            ? "common.white"
            : "common.black"
        }
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        position="relative"
        borderBottom={0.5}
        borderColor={grey[600]}
        sx={{
          bgcolor: "primary.main",
          "&::before": {
            background: `url(${IMG_BACKGROUND})`,
            display: "block",
            content: '""',
            position: "absolute",
            opacity: 0.1,
            height: "100%",
            width: "100%",
            left: 0,
          },
        }}
      >
        <Box mr={2}>
          <Typography
            display={{ xs: "none", md: "block" }}
            fontSize={{ xs: "subtitle2.fontSize", md: "h6.fontSize" }}
            textTransform="capitalize"
            fontWeight="fontWeightBold"
          >
            Introducing our Black Friday Super Saver Plan at an Unbelievable
            <Typography
              component="span"
              color="secondary"
              fontSize={{ xs: "subtitle1.fontSize", md: "h5.fontSize" }}
              fontWeight="fontWeightBold"
            >
              60% Off.
            </Typography>
            Buy Before It's Over!
          </Typography>
          <Typography
            display={{ xs: "block", md: "none" }}
            fontSize={{ xs: "subtitle2.fontSize", md: "h6.fontSize" }}
            textTransform="capitalize"
            fontWeight="fontWeightBold"
          >
            Grab our Black Friday Super Saver Deal at an incredible
            <Typography
              component="span"
              color="secondary"
              fontSize={{ xs: "subtitle1.fontSize", md: "h5.fontSize" }}
              fontWeight="fontWeightBold"
            >
              60% Off.
            </Typography>
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="center"
          gap={{ xs: 1, md: 2 }}
        >
          <Timer />
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{
              borderRadius: 12,
              py: 0.5,
              px: 2,
              fontWeight: "fontWeightBold",
              fontSize: "subtitle2.fontSize",
              textTransform: "capitalize",
            }}
            onClick={handleGetDeal}
          >
            Claim Offer
          </Button>
        </Box>
        {/* <IconButton
          size="small"
          sx={{ position: "absolute", top: "50%", right: 4, transform: "translate(0, -50%)" }}
          color="inherit"
          onClick={handleCloseTicker}
        >
          <Close />
        </IconButton> */}
      </Box>
    );
  else return false;
};

export default Ticker;
