import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import HomeIcon from "@mui/icons-material/Home";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { showToast } from "../../data/configs/utils";
import get from "lodash/get"
import {
  updateAuthState,
  placeDemoCall,
  updateUserPhoneNumber,
  getOtpToVerifyMobileNumber,
  verifyOTP,
  demoCallAnalytics
} from "../../data/store/authSlice";
import { GTAGS,CLEVERTAP_EVENT } from "../../data/Constants/hardData";
import { ROUTE_PATH } from "../../data/Constants/url";

import { log } from "../../data/configs/utils";
import ConfettiComponent from "../ConfettiAnimation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpTextField from "../OtpTextField";
import ResendOTP from "./ResendOTP";
import { handleCleverTapBtnClickWithPathname } from "../../data/configs/utils";
import { CardContent, Card } from "@mui/material";
// import DemoCallDetailTranscript from "../DemoCallDetailTranscript";
import DemoCallDetailTranscript from "../DemoCallDetailTranscript"

const TestAssistantForm = ({ assistantId, onCancel,handlePoling }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const state=useSelector((state)=>state)
  const existingNumber=get(state,"authDetails.user.phoneNumber",null)
  // const existingNumber = useSelector(
  //   (state) => state.authDetails.user?.phoneNumber
  // );
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(existingNumber ?? "");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [verificationMsg, setVerificationMsg] = useState(
    "Enter the OTP sent to your Email and Mobile to complete verification."
  );
  const [isDemoSuccessFul, setIsDemoSuccessFul] = useState(false);
  const [isVerifyOTPEnable, setIsVerifyOTPEnable] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const modalLoading=get(state,"pageDetails.modalLoading")
 // const modalLoading = useSelector((state) => state.pageDetails.modalLoading);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
 // const user = useSelector((state) => state.authDetails.user);
  const user=get(state,"authDetails.user",null)

  const [countdown, setCountdown] = useState(10);
  const handleChangeNumber = () => {
    window.gtag("event", GTAGS.CHANGE_PHONE_NUMBER_CLICKED, {
      userID: user.userId,
    });
    setPhoneNumber("");
  };
  const [showDialog, setShowDialog] = useState(false)

  const handleSubmitNumber = () => {
    if (phoneNumberInput?.trim()) {
      handleCleverTapBtnClickWithPathname("Submit Number", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
      setPhoneNumber(phoneNumberInput);
      dispatch(updateUserPhoneNumber({ phoneNumber: phoneNumberInput }));
    }
  };

  const handleDemoCall = (name = "Place Demo Call") => {
    handleCleverTapBtnClickWithPathname(name, pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    window.gtag("event", GTAGS.PLACE_DEMO_CALL_CLICKED, {
      userId: user.userId,
      PhoneNumber: phoneNumber,
    });
    dispatch(
      placeDemoCall({
        number: phoneNumber,
        assistantId,
      })
    )
      .unwrap()
      .then((res) => {
        if (res && res.data && res.data.quotaExhausted === true) {
          navigate(ROUTE_PATH.PRICING + "?quotaExhausted=true");
        }
    handlePoling()
        enqueueSnackbar(
          res?.message ? res.message : "Your will recieve a call shortly",
          { variant: "success" }
        );

        if (res?.data?.user?.userId)
          dispatch(
            updateAuthState({
              user: res.data.user,
            })
          );
        if (
          window.location.pathname.includes(
            ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT
          ) &&
          res &&
          res?.status === "success"
        ) {
          setIsDemoSuccessFul(true);
        } else if (onCancel) onCancel();
      })
      .catch((err) => {
        if (err && err.status === 402) {
          setIsVerifyOTPEnable(true);
          handleGetOTP();
        }
        log("ERR:placeDemoCall: ", err);
      });
  };

  const handleGetOTP = () => {
    handleCleverTapBtnClickWithPathname("Get OTP", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    window.gtag("event", GTAGS.OTP_SENT, {
      userId: user.userId,
      PhoneNumber: phoneNumber,
      Otp: otp,
    });
    dispatch(
      getOtpToVerifyMobileNumber({
        number: phoneNumber,
        onBoarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.data?.verificationMessage) {
          setVerificationMsg(
            res?.data?.verificationMessage ??
            "Enter the OTP sent to your email and mobile to complete verification"
          );
        }
        setOtpSent(true);
        enqueueSnackbar(
          res?.message ? res.message : "OTP has been successfully sent"
        );
      })
      .catch((err) => log("ERR-sendOTP: ", err));
  };

  const handleResendOTP = (callback) => {
    handleCleverTapBtnClickWithPathname("Resend OTP", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    dispatch(
      getOtpToVerifyMobileNumber({
        number: phoneNumber,
        onBoarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
      })
    )
      .unwrap()
      .then((res) => {
        if (callback) callback();
        enqueueSnackbar(
          res?.message ? res.message : "OTP has been successfully sent"
        );
      })
      .catch((err) => log("ERR-resendOTP: ", err));
  };

  const handleOTPChange = (otp) => {
    setOtp(otp);

    if (otp.length === 4) {
      handleVerifyOTP(otp);
    }
  };

  const handleVerifyOTP = (otp) => {
    handleCleverTapBtnClickWithPathname("Verify OTP", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    window.gtag("event", GTAGS.OTP_VERIFIED, {
      userID: user.userID,
      OTP: otp,
      number: phoneNumber,
    });
    dispatch(
      verifyOTP({
        code: otp,
        number: phoneNumber,
        onBoarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
      })
    )
      .unwrap()
      .then((res) => {
        if (res?.data?.user?.userId) {
          dispatch(updateAuthState({ user: res.data.user }));
        }
        setIsOtpVerified(true);
        setIsVerifyOTPEnable(false);
        handleDemoCall("Call User");
      });
  };

  useEffect(() => {
    let timeoutId;
    let intervalId;

    // Check if the demo is successful and start the timeout
    if (isDemoSuccessFul) {
      timeoutId = setTimeout((e) => {
        navigate(ROUTE_PATH.ASSISTANTS, {
          state: {
            isDemoSuccessFul: true,
          },
        });
      }, 10000); // 10 seconds timeout

      // Start the interval to update the countdown every second
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    // Clear the timeout and interval if the component is unmounted or if "Go Home" is clicked
    return () => {
      clearTimeout(timeoutId);
      clearInterval(intervalId);
    };
  }, [isDemoSuccessFul, navigate]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        sx={{ position: "absolute", top: 8, right: 8 }}
        onClick={(e) =>
          isDemoSuccessFul ? navigate(ROUTE_PATH.ASSISTANTS) : onCancel(e)
        }
      >
        <CloseIcon />
      </IconButton>
      {isDemoSuccessFul ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h4" fontWeight="fontWeightMedium" mb={2}>
            <Typography
              variant="inherit"
              component="span"
              color="primary.main"
            >
              Congratulations
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              ml={1}
              sx={{
                animationName: "wave",
                animationDuration: "3s",
                animationIterationCount: "infinite",
                transformOrigin: "70% 70%",
                display: "inline-block",
                "@keyframes wave": {
                  "0%": { transform: "rotate( 0.0deg)" },
                  "10%": { transform: "rotate(20.0deg)" },
                  "20%": { transform: "rotate(-10.0deg)" },
                  "30%": { transform: "rotate(20.0deg)" },
                  "40%": { transform: "rotate(-10.0deg)" },
                  "50%": { transform: "rotate(10.0deg)" },
                  "60%": { transform: "rotate( 5.0deg)" },
                  "100%": { transform: "rotate( 0.0deg)" },
                },
              }}
            >
              😍
            </Typography>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Your Onboarding has been successfully completed.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            You will shortly recieve a call.
          </Typography>
          <Typography mb={2} sx={{ maxWidth: 300 }} align="center">
            Enjoy our Free Trial with <strong>5 minutes</strong> of{" "}
            <strong>free voice call minutes</strong> to test our product before
            subscribing
          </Typography>
          <ConfettiComponent />
          <Button
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(ROUTE_PATH.ASSISTANTS);
            }}
          >
            Go Home
          </Button>
          {isDemoSuccessFul && (
            <Typography variant="body1" color="text.secondary">
              Taking you Home in : {countdown}s
            </Typography>
          )}
        </Box>
      ) : (
        <>
          <Typography variant="h6" fontWeight="fontWeightMedium" mb={2}>
            <Typography
              variant="inherit"
              component="span"
              color="primary.main"
            >
              Talk to Your Assistant
            </Typography>
            <Typography
              variant="inherit"
              component="span"
              ml={1}
              sx={{
                animationName: "wave",
                animationDuration: "3s",
                animationIterationCount: "infinite",
                transformOrigin: "70% 70%",
                display: "inline-block",
                "@keyframes wave": {
                  "0%": { transform: "rotate( 0.0deg)" },
                  "10%": { transform: "rotate(20.0deg)" },
                  "20%": { transform: "rotate(-10.0deg)" },
                  "30%": { transform: "rotate(20.0deg)" },
                  "40%": { transform: "rotate(-10.0deg)" },
                  "50%": { transform: "rotate(10.0deg)" },
                  "60%": { transform: "rotate( 5.0deg)" },
                  "100%": { transform: "rotate( 0.0deg)" },
                },
              }}
            >
              🎉
            </Typography>
          </Typography>
          {isVerifyOTPEnable ? (
            phoneNumber ? (
              <>
                <Typography>{verificationMsg}</Typography>
                {otpSent && (
                  <ResendOTP
                    py={1}
                    variant="caption"
                    onResend={handleResendOTP}
                  />
                )}
                {otpSent && (
                  <OtpTextField
                    size={4}
                    py={2}
                    onChange={(otp) => handleOTPChange(otp)}
                  />
                )}
              </>
            ) : (
              <>
                <Typography>Phone Number</Typography>
                <Typography
                  fontSize="caption.fontSize"
                  color="text.secondary"
                  mb={0.5}
                >
                  Enter your phone number, we will send a demo call to you from
                  your assistant.
                </Typography>
                <PhoneInput
                  placeholder="Enter your phone number"
                  enableSearch
                  disableSearchIcon
                  country={"us"}
                  variant="outlined"
                  size="small"
                  onChange={(ph) => setPhoneNumberInput(ph)}
                  inputStyle={{
                    width: "100%",
                  }}
                  dropdownStyle={{
                    color: "black",
                    alignItems: "center",
                    width: "1400%",
                  }}
                  inputProps={{
                    required: true,
                    autoFocus: true,
                  }}
                />
              </>
            )
          ) : (
            <>
              {phoneNumber ? (
                <Typography>
                  Congratulations! You're ready to go! Simply click below to
                  receive your demo call at {phoneNumber}. Your assistant will
                  be in touch shortly
                </Typography>
              ) : (
                <>
                  <Typography>Phone Number</Typography>
                  <Typography
                    fontSize="caption.fontSize"
                    color="text.secondary"
                    mb={0.5}
                  >
                    Enter your phone number, we will send a demo call to you
                    from your assistant.
                  </Typography>
                  <PhoneInput
                    placeholder="Enter your phone number"
                    enableSearch
                    disableSearchIcon
                    country={"us"}
                    variant="outlined"
                    size="small"
                    onChange={(ph) => setPhoneNumberInput(ph)}
                    inputStyle={{
                      width: "100%",
                    }}
                    dropdownStyle={{
                      color: "black",
                      alignItems: "center",
                      width: "1400%",
                    }}
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </>
              )}
            </>
          )}

          <Box display="flex" justifyContent="center" gap={2} pt={2}>
            {phoneNumber ? (
              <>
                {!otpSent ? (
                  <Button
                    variant="outlined"
                    sx={{ minWidth: 100 }}
                    onClick={handleChangeNumber}
                    disableElevation
                  >
                    Change Number
                  </Button>
                ) : null}

                {isVerifyOTPEnable ? (
                  <>
                    {otpSent ? (
                      <LoadingButton
                        variant="contained"
                        sx={{ minWidth: 100 }}
                        loading={modalLoading}
                        disabled={otp?.length < 4 || modalLoading}
                        onClick={handleVerifyOTP}
                      >
                        Verify OTP
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        variant="contained"
                        sx={{ minWidth: 100 }}
                        loading={modalLoading}
                        disabled={otpSent || modalLoading}
                        onClick={handleGetOTP}
                      >
                        Get OTP
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  <LoadingButton
                    variant="contained"
                    sx={{ minWidth: 100 }}
                    loading={modalLoading}
                    disabled={modalLoading}
                    onClick={() => handleDemoCall("Start Demo Call Process")}
                  >
                    Place Demo Call
                  </LoadingButton>
                )}
              </>
            ) : (
              <>
                <LoadingButton
                  variant="contained"
                  sx={{ minWidth: 100 }}
                  loading={modalLoading}
                  disabled={modalLoading}
                  onClick={handleSubmitNumber}
                >
                  Save Number
                </LoadingButton>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 100 }}
                  onClick={() => setPhoneNumber(existingNumber ?? "")}
                >
                  Cancel
                </Button>
              </>
            )}
          </Box>
        </>
      )}
    </div>
  );
};

TestAssistantForm.propTypes = {
  data: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

TestAssistantForm.defaultProps = {
  data: {},
};

export default TestAssistantForm;
