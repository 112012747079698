import { IconButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIconButton from "../DeleteIconButton";
import DeleteIconButtonCampaignEdit from "../DeleteIconButtonCampaignEdit";
const CampaignActions = ({
  data,
  Icon,
  title,
  onEdit,
  onDelete,
  onClick,
  deleteProps,
  children,
  onClickUserAvatar,
  ...restProps
}) => {
  return (
    <Box
      minWidth={50}
      marginBlock={1}
      marginInline={2}
      display="flex"
      flexDirection="column"
    >
      {onEdit && (
        <Box
          display="flex"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(e);
          }}
        >
          <Typography variant="caption">Edit</Typography>
          <IconButton
            size="small"
            aria-label="edit"
            sx={{
              color: "inherit",
            }}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Box>
      )}
      {onDelete && (
        <DeleteIconButtonCampaignEdit
          size="small"
          aria-label="delete"
          sx={{
            color: "inherit",
          }}
          {...deleteProps}
          onDelete={onDelete}
          onClickUserAvatar={onClickUserAvatar}
        />
      )}
    </Box>
  );
};

export default CampaignActions;
