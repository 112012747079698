import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createDynamicVariable,
  getDynamicVariablesList,
} from "../../../data/store/dynamicVariableSlice";
import { enqueueSnackbar } from "notistack";
import { log } from "../../../data/configs/utils";

const DynamicVariableForm = () => {
  const dispatch = useDispatch();
  const [variable, setVariable] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setVariable(value);

    // Check if the value contains spaces
    if (/\s/.test(value)) {
      setError(true);
    } else {
      setError(false);
      setVariable(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!error) {
      const payload = {
        dynamicVariable: {
          name: variable.toLowerCase(),
          description,
        },
      };
      dispatch(createDynamicVariable(payload))
        .unwrap()
        .then((res) => {
          setVariable("");
          setDescription("");
          dispatch(getDynamicVariablesList())
            .unwrap()
            .then(() => {
              enqueueSnackbar({
                message: "Dynamic variable created successfully",
                variant: "success",
              });
            });
        })
        .catch((err) => {
          log("ERROR-create_dynamic_variable: ", err);
        });
    }
  };

  return (
    <Paper
      variant="outlined"
      mt={2}
      sx={{
        borderRadius: 1,
        padding: 2,
        backgroundColor: "background.bgContainer",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" mb={1}>
            Variable name
          </Typography>
          <TextField
            sx={{ mb: 2, borderRadius: 3 }}
            id="variable"
            type="text"
            size="small"
            placeholder="Enter variable name"
            variant="outlined"
            value={variable}
            helperText={error ? "Spaces are not allowed!" : ""}
            onChange={handleNameChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" mb={1}>
            Description
          </Typography>
          <TextField
            sx={{ mb: 3, borderRadius: 3 }}
            id="description"
            type="text"
            size="small"
            placeholder="Enter description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
          />
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!variable}
            sx={{
              textTransform: "none",
            }}
          >
            + Create
          </Button>
        </Box>
      </Grid>
    </Paper>
  );
};

export default DynamicVariableForm;
