
import GroupIcon from "@mui/icons-material/Group";
import TtyIcon from "@mui/icons-material/Tty";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CampaignIcon from "@mui/icons-material/Campaign";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HistoryIcon from "@mui/icons-material/History";
import ApiIcon from "@mui/icons-material/Api";
import AppsIcon from "@mui/icons-material/Apps";
import IMG_SIGNUP_1 from "../assets/img/signupCarousel1.png";
import IMG_SIGNUP_2 from "../assets/img/signupCarousel2.png";
import IMG_SIGNUP_3 from "../assets/img/signupCarousel3.png";
import IMG_SIGNUP_4 from "../assets/img/signupCarousel4.png";
import HUBSPOT_LOGO from "../assets/img/hubspot_logo.png";
import DARK_HUBSPOT_LOGO from "../assets/img/HubSpot-Night.png";
import DARK_GOHIGHLEVEL_LOGO from "../assets/img/gohighlevel_dark.jpg";
import GOHIGHLEVEL_LOGO from "../assets/img/gohighlevel.png";
import HUB_SPOT_ICON from "../assets/img/hub_spot_icon.png"
import GO_HIGH_LEVEL_ICON from "../assets/img/go_high_level_icon.png"
import CAL_COM_ICON from "../assets/img/cal_icon.png"
import ELEVEN_LABS_ICON from "../assets/img/eleven_labs_icon.png"
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { MenuItem } from "@mui/material";
import React from "react";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";

export const DEVELOPERSAPI_TEMPLATE = {
    initialCode: `curl --location 'https://core-saas.voicegenie.ai/api/v1/pushCallToCampaign' \
  --header 'Content-Type: application/json' \
  --data '{
      "token": "your_token_here", 
      "workspaceId": "your_workspaceId_here", 
      "campaignId": "YOUR_CAMPAIGNID_HERE", 
      "customerNumber": "+10909090909", 
      "customerInformation": { 
          "first_name" : "john",
          "last_name" : "wick"
      }
  }'`,
    description: `This is an example cURL request that demonstrates adding a call to a recurring campaign.`,
    linkUrl: `https://voicegenie.gitbook.io/voicegenie-ai/product-guides/campaigns/types-of-campaign/recurring-campaign`,
    linkText: `[click here to know more about recurring campaigns]`,
};

export const DEVELOPERSAPI_DOCS_LINK = `https://voicegenie.gitbook.io/voicegenie-ai/developer-documentation-1.0`;
export const BREAKPOINT_VALUES = Object.freeze({
    xs: 0,
    sm: 600,
    lg: 1200,
    md: 900,
    xl: 1536,
});
// need to look at this
export const GTAGS = Object.freeze({
    USE_EXISTING_TEMPLATE: "Use-Existing-Template",
    SCRIPT_MANUALLY_CREATED: "Script-Manually-Changed",
    ASSISTANT_LANGUAGE_CHANGED: "Assistant-Language-Changed",
    ASSISTANT_GENDER_CHANGED: "Assistant-Gender-Changed",
    CALL_OPENING_MESSAGE_CHANGED: "Call-Opening-Message-Changed",
    ASSISTANT_TEST_BUTTON_CLICKED: "Assistant-Test-Button-Clicked",
    UPGRAGE_OPTION_CLICKED: "Upgrade-Option-Clicked",
    ADD_NEW_ASSISTANT_CLICKED: "Add-New-Assistant-Clicked",
    ASSISTANT_TAB_CHANGED: "Assistant-Tab-Changed",
    ASSISTANT_COMAPANY_NAME_CHANGED: "Assistant-Company-Name-Changed",
    ASSISTANT_GOAL_CHANGED: "Assistant-Goal-Changed",
    ASSISTANT_NAME_CHANGED: "Assistant-Name-Changed",
    CHANGE_PHONE_NUMBER_CLICKED: "Change-Phone-Number-Clicked",
    OTP_VERIFIED: "Otp-Verified",
    RESEND_EMAIL_CLICK: "Resend-Email-Click",
    SUCCESSFUL_SIGN_IN: "Successful-Sign-in",
    SUCCESSFUL_SIGN_UP: "Successful-Sign-up",
    SIGN_UP_LINK_CLICK: "Sign-Up-Link-Click",
    SIGN_UP_BUTTON_CLICK: "Sign-Up-Button-Click",
    SIGN_IN_BUTTON_CLICK: "Sign-In-Button-Click",
    FORGOT_PASSWORD_CLICK: "Forgot-Password-click",
    PLACE_DEMO_CALL_CLICKED: "Place-Demo-Call-Clicked",
    OTP_SENT: "OTP-Sent",
    TEST_ASSISTANT_CLICKED: "Test-Assistant-Clicked",
    CREATE_CUSTOM_ASSISTANT_CLICKED: "Create-Custom-Assistant-Clicked",
    TESLA_BOT_CLICKED: "Tesla-Bot-Clicked",
    BASIC_INFORMATION_SUBMITTED: "Basic-Information-Submitted",
    SKIP_ONBOARDING_CLICKED: "Skip-Onboarding-Clicked",
    LETS_GO_TO_SURVEY_PAGE: "Lets-Go-To-Survey-page",
    THEME_CHANGED: "Theme-Changed",
    USER_LOGGED_OUT: "User-Logged-Out",
    COMMUNITY_PAGE_VIEWED: "Community-Page-Viewed",
    ROADMAPS_PAGE_VIEWED: "Roadmaps-Page-Viewed",
    FEATURE_REQUEST_PAGE_VIEWED: "Feature-Request-Page-Viewed",
    CHANGE_PASSWORD_CLICKED: "Change-Password-Clicked",
    EDIT_PROFILE_OPENED: "Edit-Profile-Opened",
    FEEDBACK_FORM_OPENED: "Feedback-Form-Opened",
    APP_SUMO_CODE: "vg-app-sumo-code",
    GOHIGH_LEVEL_CODE: "vg-gohigh-code",
    APP_VERSION: "vg-app-version",
    APP_USER: "vg-app-user",
    AUTH_TOKEN: "vg-auth-token",
    THEME: "vg-theme-mode",
    SHOW_DEAL: "vg-show-deal",
    AUTH_SEARCH_PARAMS: "vg-auth-search-params",
    IS_NEW_USER: "vg-is-new-user",
    DOCUMENTATION_VISITED: "Documentation-Visited",
});

export const LOCAL_STORAGE = Object.freeze({
    ELEVEN_LABS_CLONED_AGREEMENT: "vg-app-elevenlabs-cloned-voice-agreement",
    APP_SUMO_CODE: "vg-app-sumo-code",
    GOHIGH_LEVEL_CODE: "vg-gohigh-code",
    APP_VERSION: "vg-app-version",
    APP_USER: "vg-app-user",
    AUTH_TOKEN: "vg-auth-token",
    THEME: "vg-theme-mode",
    SHOW_DEAL: "vg-show-deal",
    AUTH_SEARCH_PARAMS: "vg-auth-search-params",
    IS_NEW_USER: "vg-is-new-user",
    UTM_PARAMS: "utm-params",
    SUBSCRIPTION_PLAN_ID: "priceId",
    IS_TEST_MODE_ENABLE: "is_test_mode_enable",
});

// export const INTEGRATIONS_LIST = [
//     {
//         darklogo: DARK_HUBSPOT_LOGO,
//         logo: HUBSPOT_LOGO,
//         name: "Hubspot",
//         // intergratingApi: installHubSpot
//     },
//     {
//         darklogo: DARK_GOHIGHLEVEL_LOGO,
//         logo: GOHIGHLEVEL_LOGO,
//         name: "GoHighLevel",
//         // intergratingApi: installGoHighLevel
//     },
// ];
export const INTEGRATIONS_LIST = [
    {
      darklogo: DARK_HUBSPOT_LOGO,
      logo: HUBSPOT_LOGO,
      icon: HUB_SPOT_ICON,
      name: "Hubspot",
      label:'hubspot',
      description:
        "HubSpot is a customer engagement platform that helps businesses manage their customer data and drive growth.",
    },
    {
      darklogo: DARK_GOHIGHLEVEL_LOGO,
      logo: GOHIGHLEVEL_LOGO,
      icon:GO_HIGH_LEVEL_ICON,
      name: "GoHighLevel",
      label:'gohighlevel',
      description:
        "GoHighLevel is a powerful, flexible, and easy-to-use voice assistant that helps you automate your business processes.",
    },
    {
      darklogo: DARK_GOHIGHLEVEL_LOGO,
      logo: GOHIGHLEVEL_LOGO,
      icon: CAL_COM_ICON,
      name: "Cal.com",
      label:'cal',
      description:
        "Cal.com is a popular, cloud-based appointment scheduling and scheduling tool.",
    },
    {
      darklogo: DARK_GOHIGHLEVEL_LOGO,
      logo: GOHIGHLEVEL_LOGO,
      icon:ELEVEN_LABS_ICON,
      name: "Eleven Labs",
      label:'elevenLabs',
      description:"Eleven Labs is a cloud-based, scalable, and AI-powered virtual assistant platform.",
    },
  ];

export const METHOD_TYPES = Object.freeze({
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
    PATCH: "PATCH",
});
export const TTSPROVIDER_TYPES = Object.freeze({
    TOPPICKS: "topPicks",
    AZURE: "azure",
    ELEVENLABS_FREE: "elevenlabs_free",
    ELEVENLABS: "elevenlabs",
});
export const ACCENT_TYPES = Object.freeze({
    TOPPICKS: "topPicks",
    VOICEGENIE: "voicegenie",
    ELEVENLABS_FREE: "elevenlabs",
    YOUR_ELEVENLABS: "yourElevenLabs",
});

export const MODULE_CONFIGURATIONS = Object.freeze({
    CAMPAIGNS_LOAD_MORE_LIMIT: 10,
    CAMPAIGNS_OVERVIEW_CALL_DATA_LIMIT: 20,
    MAX_FILE_SIZE_KNOWLEGDE_BASE: 2 * 1024 * 1024
});

export const CAMPAIGN_STATUS = Object.freeze({
    PENDING: "pending",
    IN_PROGRESS: "inProgress",
    STOPPED: "stopped",
    FINISHED: "completed",
    SYSTEM_PAUSED: "systemPaused",
});

export const CAMPAIGN_OUTBOUND_CALL_STATUS = Object.freeze({
    PENDING: "pending",
    DIALED: "dialed",
    IN_QUEUE: "enqueue",
    COMPLETED: "Ended",
    IN_PROGRESS: "inProgress",
    FAILED: "failed",
    STOPPED: "stopped",
    WAITING: "waiting",
    COMPLETE: "answered",
    REJECTED: "rejected",
    NO_ANSWER: "no-answer",
    BUSY: "busy"
});

export const ONBOARDING_STATE = Object.freeze({
    WELCOME: "welcome",
    MICRO_SURVEY: "survey",
    CREATE_ASSISTANT: "createAssistant",
    TEST_ASSISTANT: "testAssistant",
});

export const ASSISTANT_TRAINING_STATUS = Object.freeze({
    IN_QUEUE: "Training In Queue",
    IN_PROGRESS: "In Progress",
    SUCCESS: "Success",
    FAILED: "Failed",
});

export const SUPER_SAVER_DEAL = Object.freeze({
    START_TIME: new Date("24 Nov 2023 16:00:00").getTime(),
    END_TIME: new Date("07 Dec 2023 16:00:00").getTime(),
    PLAN_NAME: "SuperSaver Deal",
});

export const SUBSCRIPTION_STATUS = Object.freeze({
    ACTIVE: "active",
    CANCELED: "canceled",
});

export const CLEVERTAP_EVENT = {
    PAGE_VISIT: "Page Visiting",
    BUTTON_CLICK: "Button Clicked",
    BILLING_VISIT_TYPE: {
        SUBSCRIBE: "Subscription",
    },
    CONTACT_US: "Contact Us",
};

export const SIGNUP_CAROUSEL = [
    {
        IMAGE_PATH: IMG_SIGNUP_1,
    },
    {
        IMAGE_PATH: IMG_SIGNUP_2,
    },
    {
        IMAGE_PATH: IMG_SIGNUP_3,
    },
    {
        IMAGE_PATH: IMG_SIGNUP_4,
    },
];
export const FILTER_OPTIONS = [
    { value: "gte", label: "Greater Than" },
    { value: "lte", label: "Less Than" },
    { value: "eq", label: "Equal to" },
    { value: "btw", label: "Between" },
]

export const CUSTOM_SMS = "custom_sms"
export const ERROR_MESSAGES = {
    messageBody: "Please enter the message body.",
    messageName: "Please enter the message name",
    triggerPrompts: `Entered 'Triggering Prompt' not added. Click 'Add' to include it.`,
};
export const ANSWER_TYPE = ['Boolean', 'Number', 'Date', 'Text']
export const ANSWER_TYPES = ['Boolean', 'Number', 'Date', 'Text', 'Selector']
export const INITIAL_ENTITIES = ["email", "name", "location"];
export const PAGE_STEPS = {
    phoneNumberTwilio: {
        title: "Follow these steps to buy your Twilio Phone Number",
        steps: [
            "Click on 'Go To Twilio' button. Sign up your Twilio account.",
            'Go to "Phone Numbers" in the left panel.',
            'Select "Manage" then "Buy a Number".',
            'Choose any number of your choice and click on "Buy". Now Agree with the T&C and click on Buy.',
            "Click on your account name (top left), then scroll down to view your Account SID and Auth Token.",
            'To add this number to VoiceGenie, click on "Add Phone Number" button above & enter the details and Click on "Save".',
        ],
    },
    phoneNumberPlivo: {
        title: "Follow these steps to buy your Plivo Phone Number",
        steps: [
            "Click on 'Go To Plivo' button. Sign up to your Plivo account. Select 'Phone Numbers' in the left panel. Click on 'Buy Number'",
            "Fill in the details and click on 'Search'. Select any number of your choice & click on 'Buy Number & Continue'. Enter details & click on 'Configure Number'",
            "Once configured, you can access your new phone number. You can easily find the Auth ID & Auth Token in the Overview Section of Plivo",
            "To add this number to VoiceGenie, click on 'Add Phone Number' button above & enter the details and Click on 'Save'",
        ],
    },
};

export const SCRIPT_TEMPLATES = {
    "existing script": `[Wait for the user response] 
    
    Understanding Needs: Dive into a conversation about what they're looking for in a Tesla vehicle. 
    
    Use open-ended questions to gauge their preferences, such as, "What features are you looking for in a car?" or "What's most important to you in a vehicle?
    
    "Once User tells the requirement, Model Suggestion: Based on their requirements, suggest the most suitable Tesla model. Keep your explanation brief but informative.
    
    Encourage Test Drive: If the lead shows interest, encourage them to book a test drive. 
    
    Tell them, "Great choice! Would you like to experience the Model firsthand with a test drive?
    
    "If user agrees, Information Verification: If a user is willing to book a test drive, politely confirm the customer's details by stating the information you have and asking them to verify it. 
    
    For example, "I have your name as Nester, email address as abc@gmail.com and your contact number as 6465685669, is that correct?
    
    "Once User gives personal information,Booking Forwarding: Inform the customer that a Tesla representative will contact them to finalize the test drive details. 
    
    You might say, "I'm excited you're interested in a test drive. A Tesla representative will reach out shortly to confirm your booking details.
    
    "Conversation Closure:End the call on a positive note, expressing appreciation for their time and interest.`,
};

export const SCRIPT_GUIDELINES = `[Open with a Warm Welcome]
  
  "Hello first_name thank you for reaching out! I'm here to assist you. Could you share a bit about what you're looking for or how I might help?"
  
  Goal: Start with a friendly, open-ended question to understand the customer's needs and set a positive tone.
  
  
  2. Explore Customer Needs
  
  "Could you tell me a bit more about what's most important to you in this area? For example, some customers look for [benefits or features relevant to your service, such as reliability, affordability, customization, etc.]."
  
  
  Goal: Invite the customer to elaborate on their needs and priorities without focusing on a specific product.
  
  
  3. Offer Relevant Options or Solutions
  
  "Thank you for sharing that! Based on what you've mentioned, we have some options that might be a great fit for you. Would you like to hear more about these?"
  
   Goal: Keep the response general, offering solutions or guidance that align with their expressed preferences.
  
  
  4. Encourage Next Steps (If Applicable)
  
  "Many of our customers find that taking [next step, like exploring more details, trying a demo, or setting up a consultation] is helpful. Would you be interested in moving forward with this?"
  
  
     Goal: Suggest a logical next step to help the customer learn more or take action, without assuming specific interests.
  
  
  5. Confirm Contact Information (If Needed)
  
  "To assist you further, could you please confirm your contact details? This will allow us to follow up with any additional information you may need."
  
  
     Goal: Gather or verify contact information respectfully to enable any follow-up.
  
  
  6. Wrap Up with Gratitude
  
  "Thank you so much for your time today first_name. We're here to help anytime, so please don't hesitate to reach out with any questions or for further assistance!"
  `;

export const EXISTING_SCRIPT_CALL_OPENING_MESSAGE = `<p>Hi <span id="ts-mention-blot" class="ts-mention" data-name="first_name" data-id="1234">﻿<span contenteditable="false">first_name</span>﻿</span>, This is James, calling from Tesla. How are you doing today?</p>`;
export const EXISTING_SCRIPT_GOAL =
    "To convince users to book a test ride and give all their queries about Tesla.";



export const TOGGLE_BUTTON = {
    toggleButtonDefault: "Twilio",
    toggleButtonArray: ["Twilio", "Plivo"],
};
export const PHONE_NUMBER_DELETE_FAILED = "You have a campaign in Progress which uses this phone Number";

export const PAGE_HEADER_CONTENT = {
    Assistant: {
      title: "Voice Assistants",
      tooltip: "Build a personalized voice assistant that understands your needs and interacts with your users seamlessly. Whether it’s automating tasks, enhancing customer engagement, or providing tailored information, your voice assistant will be fully customizable to your preferences. Let us help you bring your ideas to life with advanced voice technology that supports a variety of accents and languages.",
      name: "Assistant",
      id: "search-assistants",
      placeholder: "Search assistants...",
      buttontext:"Create an AI Voice Assistant",
    },
    PhoneNumber: {
      title: "Phone Numbers",
      tooltip: "Acquire a Dialer (Twilio/Plivo) Phone Number to connect with your users. This will facilitate your outbound and inbound calls, ensuring seamless communication.",
      name: "Phone Numbers",
      id: "search-phone-numbers",
      placeholder: "Search phone numbers...",
      buttontext: "Add Phone Number"
    },
    Campaigns: {
      title: "Campaigns",
      tooltip: "Campaigns are where you bring everything together including Assistant, Phone Number and Contact List. Using Campaigns you reach your contacts and gather post call insights.",
      name: "Hubspot",
      id: "search-campaign",
      placeholder: "Search campaign...",
      buttontext:"Create New Campaign"
    },
    Inbound: {
      title: "Inbound",
      tooltip: "IN-Bound page description with unique searchable text asdfasdasdfsfd",
      name: "Inbound",
      id: "search-inbound",
      placeholder: "Search campaign...",
      buttontext:"Create New Campaign"
    },
    ContactLists: {
      title: "Contact List",
      tooltip: "Simplify audience segmentation by uploading your contact lists. This empowers you to categorize & target your customers effectively.",
      name: "Contact List",
      id: "search-contactlist",
      placeholder: "Search contact list...",
      buttontext:"Add New Contact List",
    },
    CallHistory: {
      title: "Call History",
      tooltip: "Track and review the history of all calls made through your system. Gain insights into call volume, duration, and outcomes to optimize your communication strategies.",
      id: "search-history",
      placeholder: "Search History",
    },
    Pricing: {
      title: "Pricing",
      tooltip: "Explore our range of plans, each designed to meet your specific business requirements. Choose the one that perfectly aligns with your needs.",
      name: "pricing",
      id: "",
      placeholder: "",
    },
    KnowledgeBase: {
      title: "KnowledgeBase",
      tooltip: "Enrich your bot's capacity to respond to your audience's queries by providing training data & scripts. Customize your knowledge base, and effortlessly connect it to your assistant.",
      name: "KnowledgeBase",
      id: "",
      placeholder: "",
      buttontext:"Create a Knowledge Base",
    },
  };