import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import { CREATE_DYNAMIC_VARIABLE, GET_ALL_DYNAMIC_VARIABLES } from "./api";
import { METHOD_TYPES } from "../../Constants/hardData";

const dynamicVariableSlice = createSlice({
  name: "dynamicVariables",
  initialState,
  reducers: {
    updateDynamicVariablesState(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDynamicVariablesList.pending, (state) => {
        state.loading = true;
        state.loadError = false;
      })
      .addCase(getDynamicVariablesList.fulfilled, (state, { payload }) => {
        if (payload?.data?.dynamicVariables)
          state.dynamicVariables = payload.data.dynamicVariables;
        state.loading = false;
        state.loadError = false;
      })
      .addCase(getDynamicVariablesList.rejected, (state) => {
        state.loading = false;
        state.loadError = true;
      })
      .addCase(createDynamicVariable.pending, (state) => {
        state.loading = true;
        state.loadError = false;
      })
      .addCase(createDynamicVariable.fulfilled, (state, { payload }) => {
        if (payload?.data?.dynamicVariables)
          state.dynamicVariables = payload.data.dynamicVariables;
        state.loading = false;
        state.loadError = false;
      })
      .addCase(createDynamicVariable.rejected, (state) => {
        state.loading = false;
        state.loadError = true;
      });
  },
});

export const createDynamicVariable = createAsyncThunk(
  "/assistant/createScriptWithAI",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_DYNAMIC_VARIABLE,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const getDynamicVariablesList = createAsyncThunk(
  "/dynamic-variables/get",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_DYNAMIC_VARIABLES,
        method: METHOD_TYPES.GET,
        data,
        modalLoader: true,
        forceFEHideEnquebar: true,
      },
      helpers
    )
);

export const {
  updateDynamicVariablesState,
} = dynamicVariableSlice.actions;
export default dynamicVariableSlice.reducer;
