import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import { CRAWL_STATUS } from "../knowledgeBasesSlice/api";
import { METHOD_TYPES } from "../../Constants/hardData";
import initialState from "./initialState"

export const getScrapedUrls = createAsyncThunk(
  "knowledgeBases/getCrawlStatus",
  async ({ knowledgeBaseId, page, limit }, helpers) => 
    fetchDataAndProceedWithToolkit(
      {
        url: `${CRAWL_STATUS}?knowledgeBaseId=${knowledgeBaseId}&page=${page}&limit=${limit}`,
        method: METHOD_TYPES.GET,
        loader: false, 
      },
      helpers
    )
);

const scrapedUrlsSlice = createSlice({
  name: "scrapedUrls",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    deleteScrapedUrl: (state, action) => {
      state.urls = state.urls.filter((url) => url !== action.payload);
      state.totalCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getScrapedUrls.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getScrapedUrls.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.urls = payload?.data?.notConnectedUrls;
        state.totalCount = payload?.data?.totalCount;
      })
      .addCase(getScrapedUrls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setPage, deleteScrapedUrl } = scrapedUrlsSlice.actions;
export default scrapedUrlsSlice.reducer;
