import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Box,
  CardHeader,
  TextField,
  Dialog,
  DialogContent,
  keyframes,
  DialogActions,
  DialogTitle,
  Tooltip,
  IconButton,
  Popover,Menu,MenuItem,Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SettingsIcon from "@mui/icons-material/Settings";
import { CLEVERTAP_EVENT,GTAGS } from "../../data/Constants/hardData";
import { ROUTE_PATH } from "../../data/Constants/url";
import { cleverTap } from "../../data/configs/clevertap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../ErrorBoundary";
import TestAssistantForm from "../TestAssistantForm";
import { useState } from "react";
import CustomHintPopover from "../CustomHintPopover";
import { demoCallAnalytics } from "../../data/store/authSlice";
import { useDispatch } from "react-redux";
import { log, showToast } from "../../data/configs/utils";
import CampaignActions from "../CampaignActions";
import { createAssistant } from "../../data/store/assistantsSlice";
import CopyAllIcon from '@mui/icons-material/CopyAll';
export default function AssistantCard({
  onClick,
  assistant = {},
  handleEditAssistant,
  handleDeleteAssistant,
}) {
  const pop = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;
  const [dialogData, setDialogData] = useState({});
  const [dialogDataForDelete, setDialogDataForDelete] = useState(false);
  const { pathname } = useLocation();
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  const user = useSelector((state) => state.authDetails.user);
  const [showPolingDialog, setShowPolingDialog] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const workspaceId = useSelector(
    (state) => state.authDetails?.user?.workspace
  );
  function strShortener(str = "") {
    if (str.length > 80) {
      return `${str.slice(0, 80)}...`;
    } else {
      return str;
    }
  }

  const handleTalkToAgentClick = (e) => {
    e.stopPropagation();
    window.gtag("event",GTAGS.ASSISTANT_TEST_BUTTON_CLICKED,{
      userID:user.userID,
      WorkspaceID: workspaceId,
      buttonName: "Test Your Assistant",
    })
    cleverTap.track(CLEVERTAP_EVENT.BUTTON_CLICK, {
      workspaceId,
      buttonName: "Test Your Assistant",
      onboarding: pathname === ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT,
    });
    setDialogData({
      showTestAssistant: true,
      testAssistant: {},
    });
  };

  const handleCloseDialoge = (e, reason) => {
    if (reason !== "backdropClick") setDialogData({});
  };
  const handleCloseDialogeForDelete = (e) => {
    setDialogDataForDelete(false);
  };
  const handlePoling = () => {
    const searchParams = new URLSearchParams(location.search);
    const assistantId = searchParams.get("id");
    if(assistantId)(
    showToast(
      <Box px={4}>
        <Typography variant="button" textAlign={"left"} >
          Your call is in Progress...
        </Typography>
      </Box>
    ))
    let intervalId = setInterval(
      () => handleFetchPollingData(intervalId),
      10000
    )
  };
  const handleFetchPollingData = (intervalId) => {
    const searchParams = new URLSearchParams(location.search);
    const assistantId = searchParams.get("id");
    if(assistantId)(
    dispatch(demoCallAnalytics({ assistantId }))
      .unwrap()
      .then((res) => {
        if (res?.data?.status === "Ended") {
          setShowPolingDialog(true);
          clearInterval(intervalId);
          showToast();
          intervalId = null;
        }
      }));
  };
  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloneAssistant = (assistant) => {
    dispatch(createAssistant({ assistantId: assistant?.assistantId , isCloning : true })); 
    handleMenuClose();
  };
  
  return (
    <>
      <Card
        onClick={onClick}
        sx={{
          minHeight: "182px",
          display: "flex",
          flexDirection: "column",
          cursor: onClick ? "pointer" : "default", // Corrected the condition
          minWidth: 100,
          paddingBottom: 1,
          paddingLeft: 1,
          boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
        }}
      >
        <CardHeader
          variant="subtitle1"
          sx={{
            borderBottom: 1,
            paddingBlock: 1,
            paddingInline: 0,
            borderColor: "#d3d3d3",
          }}
          title={
            <Grid
              container
              alignItems="center"
              sx={{ paddingLeft: 1, paddingRight: 1 }}
            >
              <Grid item xs={10}>
               <Tooltip title={assistant?.name && assistant?.name.length > 25 ? assistant?.name : ""} placement="top"> <Typography sx={{ fontSize: "1.35rem", fontWeight: "bold" , mr:2 ,display:"flex"}}   
                >
                {assistant?.name && assistant?.name.length > 25
                  ? `${assistant?.name.slice(0, 25)}...`
                  : assistant?.name || "The Bot"}
                { assistant?.defaultAssistant && ( 
                  <Chip
                                            size="small"
                                            color="success"
                                            sx={{
                                              borderTopRightRadius: 16,
                                              borderBottomRightRadius: 16,
                                              ml: 1.5, p: 1, mt:0.6
                                            }}
                                            label="Default"
                                          />
              ) }  </Typography></Tooltip> 
              </Grid>
                <Popover
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <CampaignActions
                                />
                              </Popover>
                              <Grid
                              container
                              item
                              xs={2}
                              justifyContent="center"
                              alignItems="center"
                            >
                       <Grid container item xs={2} justifyContent="center" alignItems="center" ml={5}>
                        <IconButton onClick={handleMenuOpen} >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                          onClick={(e) => e.stopPropagation()} 
                        >
                          <Tooltip title="Clone this Assistant" placement="top">
                            <MenuItem onClick={() => handleCloneAssistant(assistant)}>
                              <Typography display="flex" alignItems="center" gap={1}>
                                <CopyAllIcon fontSize="small" />
                                <Typography mb={0.5}>Clone</Typography> 
                              </Typography>
                            </MenuItem>
                          </Tooltip>

                          {!assistant?.defaultAssistant && (
                            <Tooltip title="Delete" placement="top">
                              <MenuItem onClick={() => setDialogDataForDelete(true)}>
                                <Typography display="flex" alignItems="center" gap={1}>
                                  <DeleteIcon
                                    fontSize="small"  color="error"
                                    sx={{
                                      "&:hover": { animation: `${pop} 0.3s ease-in-out forwards` }, 
                                    }}
                                  />
                                  Delete
                                </Typography>
                              </MenuItem>
                            </Tooltip>
                          )}
                        </Menu>
                      </Grid>
                {/* <Grid item>
                  <SettingsIcon
                    fontSize="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditAssistant();
                    }}
                    sx={{
                      "&:hover": {
                        animation: `${pop} 0.3s ease-in-out forwards`,
                      },
                    }}
                  />
                </Grid> */}

              </Grid>
            </Grid>
          }
        />
        <CardContent sx={{ flexGrow: 1, padding: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", paddingLeft: 1 }}
                color="text.primary"
              >
                Goal
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingRight: 1 }}>
              <Typography
                variant="body2"
                paragraph="none"
                marginBottom={0}
                marginBlock={0.5}
                sx={{ paddingLeft: 1 }}
              >
                {assistant?.goal ? (
                  strShortener(assistant?.goal)
                ) : (
                  <Typography
                    variant="caption"
                    color="main.secondary"
                    sx={{ fontWeight: "bold" }}
                  >
                    Add assistant goal
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <Grid container display="flex" gap={1}>
         { assistant?.defaultAssistant ?  null : (  <Grid>
              <Button size="small" variant="contained" sx={{ width: "100%" }}>
                <Typography
                  variant="caption"
                  display="flex"
                  alignItems="center"
                  sx={{color: "themetext.primary"}}
                >
                  Setup
                </Typography>
              </Button>
            </Grid>)}
            <Grid>
              <Tooltip
                title={
                  !assistant.script?.trim() ||
                  assistant.script === "<p><br></p>"
                    ? "Assistant script is required to test"
                    : !assistant.goal?.trim() ||
                      assistant.goal === "<p><br></p>"
                    ? "Assistant goal is required to test"
                    : !assistant.callOpeningMessage?.trim() ||
                      assistant.callOpeningMessage === "<p><br></p>"
                    ? "Call opening message is required to test"
                    : ""
                }
              >
                <span>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={
                      !assistant?.script?.trim() ||
                      assistant?.script === "<p><br></p>" ||
                      !assistant?.goal?.trim() ||
                      assistant?.goal === "<p><br></p>" ||
                      !assistant?.callOpeningMessage?.trim() ||
                      assistant?.callOpeningMessage === "<p><br></p>"
                    }
                    onClick={handleTalkToAgentClick}
                  >
                    <Typography
                      fontWeight="bold"
                      variant="caption"
                      display="flex"
                      alignItems="center"
                    >
                      Test
                    </Typography>
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        PaperProps={{
          sx: {
            overflowY: "visible",
          },
        }}
        aria-labelledby="vg-test-assistant"
        open={
          dialogData.showTestAssistant || dialogData.showVoiceAccent || false
        }
        onClose={handleCloseDialoge}
        fullWidth
      >
        <DialogContent sx={{ overflowY: "visible" }}>
          {dialogData?.showTestAssistant && (
            <ErrorBoundary onErrorCallback={handleCloseDialoge}>
              <TestAssistantForm
                assistantId={assistant?.assistantId}
                onCancel={handleCloseDialoge}
                handlePoling={handlePoling}
              />
            </ErrorBoundary>
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        aria-labelledby="vg-delete-assistant"
        open={dialogDataForDelete}
        onClose={handleCloseDialogeForDelete}
        fullWidth
      >
        <DialogTitle fullWidth sx={{ justifyContent: "center" }}>
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography align="center">
            Do you really want to delete this
            <strong>({assistant?.name})</strong> assistant?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: "center" }}>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleCloseDialogeForDelete();
            }}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteAssistant();
              handleCloseDialogeForDelete();
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
