import { Box, Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";

const DynamicVariableButton = () => {
  return (
    <Tooltip
      title={
        <Box sx={{ margin: 1 }}>
          <Typography variant="subtitle2">
            <strong>Add / Remove</strong> more <strong>variables</strong> to
            contacts. These <strong>variables</strong> can be injected into the
            assistant script using <strong>@ symbol</strong>.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            During call the AI assistant will pick values according to the
            individual contact and will give a personalised experience to the
            customer.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            <strong>Example :</strong> Address, Purchase Date, Time, etc.
          </Typography>
        </Box>
      }
    >
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
          gap: 1,
        }}
      >
        <DynamicFeedIcon />
        Dynamic Personalization
      </Button>
    </Tooltip>
  );
};

export default DynamicVariableButton;
