import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";

const DeleteIconButtonCampaignEdit = ({
  onClickUserAvatar,
  title,
  description,
  onDelete,
  ...restProps
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "vg-delete-icon-button" : undefined;

  const handleButtonClick = (e) => {
    if (e) e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation();
    onClickUserAvatar(e);
    setAnchorEl(null);
  };

  const handleConfirmation = (e) => {
    e.stopPropagation();
    handleClose();
    onDelete();
  };

  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      onClick={handleButtonClick}
      sx={{ cursor: "pointer" }}
    >
      <Typography variant="caption">Delete</Typography>
      <IconButton size="small" {...restProps}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              display: "flex",
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ErrorIcon color="error" fontSize="medium" />
        <Box pl={1.5}>
          <Typography variant="body1" fontWeight="fontWeightMedium">
            {title}
          </Typography>
          <Typography mb={1.5}>{description}</Typography>
          <Button
            variant="contained"
            size="small"
            sx={{ mr: 1.5 }}
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleConfirmation}
          >
            Yes
          </Button>
        </Box>
      </Popover>
    </Box>
  );
};

DeleteIconButtonCampaignEdit.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onDelete: PropTypes.func,
};

DeleteIconButtonCampaignEdit.defaultProps = {
  title: "Delete this item",
  description: "Are you sure to delete this item?",
};

export default DeleteIconButtonCampaignEdit;
