import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import initialState from "./initialState"
import { fetchDataAndProceedWithToolkit } from "../helpers"
import { GET_CALL_HISTORY_LIST,DELETE_CALL_HISTORY } from "./api"
import { METHOD_TYPES } from "../../Constants/hardData";
const callHistorySlice = createSlice({
  name: "callHistory",
  initialState,
  reducers: {
    updateCallHistoryState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCallHistoryList.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaignCallData) {
          state.callHistoryList = payload.data.campaignCallData
          state.totalCount = payload?.data?.documentCount
        }
        state.loading = false
        state.loadError = false
      })
      .addCase(getCallHistoryList.pending, (state) => {
        if (state.callHistoryList.length === 0) {
          state.loading = true
          state.loadError = false
        }
      })
      .addCase(getCallHistoryList.rejected, (state) => {
        state.loadError = true
        state.loading = false
      })
  },
})

export const getCallHistoryList = createAsyncThunk(
  "callHistory/getCallHistoryList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      { url: GET_CALL_HISTORY_LIST, loader: false, data: data },
      helpers
    )
)

export const deleteCall = createAsyncThunk(
  "callHistory/deleteCall",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_CALL_HISTORY,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
)

export const { updateCallHistoryState } = callHistorySlice.actions
export default callHistorySlice.reducer
