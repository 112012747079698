import {
  Box,
  Grid,
  Typography,
  Slider,
  Switch,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import {  ASSISTANT_RESPONSE_CONTENT } from "../../../data/Constants/tooltip";
import get from "lodash/get";
const SpeechSettings = ({ handleChangeAssistantChangedData }) => {
  const state=useSelector((state)=>state);
  const ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.ssml_data
  );
  const updated_ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.ssml_data
  );

  const [ssmlDataExtraSetting, setSsmlDataExtraSetting] = useState({
    label: ssml_data?.label ?? "Aria",
    gender: ssml_data?.gender ?? "female",
    country: ssml_data?.country ?? "US",
    vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
    tts_provider: ssml_data?.tts_provider ?? "azure",
    lang: ssml_data?.lang ?? "en",
    max_duration: ssml_data?.max_duration ?? 780,
    rengage_caller: ssml_data?.rengage_caller ?? true,
    rengage_count: ssml_data?.rengage_count ?? 2,
    no_input: ssml_data?.no_input ?? 22,
    speech_rate: ssml_data?.speech_rate ?? -12,
    rengage_message:
      ssml_data?.rengage_message ??
      "Sorry, I didn't get any response from you, are you still there?",
  });

  const assistantInterruptionData = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.additionalConfig
        ?.interruption
  );
  const assistantResponseBehavior = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.additionalConfig
        ?.brand_name
  );

  const [brandName, setBrandName] = useState(
    assistantResponseBehavior ?? "deepgram"
  );

  const [assistantInterruption, setAssistantInterruption] = useState(
    assistantInterruptionData ?? false
  );

  const assistantAdditionalConfigPresentInUpdatedData = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.additionalConfig
  );

  const additionalConfigObj = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.additionalConfig
  );

  const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );

  const handleChangeInAdditionalConfigData = (value) => {
    handleChangeAssistantChangedData({
      additionalConfig: {
        ...(additionalConfigObj || {}),
        ...(assistantAdditionalConfigPresentInUpdatedData || {}),
        ...value,
      },
    });
  };

  const handleChangeSetExtraSettings = (name, newValue) => {
    setSsmlDataExtraSetting((prevSettings) => ({
      ...prevSettings,
      [name]: newValue,
    }));
    handleChangeAssistantChangedData({
      ssml_data: {
        ...ssml_data,
        ...(updated_ssml_data || {}),
        [name]: newValue,
      },
    });
  };
  useEffect(() => {
    setAssistantInterruption(assistantInterruptionData ?? false);
  }, [assistantInterruptionData]);

  useEffect(() => {
    setBrandName(assistantResponseBehavior ?? "deepgram");
  }, [assistantResponseBehavior]);

  useEffect(() => {
    setSsmlDataExtraSetting({
      label: ssml_data?.label ?? "Aria",
      gender: ssml_data?.gender ?? "female",
      country: ssml_data?.country ?? "US",
      vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
      tts_provider: ssml_data?.tts_provider ?? "azure",
      lang: ssml_data?.lang ?? "en",
      max_duration: ssml_data?.max_duration ?? 780,
      rengage_caller: ssml_data?.rengage_caller ?? true,
      rengage_count: ssml_data?.rengage_count ?? 2,
      no_input: ssml_data?.no_input ?? 22,
      speech_rate: ssml_data?.speech_rate ?? -12,
      rengage_message:
        ssml_data?.rengage_message ??
        "Sorry, I didn't get any response from you, are you still there?",
    });
  }, [ssml_data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container paddingInline={1}>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              display="flex"
              alignItems="center"
              sx={{ fontWeight: "bold" }}
            >
              Speech Rate
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent="Adjust how fast or slow the voice assistant talks. Set it to your perfect speed for an enjoyable listening experience!"
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8} display="flex" alignItems="center">
              <Slider
              disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                size="small"
                valueLabelDisplay="auto"
                value={ssmlDataExtraSetting?.speech_rate}
                onChange={(e, newValue) =>
                  handleChangeSetExtraSettings("speech_rate", newValue)
                }
                aria-labelledby="speech-rate-slider"
                min={-20}
                max={20}
              />
            </Grid>
            <Grid item display="flex" alignItems="center">
              ({ssmlDataExtraSetting?.speech_rate})
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              sx={{ fontWeight: "bold" }}
            >
              Assistant's Response Behavior
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent={
                 ASSISTANT_RESPONSE_CONTENT
                }
              />
            </Typography>
          </Grid>
          <Grid item xs={12} mb={2} mt={1}>
            <FormControl
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  padding: 0,
                  margin: 0,
                  paddingBlock: 0.65,
                  paddingInline: 1,
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.9rem",
                  padding: 0,
                  paddingInline: 1,
                },
              }}
            >
              <Select
              disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                labelId="response-behavior-select-label"
                id="response-behavior-select"
                value={brandName ?? "deepgram"}
                onChange={(event) => {
                  handleChangeInAdditionalConfigData({
                    brand_name: event.target.value,
                  });
                  setBrandName(event.target.value);
                }}
                displayEmpty
                sx={{
                  fontSize: "0.9rem",
                  padding: 0,
                  paddingBlock: 0.65,
                  paddingInline: 2,
                }}
              >
                <MenuItem
                  value="deepgram"
                  sx={{
                    fontSize: "0.9rem",
                    padding: 0,
                    paddingBlock: 0.5,
                    paddingInline: 2,
                  }}
                >
                  Rapid Response
                </MenuItem>
                <MenuItem
                  value="azure"
                  sx={{
                    fontSize: "0.9rem",
                    padding: 0,
                    paddingBlock: 0.5,
                    paddingInline: 2,
                  }}
                >
                  Thoughtful Response (Contextual Precision)
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Allow Assistant Interuption
            </Typography>
            <Typography variant="caption">
              If turned on, we are allowing callers to interrupt the assistant
              while the assistant is speaking. Once interrupted the assistant
              will stop speaking and listen to what the caller is saying.
            </Typography>
          </Grid>
          <Grid item>
            <Switch
            disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
              size="medium"
              defaultChecked={assistantInterruption}
              checked={assistantInterruption}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(event) => {
                handleChangeInAdditionalConfigData({
                  interruption: event.target.checked,
                });
                setAssistantInterruption(event.target.checked);
              }}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              display="flex"
              alignItems="center"
              fontWeight="bold"
            >
              No Response Time Limit:
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent="Set how long the assistant waits for the user to respond during a call. If the user doesn't reply within this time, the call will end. ( unless re-engagement settings are on in which case the assistant will attempt to re-engage with the user )."
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8} display="flex" alignItems="center">
              <Slider
              disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                size="small"
                valueLabelDisplay="auto"
                value={ssmlDataExtraSetting?.no_input}
                onChange={(e, newValue) =>
                  handleChangeSetExtraSettings("no_input", newValue)
                }
                aria-labelledby="no_input-slider"
                min={10}
                max={30}
              />
            </Grid>
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2" fontWeight="bold">
                ({ssmlDataExtraSetting?.no_input} seconds)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              Re-engage
            </Typography>
            <Typography variant="caption">
              Enable this setting to allow the assistant to re-engage with the
              user if the user has not responded.
            </Typography>
          </Grid>
          <Grid item>
            <Switch
            disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
              size="medium"
              checked={ssmlDataExtraSetting.rengage_caller}
              onChange={(e, newValue) =>
                handleChangeSetExtraSettings("rengage_caller", newValue)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Collapse
        in={ssmlDataExtraSetting?.rengage_caller}
        sx={{ paddingInline: 1 }}
      >
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              display="flex"
              alignItems="center"
              sx={{ fontWeight: "bold" }}
            >
              Re-engage Count
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent="Set how many times the assistant will attempt to re-engage."
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={8} display="flex" alignItems="center">
              <Slider
              disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                size="small"
                step={1}
                valueLabelDisplay="auto"
                value={ssmlDataExtraSetting.rengage_count}
                onChange={(e, newValue) =>
                  handleChangeSetExtraSettings("rengage_count", newValue)
                }
                min={2}
                max={5}
              />
            </Grid>
            <Grid item display="flex" alignItems="center">
              ({ssmlDataExtraSetting?.rengage_count})
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            display="flex"
            alignItems="center"
            sx={{ fontWeight: "bold" }}
          >
            Re-engage Message
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition={"left"}
              transformVerticalPosition={"bottom"}
              anchorHorizontalPosition={"left"}
              anchorVerticalPosition={"top"}
              hintContent="This is the message the assistant will use to re-engage with the user."
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
          disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                padding: 0,
                margin: 0,
                paddingBlock: 0.5,
                paddingInline: 1,
              },
              "& .MuiInputBase-input": {
                fontSize: "0.8rem",
              },
            }}
            size="small"
            value={ssmlDataExtraSetting.rengage_message}
            placeholder="e.g., 'Sorry, I didn't get any response from you, are you still there?'"
            onChange={(e) =>
              handleChangeSetExtraSettings("rengage_message", e.target.value)
            }
            fullWidth
            multiline
            minRows={1}
            variant="outlined"
          />
        </Grid>
      </Collapse>
    </Box>
  );
};

export default SpeechSettings;
