import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterObject: (state, action) => {
      state.filterObject = action.payload;
    },
     setFilters: (state, action) => {
       state.filters = action.payload;
     },
     setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
    setFilterParams: (state, action) => {
      state.filterParams = action.payload;
    },
    clearFilters: (state) => {
      state.filterObject = {};
      state.filters = {};
      state.appliedFilters = {};
      state.filterParams = {};
    },
    removeFilterKey: (state, action) => {
      const key = action.payload;
    
      const deleteByKeyPath = (obj, keyReceived) => {
        if (!obj || typeof obj !== "object") return obj;
        for (const [k, value] of Object.entries(obj)) {
          if (k === keyReceived) {
            delete obj[k];
          } else if (typeof value === "object" && value !== null) {
            deleteByKeyPath(value, keyReceived);
            if (Object.keys(value).length === 0) delete obj[k]; 
          }
        }
      };
    
      deleteByKeyPath(state.filterParams, key);
      deleteByKeyPath(state.appliedFilters, key);
    }    
  },
});

export const { 
  setFilterObject, 
  setFilters, 
  setAppliedFilters, 
  clearFilters, 
  setFilterParams, 
  removeFilterKey 
} = filterSlice.actions;

export default filterSlice.reducer;


