import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  keyframes,
  useTheme,
  styled,
  tooltipClasses,
  alpha,
  lighten,
  darken,
} from "@mui/material";
import HdrStrongIcon from "@mui/icons-material/HdrStrong";
import { useSelector } from "react-redux";

// Define keyframes for the diagonal shimmer effect
const badgeHighlightShimmer = keyframes`
  0% {
    left: -1100px;
    top: -150px;
  }
  50% {
    left: 100%;
    top: 550px;
  }
  100% {
    left: 100%;
    top: 150px;
  }
`;

// Styled component with the shimmer animation
const HighlightedDiv = styled("div")(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  backgroundColor: "lightgreen", // Set the background color to light green
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center", // Center the icon vertically
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: "-150px",
    left: "-1000px",
    width: "2000px",
    height: "90px",
    background:
      "linear-gradient(180deg, rgba(127,127,127,0) 0%, rgba(127,127,127,0.18) 25%, rgba(127,127,127,0.3) 50%, rgba(127,127,127,0.18) 75%, rgba(127,127,127,0) 100%)",
    transform: "rotate(-45deg)",
    animation: `${badgeHighlightShimmer} 5s ease-out 2s infinite`, // Decrease the speed of the animation
  },
}));

const TooltipDiv = styled(({ themeMode, className, ...props }) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(({ theme, themeMode }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:
        themeMode === "dark"
          ? darken(theme.palette.info.main, 0.7)
          : lighten(theme.palette.info.main, 0.9),
      color:
        themeMode === "dark"
          ? lighten(theme.palette.info.main, 0.7)
          : darken(theme.palette.info.main, 0.6),
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  };
});

const CustomTooltip = ({ heading = "Title", title, placement = "top" }) => {
  const themeMode = useSelector((state) => state.pageDetails.themeMode);
  return (
    <TooltipDiv themeMode={themeMode} title={title} placement={placement} arrow>
      <HighlightedDiv>
        <HdrStrongIcon style={{ marginRight: "8px" , color:'000'}} />
        <Typography
          paddingInline={0.5}
          variant="subtitle2"
          textTransform="none"
          component="span"
          sx={{color:'#000'}}
        >
          {heading}
        </Typography>
      </HighlightedDiv>
    </TooltipDiv>
  );
};

export default CustomTooltip;
