import { Box, Grid, Typography, Select, MenuItem, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import { KNOWLEDGEBASE_CONTENT } from "../../../data/Constants/tooltip";
import get from "lodash/get";
const KnowledgeBaseAssistant = ({
  handleChange,
  handleChangeAssistantChangedData,
}) => {
  const state=useSelector((state)=>state);
  const knowledgeBaseList = useSelector(
    (state) => state.knowledgeBasesDetails.knowledgeBaseList
  );
  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const [selectedKB, setSelectedKB] = useState("");
  const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );

  useEffect(() => {
    if (assistantInitialData?.knowledgeBases) {
      setSelectedKB(assistantInitialData?.knowledgeBases?.[0]?._id)
    }
  }, [assistantInitialData?.knowledgeBases])

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Knowledge Bases
              <Box mb={0.3}>
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                   KNOWLEDGEBASE_CONTENT
                  }
                />
              </Box>
            </Typography>
            <Typography variant="caption">
              A Knowledge Base (KB) stores structured information for queries.
              Enabling it makes its data usable; disabling excludes it until
              re-enabled. Only one KB can be active at a time.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Select Knowledge Base:
          </Typography>
          <Box sx={{
            display: 'flex',
            columnGap: '5px'
          }}>
            <Select
            disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
              fullWidth
              value={selectedKB ?? ""}
              onChange={(event) => {
                setSelectedKB(event.target.value);
                handleChangeAssistantChangedData({
                  knowledgeBases: [event?.target?.value],
                });
              }}
              displayEmpty
              size="small"
              sx={{
                borderRadius: '5px'
              }}
            >
              <MenuItem value="" disabled>
                Choose a Knowledge Base
              </MenuItem>
              {knowledgeBaseList
                ?.filter((kb) => kb.isTrained === true)
                ?.map((kb) => (
                  <MenuItem key={kb._id} value={kb._id}>
                    {kb.name}
                  </MenuItem>
                ))}
            </Select>
            {selectedKB && <Button variant="contained" size="small" sx={{
              textTransform: 'none',
              borderRadius: '5px'
            }}
              onClick={() => {
                handleChangeAssistantChangedData({
                  knowledgeBases: []
                })
                setSelectedKB("")
              }}
            >
              Delete
            </Button>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KnowledgeBaseAssistant;
