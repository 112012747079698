import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { THEME_MODE } from "../../data/Constants/theme";
import IMG_LOGO_AVATAR_LIGHT from "../../data/assets/img/logoAvatarLight.png";
import IMG_LOGO_AVATAR_DARK from "../../data/assets/img/logoAvatarDark.png";

const SpinnerLoader = ({ size, brand, ...restProps }) => {
  const themeMode = useSelector((state) => state.pageDetails.themeMode);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
      {...restProps}
    >
      {brand ? (
        <Box
          className="vg-animated vg-pulse"
          component="img"
          src={
            themeMode === THEME_MODE.DARK
              ? IMG_LOGO_AVATAR_DARK
              : IMG_LOGO_AVATAR_LIGHT
          }
          alt="Logo"
          width={size}
        />
      ) : (
        <CircularProgress color="secondary" size={size} />
      )}
    </Box>
  );
};

SpinnerLoader.propTypes = {
  size: PropTypes.number,
  brand: PropTypes.bool,
};

SpinnerLoader.defaultProps = {
  size: 50,
  brand: false,
};

export default SpinnerLoader;
