import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  TextField,
  Button,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,  
  FormControl,
  Tabs,
  Tab
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { enqueueSnackbar } from "notistack";
import CustomHintPopover from "../CustomHintPopover";
import CloseIcon from "@mui/icons-material/Close";
import { displayName } from "react-quill";
import { ANSWER_TYPES } from "../../data/Constants/hardData";
const QuestionInputComponent = ({
  setShowDialog,
  updatedQuestion,
  editEnable,
  saveQuestion,
  setAddQuestion,
  questionDialogisOpen,
  setQuestionDialogisOpen,
  questionDialogisClose,
  setQuestionDialogisClose,
  text,
  setText,handleChangeAssistantChangedData,assistantEdit,handleSubmit,questions

}) => {
  const [questionData, setQuestionData] = useState({
    question: editEnable ? updatedQuestion?.question : "",
    type:editEnable? updatedQuestion ?.type:"",
    possibleAnswers: editEnable
      ? updatedQuestion?.possibleAnswers
      : [],
    //  : ["Yes", "No"],
    shortHand: editEnable ? updatedQuestion?.shortHand : "",
  });

  const [isValid, setIsValid] = useState(false); 
  const [shorthanderror, setShortHandError] = useState(false)
 
  const [answerTypevalue, setAnswerTypeValue] = useState('')

  const clearQuestionData = (keyName) => {
    setQuestionData((prevState) => ({
      ...prevState,
      [keyName]: "",
    }));
  };

  const checkValidity = (question, possibleAnswers, shortHand,type) => {
    const isQuestionValid = question.trim() !== "";
    const arePossibleAnswersValid = possibleAnswers.some(
      (answer) => answer.trim() !== ""
    ); // Check if any answer is non-empty
    const isShorthandValid = shortHand.trim() !== "";
    // Update isValid state based on all field validity
    const isTypeValid=type!==null
    setIsValid(isQuestionValid &&  isShorthandValid && isTypeValid);
  };

  const handleRemovePossibleAnswer = (index) => {
    setQuestionData((prevState) => ({
      ...prevState,
      possibleAnswers: prevState.possibleAnswers.filter(
        (_, idx) => idx !== index
      ),
    }));
  };

  const handleAnswerTypeValue = (e) => {
    setAnswerTypeValue(e.target.value)

  }
  const handleAddMore = () => {
    const newPossibleAnswers = [...questionData.possibleAnswers, ""];
    setQuestionData((prevState) => ({
      ...prevState,
      possibleAnswers: newPossibleAnswers,
    }));
  };

  const handleQuestionChange = (value) => {
    setQuestionData((prevState) => ({
      ...prevState,
      question: value,
    }));
    checkValidity(value, questionData.possibleAnswers, questionData.shortHand); 
  };

  const handlePossibleAnswerChange = (index, value) => {
    const newPossibleAnswers = [...questionData.possibleAnswers];
    newPossibleAnswers[index] = value;
    setQuestionData((prevState) => ({
      ...prevState,
      possibleAnswers: newPossibleAnswers,
    }));
    setText(value)
    checkValidity(
      questionData.question,
      newPossibleAnswers,
      questionData.shortHand
    ); 
  };
  const handleAddMoreText = () => {
    setText([...text, ""]); 
  };

  const handleShorthandChange = (value) => {
    setQuestionData((prevState) => ({
      ...prevState,
      shortHand: value.toLowerCase(),
    }));
    checkValidity(
      questionData.question,
      questionData.possibleAnswers,
      value.toLowerCase()
    ); 
    if (/^[a-z_ ]{1,15}$/.test(value.toLowerCase())) {
      setShortHandError(false);

  }; 
  }
  

  const handleSaveQuestion = () => {
   
    if (!/^[a-z_ ]{1,15}$/.test(questionData?.shortHand)) {
      setShortHandError(true)
      return;
    }

    const newQuestion = {
      question: questionData.question,
      type:questionData.type,
      possibleAnswers: questionData.possibleAnswers.filter(
        (answer) => answer.trim() !== ""
      ),
      shortHand: questionData.shortHand,
    };
    saveQuestion(newQuestion);
   
    setQuestionData({
      question: "",
      type:"",
      possibleAnswers: [],
      shortHand: "",
    });
    setIsValid(false); 
    setAddQuestion(false);
  };
  const handleCloseQuestionDialog=()=>{
    setQuestionDialogisOpen(false)
  }

  return (
    <Dialog
      PaperProps={{
        style: {
          maxWidth: "800px",
          width: "120%",
        },
      }}
      open={questionDialogisOpen}
      onClose={handleCloseQuestionDialog}
    >
      <DialogContent sx={{ padding: 4 }}>
     
<Grid container spacing={2}>
          <Grid container item xs={12} mb={1}>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography display="flex" alignItems="center" variant="body1">
                Question :
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  // hintTitle={'Bot Temperature Setting'}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "Information would you like to know from the conversation."
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                required
                size="small"
                fullWidth
                placeholder="eg : Was the lead interested in buying the product?"
                label="Write you question here"
                variant="outlined"
                value={questionData?.question ?? ""}
                onChange={(e) => handleQuestionChange(e.target.value)}
                InputProps={{
                  endAdornment: questionData?.question ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => clearQuestionData("question")}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
            </Grid>
          </Grid>      
          <Grid container item xs={12} mb={0}>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "flex-start"}}>
              <Typography display="flex" alignItems="center" variant="body1">
                Answer Type:
                {/* <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  // hintTitle={'Bot Temperature Setting'}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    <>
                      List of possible values of answers to your question above.
                      <strong>(Max 4)</strong>
                    </>
                  }
                /> */}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={10}
              sx={{
                display: "flex",
                flexDirection: 'column',
                justifyContent: "flex-start",
                  // alignItems: "center",
                
              }}
            >
              <Box sx={{ display: 'flex', flexDirection:'column', height:'100%' }}>
                 <Select
                  size="small"
                  sx={{ width: '200px' }}
                  value={questionData.type ?? ""}
                  onChange={(e) =>  setQuestionData((prev) => ({
                    ...prev,
                    type: e.target.value,}))}
                  displayEmpty>
                  <MenuItem value="" disabled>Select Answer Type</MenuItem>
                  {ANSWER_TYPES?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.toString()}
                    </MenuItem>))}
                </Select>  
              
                {questionData?.possibleAnswers?.map((answer, index) => (
                  <Grid item xs={8} key={index} mr={1.5} sx={{ marginLeft:0}}>
                    { questionData.type==='Selector'&& (
                     
                    <TextField
                  sx={{marginTop:2}}
                      size="small"
                      fullWidth
                      label={`Possible Answer ${index + 1}`}
                      variant="outlined"
                      value={answer}
                      InputProps={
                        questionData.possibleAnswers.length > 0 ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="remove-possible-answer"
                                onClick={() => handleRemovePossibleAnswer(index)}
                             
                              >
                                <CloseIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        } :{}
                      }
                    onChange={(e) =>
                         handlePossibleAnswerChange(index, e.target.value,)
                       } />)}
                  </Grid>
                 ))}
               
               </Box>
              {questionData?.type==='Selector'? (
                <Grid item xs={4.8} sx={{ marginLeft: 0, backgroundColor:'' }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    startIcon={<AddIcon />}
                    sx={{ color: "text.secondary", 
                      justifyContent:'flex-start',
                      textAlign:'left'
                    }}
                    onClick={handleAddMore}
                    disabled={questionData.possibleAnswers.length>=4}
                  >
                    Add Possible Answers
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item xs={12} mb={1} mt={1} sx={{minHeight:"50px"}} >
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography display="flex" alignItems="center" variant="body1">
                Shorthand :
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  // hintTitle={'Bot Temperature Setting'}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"center"}
                  anchorHorizontalPosition={"right"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    "A one word identifier for your question. This would be used as a column header in campaign analytics pages"
                  }
                />
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                required
                fullWidth
                size="small"
                label="Write your shorthand here"
                placeholder="eg : Lead Convert?"
                variant="outlined"
                value={questionData?.shortHand ?? ""}
                onChange={(e) =>
                  handleShorthandChange(e.target.value.toLowerCase())
                } 
                InputProps={{
                  endAdornment: questionData?.shortHand ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear text"
                        onClick={() => clearQuestionData("shortHand")}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              {shorthanderror && <Typography sx={{ color: "red" }}>Shorthand must be 1-15 characters, all lowercase, and no spaces</Typography>}
            </Grid>

          </Grid>

        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            marginBlock: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="flex-end"
            marginRight={2}
          >
            <Button
              variant="contained"
              sx={{
                color: "text.primary",
                backgroundColor: "secondary.main",
                minWidth: "100px",
                paddingInline: 2,
              }}
              onClick={() => {
                handleSaveQuestion();
              }}              
              disabled={!isValid}
            >
              {editEnable ? "Update" : "Add"}
            </Button>
          </Grid>
          {!editEnable ? (
            <Grid item display="flex" justifyContent="flex-end" xs={2}>
              <Button
                variant="outlined"
                sx={{ minWidth: "100px", paddingInline: 2 }}
                onClick={() => 
                  {setAddQuestion(false)}}
             
              >
                Close
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

QuestionInputComponent.propTypes = {
  saveQuestion: PropTypes.func.isRequired,
};

export default QuestionInputComponent;
