import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePageState } from "../../data/store/pageSlice";
import { APP_PAGES,ROUTE_PATH } from "../../data/Constants/url";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Modal,Table,TableCell,TableRow,TableBody
} from "@mui/material";
import * as _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import chatbotLogo from "../../data/assets/img/chatbot_logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  copyToClipboard,
  handleCleverTapBtnClick,
  log,
  parseQueryString,
} from "../../data/configs/utils";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone.js";
import dayjs from "dayjs";
import { getCallDetails } from "../../data/store/campaignsSlice";
import CustomHintPopover from "../CustomHintPopover";
import useAudioStreamTwilio from "../../data/hooks/useAudioStreamTwilio";
//import ExtraCallDetails from "./ExtraCallDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExtraCallDetails from "../CallDetailTranscript/ExtraCallDetails";
import { demoCallAnalytics } from "../../data/store/authSlice";
import { Skeleton } from "survey-react-ui";
dayjs.extend(utc);
dayjs.extend(timezone);

const DemoCallDetailTranscript = ({ showDialog, setShowDialog }) => {
  const navigate = useNavigate();
  const restParams = useLocation();
  const token = useSelector((state) => state.authDetails.token);
  // const restParamsObj = parseQueryString(restParams?.search);
  const [restParamsObj, setRestParamsObj] = useState({
    callSessionHistoryId: "",
  });
  const [contactData, setContactData] = useState({});
  const [audioUrlPresent, setAudioUrlPresent] = useState("");
  const dispatch = useDispatch();
  const [postDemoCallAnalyticsData, setPostDemoCallAnalyticsData] = useState(
    {}
  );
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [backendMessage,SetBackendMessage]=useState("")


  const { audio, error } = useAudioStreamTwilio(
    audioUrlPresent,
    restParamsObj,
    token
  );
  const searchParams = new URLSearchParams(location.search);
  const assistantId = searchParams.get("id");

  useEffect(() => {
    if (showDialog) {
      handlePoling();
      setIsLoading(true);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      setPostDemoCallAnalyticsData({});
      clearInterval(intervalId);
      setIsLoading(false);
    };
  }, [showDialog]);

  let intervalId;
  const handlePoling = () => {
    intervalId = setInterval(() => handleFetchPollingData(intervalId), 5000);

    // Stop the polling after 2 minutes
    setTimeout(() => {
      clearInterval(intervalId);
    }, 120000);
  };

  const handleFetchPollingData = (intervalId) => {
    if (showDialog) {
      dispatch(demoCallAnalytics({ assistantId }))
        .unwrap()
        .then((res) => {
        SetBackendMessage(res?.message)
          if (res?.data?.status === "Ended") {
            setIsLoading(false);
            
            setPostDemoCallAnalyticsData(res.data.callData);
            setAudioUrlPresent(res?.data?.callData?.recordingUri);
            setRestParamsObj({
              callSessionHistoryId: res?.data?.callSessionHistoryId,
            });
           if (res?.data?.callData?.transcript?.length > 0) {
              clearInterval(intervalId);
            }
          }
        });
    }
  };
  
  const LoadingSkeleton = () => {
    return (
      <div
        style={{
          width: "100%",
          padding: "20px",
          backgroundColor: "#f0f0f0",
          borderRadius: "24px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "15px",
            backgroundColor: "#e0e0e0",
            borderRadius: "24px",
          }}
        ></div>
      </div>
    );
  };
  const handleOnClose = () => {
    setShowDialog(false);
  };

  return (
    <Modal open={showDialog} onClose={handleOnClose}>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90vw",
          height: "90vh",
          boxShadow: 24,
          p: 4,
          position: "relative",
          borderRadius: 1,
          overflow: "hidden",
          paddingBottom: "40px",
        }}
      >
        <IconButton
          onClick={handleOnClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: "blue",
            backgroundColor: "white",
          }}
        >
          <CloseIcon />
        </IconButton>

        {!isLoading ? (
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
            >
              {restParamsObj?.campaignId &&
              restParamsObj?.campaignId !== "demo" ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      ROUTE_PATH.EDIT_CAMPAIGN.replace(
                        ":campaignId",
                        restParamsObj?.campaignId
                      )
                    );
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              ) : null}
              <Typography
                marginTop={1}
                align="start"
                ml={1}
                mb={1}
                sx={{ fontWeight: "bold" }}
                fontSize="bold"
                variant="h5"
              >
                {contactData?.Name?.length > 0
                  ? contactData?.Name
                  : contactData?.customerNumber}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={8}
              sx={{
                paddingRight: 2,
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Card
                sx={{
                  padding: 2,
                  // maxHeight: "85vh",
                  // minHeight: "85vh",
                  display: "flex",
                  justifyContent: "space-between",
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                  overflow: "auto",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Grid item xs={12} mb={2} display="flex" alignItems="center">
                    <Card
                      sx={{
                        padding: 2,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 0px 1px 0px",
                        //  bgcolor:'pink'
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} mb={1}>
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="h6"
                            marginLeft={2}
                            sx={{ fontWeight: "bold" }}
                          >
                            Details
                            <CustomHintPopover
                              size="small"
                              maxWidth={400}
                              // hintTitle={'Bot Temperature Setting'}
                              transformHorizontalPosition={"left"}
                              transformVerticalPosition={"center"}
                              anchorHorizontalPosition={"right"}
                              anchorVerticalPosition={"top"}
                              hintContent={
                                "Additional information about your call."
                              }
                            />
                          </Typography>
                        </Grid>
                        <Grid container marginInline={2} spacing={2}>
                          <Grid item xs={7}>
                            <Grid mb={2}>
                              <Typography
                                variant="body1"
                                style={{ fontweight: "bold" }}
                              >
                                <strong style={{ paddingRight: "4px" }}>
                                  Call Duration :
                                </strong>{" "}
                                {postDemoCallAnalyticsData?.duration || "NA"}
                              </Typography>
                            </Grid>

                            <Grid mb={2}>
                              <Typography variant="body1">
                                <strong style={{ paddingRight: "4px" }}>
                                  Start Time :
                                </strong>
                                {dayjs(
                                  postDemoCallAnalyticsData?.initiationTime
                                ).isValid()
                                  ? dayjs
                                      .unix(
                                        postDemoCallAnalyticsData?.initiationTime /
                                          1000
                                      )
                                      ?.format("h:mm:ss a")
                                  : "NA"}
                              </Typography>
                            </Grid>
                            <Grid mb={2}>
                              <Typography
                                variant="body1"
                                style={{ fontweight: "bold" }}
                              >
                                <strong style={{ paddingRight: "4px" }}>
                                  Call Latency
                                </strong>{" "}
                                {postDemoCallAnalyticsData?.duration || "NA"}
                              </Typography>
                            </Grid>
                            {postDemoCallAnalyticsData?.HangupCause?.length >
                              0 && (
                              <Grid mb={2}>
                                <Typography variant="body1">
                                  <strong style={{ paddingRight: "4px" }}>
                                    Hangup Reason :
                                  </strong>{" "}
                                  {postDemoCallAnalyticsData?.HangupCause}
                                </Typography>
                              </Grid>
                            )}
                            {postDemoCallAnalyticsData?.customerNumber && (
                              <Grid mb={2}>
                                <Typography variant="body1">
                                  <strong style={{ paddingRight: "4px" }}>
                                    Customer Number :
                                  </strong>{" "}
                                  {postDemoCallAnalyticsData?.customerNumber}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>

                          <Grid item xs={5}>
                            <Grid mb={2}>
                              <Typography variant="body1">
                                <strong style={{ paddingRight: "4px" }}>
                                  Date :
                                </strong>
                                {dayjs(
                                  postDemoCallAnalyticsData?.initiationTime
                                ).isValid()
                                  ? dayjs
                                      .unix(
                                        postDemoCallAnalyticsData?.initiationTime /
                                          1000
                                      )
                                      .tz("Asia/Kolkata")
                                      .format("DD MMMM YYYY")
                                  : "NA"}
                              </Typography>
                            </Grid>
                            <Grid mb={2}>
                              <Typography variant="body1">
                                <strong style={{ paddingRight: "4px" }}>
                                  End Time :
                                </strong>
                                {dayjs(
                                  postDemoCallAnalyticsData?.endTime
                                ).isValid()
                                  ? dayjs(
                                      postDemoCallAnalyticsData?.endTime
                                    ).format("h:mm:ss a")
                                  : "NA"}
                              </Typography>
                            </Grid>
                            {postDemoCallAnalyticsData?.HangupSource?.length >
                              0 && (
                              <Grid mb={2}>
                                <Typography variant="body1">
                                  <strong style={{ paddingRight: "4px" }}>
                                    Hangup By :
                                  </strong>{" "}
                                  {postDemoCallAnalyticsData?.HangupSource}
                                </Typography>
                              </Grid>
                            )}
                            {postDemoCallAnalyticsData?.callStatus?.length >
                              0 && (
                              <Grid mb={2}>
                                <Typography variant="body1">
                                  <strong style={{ paddingRight: "4px" }}>
                                    Status :
                                  </strong>
                                  {postDemoCallAnalyticsData?.callStatus
                                    .charAt(0)
                                    .toUpperCase() +
                                    postDemoCallAnalyticsData?.callStatus.slice(
                                      1
                                    )}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12} mb={2} display="flex" alignItems="center">
                    <Card
                      sx={{
                        padding: 2,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 0px 1px 0px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} mb={1}>
                          <Typography
                            display="flex"
                            alignItems="center"
                            variant="h6"
                            marginLeft={2}
                            sx={{ fontWeight: "bold" }}
                          >
                            Call Recording
                            <CustomHintPopover
                              size="small"
                              maxWidth={400}
                              // hintTitle={'Bot Temperature Setting'}
                              transformHorizontalPosition={"left"}
                              transformVerticalPosition={"center"}
                              anchorHorizontalPosition={"right"}
                              anchorVerticalPosition={"top"}
                              hintContent={"Recording of whole conversation."}
                            />
                          </Typography>
                        </Grid>
                        <Grid container display="flex" justifyContent="center">
                          <Grid item xs={12} paddingInline={2}>
                            {postDemoCallAnalyticsData?.recordingUri ? (
                              restParamsObj !== "demo" ? (
                                audio ? (
                                  _.isEmpty(error) ? (
                                    <audio
                                      key={audio}
                                      preload="auto"
                                      controls
                                      style={{ width: "100%" }}
                                      onPlay={() =>
                                        handleCleverTapBtnClick(
                                          "Audio Recording Played"
                                        )
                                      }
                                    >
                                      <source src={audio} type="audio/mp3" />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  ) : (
                                    <Box
                                      sx={{
                                        minHeight: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography>
                                        Recording Not Available!
                                      </Typography>
                                    </Box>
                                  )
                                ) : (
                                  <LoadingSkeleton />
                                )
                              ) : (
                                <Box
                                  component="audio"
                                  src={postDemoCallAnalyticsData?.recordingUri}
                                  width="100%"
                                  controls
                                  onPlay={() =>
                                    handleCleverTapBtnClick(
                                      "Audio Recording Played"
                                    )
                                  }
                                />
                              )
                            ) : (
                              <Box
                                sx={{
                                  minHeight: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography>
                                  Recording Not Available!
                                </Typography>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>

                    {postDemoCallAnalyticsData?.outcome?.length > 0 ? (
                                        <Grid item xs={12} mb={2} display="flex" alignItems="center">
                                          <Card
                                            sx={{
                                              padding: 2,
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              boxShadow: "0px 0px 1px 0px",
                                              mb:2
                                            }}
                                          >
                                            <Grid container>
                                              <Grid item xs={12} mb={1}>
                                                <Typography
                                                  display="flex"
                                                  alignItems="center"
                                                  variant="h6"
                                                  marginLeft={2}
                                                  sx={{ fontWeight: "bold" }}
                                                >
                                                  Post Call Analysis
                                                  <CustomHintPopover
                                                    size="small"
                                                    maxWidth={400}
                                                    // hintTitle={'Bot Temperature Setting'}
                                                    transformHorizontalPosition={"left"}
                                                    transformVerticalPosition={"center"}
                                                    anchorHorizontalPosition={"right"}
                                                    anchorVerticalPosition={"top"}
                                                    hintContent={
                                                      "Answer to questions asked during campaign creation."
                                                    }
                                                  />
                                                </Typography>
                                              </Grid>
                                           
                                              <Grid item xs={12}>
                                                <Table>
                                                  <TableBody>
                                                    {postDemoCallAnalyticsData?.outcome?.map((item, index) => (
                                                      <TableRow key={index}>
                                                        <TableCell>
                                                          <Typography variant="body1">{`Q ${
                                                            index + 1
                                                          }:`}</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                          <Typography variant="body1">
                                                            {item?.Question}
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                          <Typography variant="body1">
                                                            {(() => {
                                                              if (typeof item?.Answer === "boolean") {
                                                                return item.Answer ? "true" : "false";
                                                              } else if (item?.Answer === "N/A") {
                                                                return "Not Applicable";
                                                              } else if (item?.Answer === "other") {
                                                                return "Other";
                                                              } else if (item?.Answer === null) {
                                                                return "No Answer";
                                                              } else if (item?.Answer === 0) {
                                                                return "Zero";
                                                              } else {
                                                                return item?.Answer || "No Answer";
                                                              }
                                                            })()}
                                                          </Typography>
                                                        </TableCell>
                                                      </TableRow>
                                                    ))}
                                                  </TableBody>
                                                </Table>
                                              </Grid>
                                            </Grid>
                                          </Card>
                                        </Grid>
                                      ) : null}
                  </Grid>

                  {contactData &&
                  Object.keys(contactData.Entities ?? {})?.some(
                    (key) => contactData?.Entities[key]?.length > 0
                  ) ? (
                    <Grid
                      item
                      xs={12}
                      mb={2}
                      display="flex"
                      alignItems="center"
                    >
                      <Card
                        sx={{
                          padding: 2,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 0px 1px 0px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} mb={1}>
                            <Typography
                              display="flex"
                              alignItems="center"
                              variant="h6"
                              marginLeft={2}
                              sx={{ fontWeight: "bold" }}
                            >
                              Callee Information Collected from the conversation
                              <CustomHintPopover
                                size="small"
                                maxWidth={400}
                                // hintTitle={'Bot Temperature Setting'}
                                transformHorizontalPosition={"left"}
                                transformVerticalPosition={"center"}
                                anchorHorizontalPosition={"right"}
                                anchorVerticalPosition={"top"}
                                hintContent={
                                  "Information gathered from the conversation."
                                }
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            paddingInline={2}
                          >
                            {Object.entries(contactData.Entities).map(
                              ([key, value], index) =>
                                value.length > 0 && (
                                  <Grid item mb={1} mt={2} key={index}>
                                    <Typography variant="h6" mr={2}>
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1)}
                                      :
                                    </Typography>
                                    <div
                                      style={{
                                        borderRadius: "4px",
                                        paddingBlock: 4,
                                        paddingInline: 6,
                                        backgroundColor: "#0055FD",
                                        color: "#fff",
                                        textAlign: "center",
                                        userSelect: "none",
                                      }}
                                    >
                                      {value}
                                    </div>
                                  </Grid>
                                )
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ) : null}
                  {contactData && contactData?.meetingInfo?.length > 0 ? (
                    <Grid
                      item
                      xs={12}
                      mb={2}
                      display="flex"
                      alignItems="center"
                    >
                      <Card
                        sx={{
                          padding: 2,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 0px 1px 0px",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} mb={1}>
                            <Typography
                              display="flex"
                              alignItems="center"
                              variant="h6"
                              marginLeft={2}
                              sx={{ fontWeight: "bold" }}
                            >
                              Meeting Information
                              <CustomHintPopover
                                size="small"
                                maxWidth={400}
                                // hintTitle={'Bot Temperature Setting'}
                                transformHorizontalPosition={"left"}
                                transformVerticalPosition={"center"}
                                anchorHorizontalPosition={"right"}
                                anchorVerticalPosition={"top"}
                                hintContent={
                                  "Information gathered from the user conversation."
                                }
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            paddingInline={2}
                          >
                            {postDemoCallAnalyticsData?.meetingInfo?.map(
                              (obj, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  container
                                  mb={1}
                                  mt={2}
                                  key={index}
                                >
                                  <Typography variant="subtitle1" mr={2}>
                                    {obj?.key}:
                                  </Typography>
                                  {obj?.chip ? (
                                    obj?.value === true ? (
                                      <Chip
                                        size="small"
                                        label="True"
                                        color="success"
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        label="False"
                                        color="warning"
                                      />
                                    )
                                  ) : obj?.value === true ? (
                                    "True"
                                  ) : (
                                    "False"
                                  )}
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ) : null}
                  {!_.isNil(postDemoCallAnalyticsData.averageResponseTime) ||
                  !_.isNil(postDemoCallAnalyticsData.isUserInterested) ||
                  !_.isEmpty(postDemoCallAnalyticsData.chatLanguage) ||
                  !_.isEmpty(postDemoCallAnalyticsData.chatSummary) ? (
                    <ExtraCallDetails
                      averageResponseTime={_.defaultTo(
                        postDemoCallAnalyticsData.averageResponseTime,
                        "N/A"
                      )}
                      isUserInterested={_.defaultTo(
                        postDemoCallAnalyticsData.isUserInterested,
                        "N/A"
                      )}
                      chatLanguage={_.defaultTo(
                        postDemoCallAnalyticsData.chatLanguage,
                        "N/A"
                      )}
                      chatSummary={_.defaultTo(
                        postDemoCallAnalyticsData.chatSummary,
                        "N/A"
                      )}
                    />
                  ) : null}
                </Grid>
              </Card>
            </Grid>

            <Grid xs={4} item sx={{ height: "100%" }}>
              <Card
                variant="outlined"
                sx={{
                  height: "100%",
                  boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.2)",
                  padding: 2,
                  "& .MuiCardHeader-title": {
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 1,
                  },
                  "& .MuiCardHeader-root": { padding: 0, mb: 1 },
                  "& .MuiCardContent-root": { padding: 0 },
                  "& .MuiCardContent-root:last-child": { padding: 0 },
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      display="flex"
                      alignItems="center"
                      variant="h6"
                      marginLeft={2}
                      sx={{ fontWeight: "bold" }}
                    >
                      Transcript
                      <CustomHintPopover
                        size="small"
                        maxWidth={400}
                        // hintTitle={'Bot Temperature Setting'}
                        transformHorizontalPosition={"left"}
                        transformVerticalPosition={"center"}
                        anchorHorizontalPosition={"right"}
                        anchorVerticalPosition={"top"}
                        hintContent={"Transcript for the call."}
                      />
                    </Typography>
                  }
                />

                <CardContent>
                  <Box
                    sx={{
                      overflowY: "auto",
                      maxHeight: "75vh",
                      minHeight: "75vh",
                      paddingBottom: "40px",
                    }}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    {postDemoCallAnalyticsData?.transcript &&
                    postDemoCallAnalyticsData?.transcript?.length > 0 ? (
                      <Grid container style={{ width: "100%" }}>
                        {postDemoCallAnalyticsData?.transcript.map(
                          (item, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12}>
                                <Typography
                                  component="div"
                                  fontWeight="bold"
                                  fontSize="0.5rem"
                                  align="center"
                                  gutterBottom
                                >
                                  {dayjs(item?.timestamp).isValid()
                                    ? dayjs(item?.timestamp).format(
                                        " h:mm:ss a"
                                      )
                                    : ""}
                                </Typography>
                              </Grid>
                              {item.sender === "BOT" ? (
                                <Grid
                                  item
                                  xs={10}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    marginBottom: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={chatbotLogo}
                                    alt="Chatbot Logo"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      marginRight: "8px",
                                      verticalAlign: "middle",
                                    }}
                                  />
                                  <Box
                                    style={{
                                      backgroundColor: "#0055FD",
                                      color: "white",
                                      padding: "8px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <Typography variant="subtitle2">
                                      {item.message}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Box
                                    style={{
                                      backgroundColor: "#35FFA1",
                                      padding: "8px",
                                      borderRadius: "8px",
                                    }}
                                  >
                                    <Typography variant="subtitle2">
                                      {item.message}
                                    </Typography>
                                  </Box>
                                  <IconButton size="small">
                                    <AccountCircleIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </Grid>
                    ) : (
                      <Box
                        sx={{
                          minHeight: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>Transcript Not Available!</Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ width: "100%", padding: 2 }}>
            <Box
              sx={{
                padding: 2,
                borderRadius: 2,
                backgroundColor: "background.paper",
                height: "100%",
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}
            >
            <Typography variant="h5">
              {backendMessage? backendMessage:"Loading call data..."}  
            </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DemoCallDetailTranscript;
