export const WEBHOOK_TOOL_TIP = (
  <>
    <p>
      Enter a POST HTTP endpoint to receive the data
      collected during call. This can be any POST
      webhook url from services like Zapier,
      goHighLevel, your personal service endpoint etc.
    </p>
    <br />
    <p>
      <strong>Use Cases :</strong> Can be used for
      meeting bookings, Mail/Message(s) sending, etc.
    </p>
  </>)
export const INFORMATION_TO_BE_COLLECTED_TOOL_TIP = (<>
  <p>
    List of insightful questions that can help
    catagorise your contacts. This can include simple
    true/false or multi select questions.
    <strong> (Max 5)</strong>
  </p>
  <br />
  <p>
    <strong>Example :</strong> Was the lead interested
    in the product ?
  </p>
</>)

export const COMPANY_NAME = (<><p>
  Enter your company’s name. This may be mentioned during interactions with users to personalize the conversation and establish context.
</p>
</>

)

export const TEST_MODE_CONTENT = (<>
  <p>
    Test Mode enables you to test your campaign
    configurations by running a demo campaign on
    your phone number. You do not need a phone
    number from Twilio / Plivo to run a demo
    campaign.
  </p>
  <br />
  <p>
    <strong>Note :</strong> Minutes would be
    deducted from your quota during this test.
  </p>
</>)

export const TEST_MODE_CONTENT2 = (<>
  <p>
    Test Mode enables you to test your campaign
    configurations by running a demo campaign on
    your phone number. You do not need a phone
    number from Twilio / Plivo to run a demo
    campaign.
  </p>
  <br />
  <p>
    <strong>Note :</strong> Minutes would be
    deducted from your quota during this test.
  </p>
</>)

export const GOAL_CONTENT = (
  <>
    <p>This will be the main objective that your AI assistant will try to achieve once its in converstation with your contacts</p></>
)

export const WEBHOOK_CONTENT = (
  <>
    <p>
      Enter a POST HTTP endpoint to receive post call data on.
      This can be any POST webhook url from services like
      Zapier, goHighLevel, your personal service endpoint etc.
    </p>
    <br />
    <p>
      <strong>Use Cases :</strong> Can be used for meeting
      bookings, Mail/Message(s) sending, etc.
    </p>
  </>
)

export const CUSTOM_SMS_CONTENT = (
  <>
    <p>
      Set up customizable trigger prompts like "Send me a
      brochure" or "Can we set up an appointment?" during a
      call. When these prompts are spoken, an SMS with your
      predefined message in the template will be sent
      automatically.
    </p>
  </>
)

export const BOOK_A_MEETING_CONTENT = (
  <>
    <p>
      Allow leads to easily schedule meetings via a phone
      call. This feature gives them the flexibility to choose
      a convenient time, streamlining the booking process and
      enhancing engagement.
    </p>
  </>
)

export const DEVELOPERS_API_CONTENT = (
  <ul>
    <li>
      <strong>
        Generate a token to authenticate your access to
        VoiceGenie APIs.
      </strong>
    </li>
    <li>
      <strong>WorkspaceId</strong> and
      <strong>token</strong> provide secure and
      authorized usage of our APIs.
    </li>
    <li>
      Ensure to keep your <strong>token</strong> and
      <strong>workspaceID</strong> confidential and use
      them in your <strong>API requests</strong> to
      access the full range of VoiceGenie
      functionalities.
    </li>
  </ul>
)

export const DEVELOPERS_API_CONTENT2 = (<ul>
  <li>
    After Re-generatation, you won't be able to able to use
    the old token anywhere. You need to use the new generated
    Token.
  </li>
  <li>
    Once Generated, the token can not be seen for security
    reasons, in case you lost the token, you can always
    generate a new one.
  </li>
</ul>)

export const EMAIL_CONTENT = (
  <p>
    This email is linked to the Cal account for scheduling
    meetings.
  </p>
)

export const GET_A_WEBHOOK_CONTENT = (
  <>
    Grab a free webhook URL to and use it to understand the data you receive. Suggested to get a private webhook for your calls.
  </>
)

export const VOICEMAIL_CONTENT = (
  <>
    <p>
      <strong>Voicemail Detection: </strong>Toggle to enable
      or disable answering machine detection for outbound
      calls.
    </p>
    <br />
    <p>
      <strong>Enabled: </strong>Detects answering machines or
      voicemails and disconnects the call without deducting
      minutes from your quota.
    </p>
    <br />
    <p>
      <strong>Disabled: </strong>All calls proceed without
      detection.
    </p>
    <br />
  </>
)
export const KNOWLEDGEBASE_CONTENT = (
  <>
    <p>
      <strong>Knowledge Base (KB):</strong> The selected
      Knowledge Base will be available for searches and
      responses, ensuring data from this KB is used actively
      in the application.
    </p>
  </>
)
export const POST_CALL_ANALYSIS_CONTENT = (
  <>
    <p>
      <strong>Post Call Analysis :</strong> Gather additional insights during your campaign calls
      & take actions on them.
    </p>
  </>
)

export const ASSISTANT_RESPONSE_CONTENT = (
  <>
    <strong>Rapid Response:</strong>
    <p>
      Assistant will start speaking as soon as silence is
      detected from the caller's end, without checking if the
      customer has fully conveyed their message. This will have
      quicker responses from the assistant.
    </p>
    <br />
    <strong>Thoughtful Response (Contextual Precision):</strong>
    <p>
      The assistant will prioritize what the customer is trying
      to say over silence detected while the caller is speaking.
      This will make responses slower than Rapid Response.
    </p>
  </>
)

export const ASSISTANT_RESPONSE_CONTENT2 = (
  <>
    <strong>Rapid Response:</strong>
    <p>
      Assistant will start speaking as soon as silence is
      detected from the caller's end, without checking if
      the customer has fully conveyed their message.This
      will have quicker responses from the assistant.
    </p>
    <br />
    <strong>
      Thoughtful Response (Contextual Precision) :
    </strong>
    <p>
      The assistant will try to respond back prioritizing
      what the customer is trying to say over silence
      detected while the caller is speaking.On an average
      this will make assistant's responses slower than Rapid
      response.
    </p>
  </>
)

export const WHO_SPEAKS_FIRST_CONTENT = (
  <>
    <strong>
      This setting allows you to control the flow of your
      calls.
    </strong>
    <br />
    <p>Choose who should start the conversation:</p>
    <br />
    <strong>User Initiates :</strong>
    <p>
      The Assistant will remain silent until the user speaks
      first, giving the user full control over the call's
      start.
    </p>
    <br />
    <strong>Assistant Initiates :</strong>
    <p>
      The Assistant will begin the call with the call
      opening message, setting the tone from the start.
    </p>
    <br />
    <strong>
      Note: This feature is currently in Beta.
    </strong>
  </>
)

export const WHO_SPEAKS_FIRST_CONTENT2 = (
  <>
    <strong>
      This setting allows you to control the flow of your
      calls.
    </strong>
    <br />
    <p>Choose who should start the conversation:</p>
    <br />
    <strong>User Initiates :</strong>
    <p>
      The Assistant will remain silent until the user speaks
      first, giving the user full control over the call's
      start.
    </p>
    <br />
    <strong>Assistant Initiates :</strong>
    <p>
      The Assistant will begin the call with the call
      opening message, setting the tone from the start.
    </p>
    <br />
    <strong>
      Note: This feature is currently in Beta.
    </strong>
  </>
)

export const RE_ENGAGE_USER_CONTENT = (
  <p>
    Enable this setting to allow the assistant to
    re-engage with the user if the user has not
    responded after{" "}
    <strong>No Response Time Limit</strong>. has
    passed. Activate this to configure re-engagement
    options.
  </p>
)

export const RE_ENGAGE_USER_COUNT = (
  <>
    Set the number of times the assistant will try
    to re-engage with the user after waiting for the{" "}
    <strong>No Response Time Limit</strong> to pass
    each time. If this count is exceeded and the
    user still does not respond, the call will end."
  </>
)

export const RE_ENGAGE_MESSAGE = (
  <>
    This is the message the assistant will use to
    re-engage with user if user haven't responded
    after <strong>No Response Time Limit</strong>.
  </>
)

export const WEBHOOK_CONTENT2 = (
  <>
    <p>
      Enter a POST HTTP endpoint to receive post call data on.
      This can be any POST webhook url from services like
      Zapier, goHighLevel, your personal service endpoint etc.
    </p>
    <br />
    <p>
      <strong>Use Cases :</strong> Can be used for meeting
      bookings, Mail/Message(s) sending, etc.
    </p>
  </>
)

export const BOOK_A_MEETING_CONTENT2 = (
  <>
    <p>
      This feature ensures a smooth and efficient way to book
      your meetings, making the process as seamless and
      convenient as possible.
    </p>
    <br />
    <p>
      <strong>SMS Booking</strong>
    </p>
    <ul>
      <li>
        <p>
          <strong>What it Does:</strong> On a call, if a user
          wants to book a meeting, they will receive an SMS
          with a scheduling link.
        </p>
      </li>
      <br />
      <li>
        <p>
          <strong>Configuration :</strong> Customize the
          message body, provide the scheduling link, and set
          specific phrases (trigger prompts) that will
          automatically initiate the SMS booking flow during
          the call.
        </p>
      </li>
    </ul>
    <br />
    <p>
      <strong>
        Note: This feature is currently in Beta.
      </strong>
    </p>
  </>
)

export const BOOK_A_MEETING_CONTENT3 = (
  <>
    <p>
      This feature ensures a smooth and efficient way to book
      your meetings, making the process as seamless and
      convenient as possible.
    </p>
    <br />
    <p>
      <strong>SMS Booking</strong>
    </p>
    <ul>
      <li>
        <p>
          <strong>What it Does:</strong> On a call, if a user
          wants to book a meeting, they will receive an SMS
          with a scheduling link.
        </p>
      </li>
      <br />
      <li>
        <p>
          <strong>Configuration :</strong> Customize the
          message body, provide the scheduling link, and set
          specific phrases (trigger prompts) that will
          automatically initiate the SMS booking flow during
          the call.
        </p>
      </li>
    </ul>
    <br />
    <p>
      <strong>
        Note: This feature is currently in Beta.
      </strong>
    </p>
  </>
)

export const WELCOME_MESSAGE_CONTENT = (
  <p>
    This is the first message that your AI assistant will say once
    the call is picked up. <br />
    <br /> <strong>Additional Note:</strong>Make use of variables
    to personalise it for each contact. ( type @ in the text box
    to use it )
  </p>
)

export const CALL_CONCURRENCY_CONTENT = (
  <>
    <p>Set custom pacing and concurrency for the campaign.</p>
  </>
)

export const CAL_MEETING_CONTENT = (
  <>
    <p>
      Set your availability to schedule campaign meetings
      automatically. Choose start and end dates, define
      available hours, and select a recurring or custom
      schedule. You can adjust or cancel meetings anytime.
    </p>
  </>
)

export const WEBHOOK_URL_CONTENT = (
  <>
    <p>
      Enter a POST HTTP endpoint to receive the data
      collected during call. This can be any POST webhook
      url from services like Zapier, goHighLevel, your
      personal service endpoint etc.
    </p>
    <br />
    <p>
      <strong>Use Cases :</strong> Can be used for meeting
      bookings, Mail/Message(s) sending, etc.
    </p>
  </>
)

export const VOIP_CONTENT = (
  <>
    <p>
      <strong>
        Do you have contacts with VoIP numbers?
      </strong>
    </p>
    <br />
    <p>
      <strong>VoIP numbers</strong> require special
      handling because the Assistant might start speaking
      during the ring, due to carrier restrictions.
    </p>
    <p>
      Enabling this will ensure the Assistant only starts
      speaking after the user answers.
    </p>
    <br />
    <p>
      This feature is available at no additional cost for
      now.
    </p>
    <br />
    <p>
      <strong>
        Note: This feature is currently in Beta.
      </strong>
    </p>
  </>
)

export const CUSTOM_SMS_CONTENT2 = (
  <>
    <p>
      This feature ensures a smooth and efficient way to
      book your meetings, making the process as seamless
      and convenient as possible.
    </p>
    <br />
    <p>
      <strong>SMS Booking</strong>
    </p>
    <ul>
      <li>
        <p>
          <strong>What it Does:</strong> On a call, if a
          user wants to book a meeting, they will receive
          an SMS with a scheduling link.
        </p>
      </li>
      <br />
      <li>
        <p>
          <strong>Configuration :</strong> Customize the
          message body, provide the scheduling link, and
          set specific phrases (trigger prompts) that will
          automatically initiate the SMS booking flow
          during the call.
        </p>
      </li>
    </ul>
    <br />
    <p>
      <strong>
        Note: This feature is currently in Beta.
      </strong>
    </p>
  </>



)

export const SCHEDULING_CONST = (
  <>
    <p>
      <strong>
        Automate your campaign’s operation by defining
        specific working hours.
      </strong>
    </p>
    <br />
    <p>
      Specify <strong>Start Time</strong>,
      <strong>End Time</strong>, and
      <strong>Time Zone</strong>: Configure when your
      campaign should run.
    </p>
    <br />
    <p>
      <strong>How It Works :</strong>
    </p>
    <ul>
      <li>
        <p>
          <strong>Automatic Operation :</strong> The
          campaign starts and stops according to your
          schedule.
        </p>
      </li>
      <br />
      <li>
        <p>
          <strong>Daily Resumption : </strong>{" "}
          Automatically resumes each day within your set
          hours and continues until completion.
        </p>
      </li>
      <br />
      <li>
        <p>
          <strong>Manual Control :</strong> Easily stop or
          resume the campaign at any time.
        </p>
      </li>
    </ul>
  </>
)

export const SCHEDULING_CONST2 = (<>
  <p>
    <strong>
      Automate your campaign’s operation by defining
      specific working hours.
    </strong>
  </p>
  <br />
  <p>
    Specify <strong>Start Time</strong>,
    <strong>End Time</strong>, and
    <strong>Time Zone</strong>: Configure when your campaign
    should run.
  </p>
  <br />
  <p>
    <strong>How It Works :</strong>
  </p>
  <ul>
    <li>
      <p>
        <strong>Automatic Operation :</strong> The campaign
        starts and stops according to your schedule.
      </p>
    </li>
    <br />
    <li>
      <p>
        <strong>Daily Resumption : </strong> Automatically
        resumes each day within your set hours and continues
        until completion.
      </p>
    </li>
    <br />
    <li>
      <p>
        <strong>Manual Control :</strong> Easily stop or
        resume the campaign at any time.
      </p>
    </li>
  </ul>
</>)
export const CUSTOM_SMS_CONTENT3 = (
  <>
    <p>
      Set up customizable trigger prompts like "Send me a brochure" or "Can we set up an appointment?" during a call. When these prompts are spoken, an SMS with your predefined message in the template will be sent automatically.
    </p>


  </>
)
export const VOIP_CONTENT2 = (<>
  <p>
    <strong>Do you have contacts with VoIP numbers?</strong>
  </p>
  <br />
  <p>
    <strong>VoIP numbers</strong> require special handling
    because the Assistant might start speaking during the
    ring, due to carrier restrictions.
  </p>
  <p>
    Enabling this will ensure the Assistant only starts
    speaking after the user answers.
  </p>
  <br />
  <p>
    This feature is available at no additional cost for now.
  </p>
  <br />
  <p>
    <strong>Note: This feature is currently in Beta.</strong>
  </p>
</>)

export const VOICE_MAIL_CONTENT2 = (
  <>
    <p>
      <strong>Voicemail Detection: </strong>Toggle to enable or
      disable answering machine detection for outbound calls.
    </p>
    <br />
    <p>
      <strong>Enabled: </strong>Detects answering machines or
      voicemails and disconnects the call without deducting
      minutes from your quota.
    </p>
    <br />
    <p>
      <strong>Disabled: </strong>All calls proceed without
      detection.
    </p>
    <br />
    <p>
      <strong>
        Note:{" "}
        <p>
          This feature is currently disabled for{" "}
          <span style={{ color: "red", fontWeight: "bold" }}>
            Plivo Dialer
          </span>
          .
        </p>
      </strong>
    </p>
  </>
)

export const TRANSFER_TO_AGENT_CONTENT = (
  <>
    <p>
      During call if the contact wishes to talk to human, we
      will forward the call to these number(s). The call will
      be tried with all the number(s) but only connected with
      the first agent who picks up the call.
    </p>
  </>
)

export const ELEVEN_LABS_CONTENT = (
  <>
    <strong>
      You can find your ElevenLabs API key by following these
      steps :
    </strong>
    <ul>
      <li>Log in to your ElevenLabs account.</li>
      <li>
        Click your profile icon in the bottom-left corner.
      </li>
      <li>Click the API keys option.</li>
      <li>
        Click on create key Copy the key and paste it in here.
      </li>
    </ul>
  </>
)

export const INTRGRATION_DETAILS = (
  <>
    <strong>
      You can find your ElevenLabs API key by following these
      steps :
    </strong>
    <ul>
      <li>Log in to your ElevenLabs account.</li>
      <li>Click your profile icon in the bottom-left corner.</li>
      <li>Click the API keys option.</li>
      <li>
        Click on create key Copy the key and paste it in here.
      </li>
    </ul>
  </>
)
export const AUDIO_SAMPLE_CONTENT = (
  <p>
    This will be the main objective that your AI assistant will
    try to achieve once its in converstation with your contacts
  </p>
)

export const GENERAL_CONTENT = (
  <p>
    Enter the name of the company or business which the AI assistant
    represents.
  </p>
)

export const PHONE_NUMBER_CONTENT = (
  <p>This is the phone number to transfer the call to.</p>
)

export const WEBHOOK_URL_CONTENT2 = (
  <p>This is the Webhook URL that will be called.</p>
)

export const ACCOUNT_USER_CONTENT = (
  <p>
    This is a unique user ID for the Cal account.
  </p>
)

export const WARNING_CONTENT =
  "We recommend you to type your Assistant's Call Opening Message in the same language as set for your Assistant, for the best possible experience !!"
export const WEBHOOK_URL_VALIDATION = (<>
  <p>
    <strong>Webhook Url Format:</strong>
  </p>
  <p>✅ : Start with http:// or https://</p>
  <p>
    ✅ : Include a valid domain name (e.g., example.com) and avoid
    spaces.
  </p>
  <p>✅ : End with a valid top-level domain (e.g., .com, .org)</p></>)

export const WEBHOOK_URL_EXAMPLE = (<>
  <p>
    <strong>Examples :</strong>
  </p>
  <p>https://example.com</p>
  <p>https://example.com/path/to/resource</p>
  <p>https://www.example.com</p>
  <p>https://example.com/search?q=openai</p></>)