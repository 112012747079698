import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Avatar,
  Chip,
  Button,
  InputLabel,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { LOCAL_STORAGE } from "../../../../../data/Constants/hardData";
import { LANG_COUNTRY,LANGUAGES_LIST } from "../../../../../data/Constants/language";
import { ACCENT_TYPES,TTSPROVIDER_TYPES } from "../../../../../data/Constants/hardData";
import {
  getAssistantVoices,
  getLanguageAndGender,setShowAccent
} from "../../../../../data/store/assistantsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataFromLocalStorage,
  log,
  setDataInLocalStorage,
} from "../../../../../data/configs/utils";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import male from "../../../../../data/assets/img/male.png";
import female from "../../../../../data/assets/img/female.png";
import CloseIcon from "@mui/icons-material/Close";
import GenerateElevenLabsAudioSample from "../../../../AssistantSetup/AssistantCustomization/GenerateElevenLabsAudioSample";
import CustomHintPopover from "../../../../CustomHintPopover";
import * as _ from "lodash";

const AssistantChooseAccentDialogUI = ({
  selectedAccents,
  setSelectedAccents,
  handleSaveChanges,
  handleChangeAssistantChangedData,
  setDialogOpen,
}) => {
  const dispatch = useDispatch();
  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const user = useSelector((state) => state.authDetails.user);
  const installedAppNameArray = user?.installedApps;

  const ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.ssml_data
  );
  const updated_ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.ssml_data
  );
  const [voicesArray, setVoicesArray] = useState([]);
  const [isVoicesArrayLoading, setIsVoicesArrayLoading] = useState(false);
  const [langList, setLangList] = useState(LANGUAGES_LIST);
  const [yourElevenLabs, setYourElevenLabs] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    ssml_data?.lang ?? "en"
  );
  const [selectedGender, setSelectedGender] = useState(
    ssml_data?.gender ?? "male"
  );
  const [isGenerateAudioSampleDialogOpen, setIsGenerateAudioSampleDialogOpen] =
    useState(false);
  const [isElevenLabConsentMsgDialogOpen, setIsElevenLabConsentMsgDialogOpen] =
    useState(false);
  const [voiceProvider, setVoiceProvider] = useState(
    ssml_data?.tts_provider ?? TTSPROVIDER_TYPES.AZURE
  );
  const [isPlaying, setIsPlaying] = useState(null);
  const audioRefs = useRef({});
  const [sampleAudioUrl, setSampleAudioUrl] = useState(null);
  const [voiceData, setVoiceData] = useState({});

  const [sampleAudioLoading, setSampleAudioLoading] = useState(false);
  const [elevenlabsClonedVoiceAgreement, setElevenlabsClonedVoiceAgreement] =
    useState(false);
  // UseRef to store previous language and gender
  const prevLanguage = useRef(selectedLanguage);
  const prevGender = useRef(selectedGender);
  const prevProvider = useRef(voiceProvider);
  const [elevenLabUser, setElevenLabUser] = useState(false);
  const [accentType, setAccentType] = useState( ssml_data?.accentType ?? ACCENT_TYPES.VOICEGENIEdf);

  const handleUseVoice = (voice, Bypass = false) => {
    if (_.isEmpty(voice)) {
      return;
    }
    if (
      voice?.tts_provider === TTSPROVIDER_TYPES.ELEVENLABS &&
      !elevenlabsClonedVoiceAgreement &&
      voice?.isCloned &&
      !Bypass
    ) {
      setVoiceData(voice);
      setIsElevenLabConsentMsgDialogOpen(true);
    } else if (Bypass && elevenlabsClonedVoiceAgreement) {
      handleUseSelectedVoiceFromList(voice);
      setIsElevenLabConsentMsgDialogOpen(false);
    } else {
      handleUseSelectedVoiceFromList(voice);
    }
  };

  useEffect(() => {
    setElevenlabsClonedVoiceAgreement(
      getDataFromLocalStorage(LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT) ||
        false
    );
  }, []);

  const handleClonedVoiceAgreement = () => {
    setDataInLocalStorage(
      LOCAL_STORAGE.ELEVEN_LABS_CLONED_AGREEMENT,
      !elevenlabsClonedVoiceAgreement
    );
    setElevenlabsClonedVoiceAgreement(!elevenlabsClonedVoiceAgreement);
  };

  const handleUseSelectedVoiceFromList = (updatedData) => {
    const mergedData = {
      ...(ssml_data || {}),
      ...(updated_ssml_data || {}),
      ...updatedData, elevenLabUser, accentType
    };

    const filteredData = {
      vtype: mergedData.vtype,
      label: mergedData.label,
      gender: mergedData?.gender,
      language: mergedData.language,
      tts_provider: mergedData.tts_provider,
      voice_recording_id: mergedData.voice_recording_id,
      mediaUrl: mergedData.mediaUrl,
      lang: mergedData.lang,
      country: mergedData.countryCode ?? LANG_COUNTRY[mergedData.lang ?? "en"],
      max_duration: mergedData.max_duration,
      rengage_caller: mergedData.rengage_caller,
      rengage_count: mergedData.rengage_count,
      no_input: mergedData.no_input,
      speech_rate: mergedData.speech_rate,
      rengage_message: mergedData.rengage_message,
      ...(mergedData.tts_provider === "elevenlabs" && {
        model: mergedData.model ?? "eleven_turbo_v2_5",
      }),
      elevenLabUser : mergedData.elevenLabUser,
      accentType : mergedData.accentType,
    };

    setSelectedAccents(filteredData);

    handleSaveChanges(
      true,
      {
        callOpeningMessage: assistantInitialData?.callOpeningMessage,
        ssml_data: filteredData,
      },
      true
    );
    // setDialogOpen(false);
  };
 
    const handleFetchAllAssistantVoices = () => {
      setIsVoicesArrayLoading(true);

      let payload = { langCode: selectedLanguage, gender: selectedGender?.toLowerCase() ?? "male" };

      if (accentType === ACCENT_TYPES.TOPPICKS) {
        payload.topPicks = true;
        payload.accentType = ACCENT_TYPES.TOPPICKS;
        setElevenLabUser(false);
        setAccentType(ACCENT_TYPES.TOPPICKS);
      } else if (accentType === ACCENT_TYPES.VOICEGENIE) {
        payload.tts_provider = TTSPROVIDER_TYPES.AZURE;
        payload.accentType = ACCENT_TYPES.VOICEGENIE;
        setElevenLabUser(false);
        setAccentType(ACCENT_TYPES.VOICEGENIE);
      } else if (accentType === ACCENT_TYPES.ELEVENLABS_FREE) {
        payload.tts_provider = TTSPROVIDER_TYPES.ELEVENLABS;
        payload.accentType = ACCENT_TYPES.ELEVENLABS_FREE
        setElevenLabUser(false);
        setAccentType(ACCENT_TYPES.ELEVENLABS_FREE);
      } else if (accentType === ACCENT_TYPES.YOUR_ELEVENLABS) {
        payload.tts_provider = TTSPROVIDER_TYPES.ELEVENLABS;
        payload.accentType = ACCENT_TYPES.YOUR_ELEVENLABS;
        setElevenLabUser(true);
        setAccentType(ACCENT_TYPES.YOUR_ELEVENLABS);
      }

      dispatch(getAssistantVoices(payload))
        .unwrap()
        .then((res) => {
          setIsVoicesArrayLoading(false);
          setVoicesArray(res.data.voices);

          const matchedVoice = res?.data?.voices?.find((voice) => voice.label === ssml_data?.label);
          if (matchedVoice && ssml_data?.lang !== res?.data?.voices[0]?.langCode) {
            handleUseVoice(matchedVoice);
          }
        })
        .catch((err) => {
          setIsVoicesArrayLoading(false);
          log("ERR-getAllActions", err);
        });
    };

  const handleFetchAllAssistantVoicesLangsList = () => {
    dispatch(getLanguageAndGender())
      .unwrap()
      .then((res) => {
        setLangList(res?.data?.config ?? LANGUAGES_LIST); // Ensure this is correct
        setYourElevenLabs(res?.data?.yourElevenLabs);
      })
      .catch((err) => {
        log("ERR-getAllActions", err);
      });
  };

  const playPauseHandler = (item) => {
    const audioRef = audioRefs.current[item.label];
    if (isPlaying === item.label) {
      audioRef.pause();
      setIsPlaying(null);
    } else {
      audioRef.src = item?.mediaUrl;
      audioRef.play();
      setIsPlaying(item.label);
    }
  };
  const handleAudioEnded = (voice) => {
    setIsPlaying(null);
  };

  // Use effect to trigger API call when language or gender changes
  useEffect(() => {
    handleFetchAllAssistantVoices();
    handleFetchAllAssistantVoicesLangsList();
    if (
      prevLanguage.current !== selectedLanguage ||
      prevGender.current !== selectedGender ||
      prevProvider.current !== accentType
    ) {
      handleFetchAllAssistantVoices();
      prevLanguage.current = selectedLanguage;
      prevGender.current = selectedGender;
      prevProvider.current = accentType;
    }
  }, [selectedLanguage, selectedGender, accentType]);

  // Get gender options for the selected language
  const genderOptions =
    langList?.find((lang) => lang.shorthand === selectedLanguage)
      ?.allowedGenders || [];
      const voiceProviderOptions = [
        { label: "Top Picks", value: ACCENT_TYPES.TOPPICKS },
        { label: "VoiceGenie", value: ACCENT_TYPES.VOICEGENIE },
        { label: "ElevenLabs", value: ACCENT_TYPES.ELEVENLABS_FREE },
      ];
      
      if (yourElevenLabs) {
        voiceProviderOptions.push({ label: "Your ElevenLabs", value: ACCENT_TYPES.YOUR_ELEVENLABS });
      }
  return (
    <div>
      <Grid container item xs={12} spacing={2} mb={2}>
        <Grid item mt={2}>
          <InputLabel sx={{ marginTop: -1, top: -6, left: 10 }}>
            <Typography variant="caption">Language</Typography>
          </InputLabel>
          <FormControl>
            <Select
              label=" Language"
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "5px",
                    maxHeight: "400px",
                  },
                },
              }}
              sx={{ marginTop: -2, borderRadius: "5px", minWidth: "200px" }}
              fullWidth
              size="small"
              value={selectedLanguage}
              onChange={(e) => {
                const newLanguage = e.target.value;
                setSelectedLanguage(newLanguage);
              }}
            >
              {langList
                ?.slice()
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((item) => (
                  <MenuItem value={item.shorthand} key={item.shorthand}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={2}>
          <InputLabel sx={{ marginTop: -1, top: -6, left: 10 }}>
            <Typography variant="caption">Gender</Typography>
          </InputLabel>
          <FormControl>
            <Select
              label="Gender"
              sx={{ marginTop: -2, borderRadius: "5px", minWidth: "200px" }}
              fullWidth
              size="small"
              value={selectedGender}
              onChange={(e) => {
                const newGender = e.target.value;
                setSelectedGender(newGender);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "5px",
                  },
                },
              }}
              // disabled={!genderOptions.length} // Disable if no gender options available
            >
              {genderOptions?.map((gender) => (
                <MenuItem value={gender.value} key={gender.value}>
                  {gender.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={2}>
          <InputLabel sx={{ marginTop: -1, top: -6, left: 10 }}>
            <Typography variant="caption"> Voice Provider</Typography>
          </InputLabel>
          <FormControl>
            <Select
              label="VoiceProvider"
              sx={{ marginTop: -2, borderRadius: "5px", minWidth: "200px" }}
              fullWidth
              size="small"
              value={accentType}
              onChange={(e) => {
                const newProvider = e.target.value;
                setAccentType(newProvider);
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "5px",
                  },
                },
              }}
              // disabled={!genderOptions.length} // Disable if no gender options available
            >
             {voiceProviderOptions.map(({ label, value }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{ borderRadius: "10px", mb: 2 }}>
        <Table stickyHeader sx={{ minWidth: 1200 }} aria-label="voice table">
          {/* <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Audio Sample
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Avatar
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Specification
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Provider
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Chosen Accent
                </Typography>
              </TableCell>
              <TableCell>
                {ssml_data?.mediaUrl ? (
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        playPauseHandler(ssml_data);
                      }}
                      style={{ cursor: "pointer", margin: "-10px" }}
                    >
                      {isPlaying === ssml_data?.label ? (
                        <PauseIcon fontSize="small" />
                      ) : (
                        <PlayArrowIcon fontSize="small" />
                      )}
                    </IconButton>
                    <audio
                      ref={(ref) => {
                        if (ref) {
                          audioRefs.current[ssml_data?.label] = ref;
                          ref.addEventListener("ended", () =>
                            handleAudioEnded(ssml_data)
                          );
                        }
                      }}
                    />
                  </Grid>
                ) : null}
              </TableCell>
              <TableCell>
                <Avatar
                  alt={ssml_data?.label}
                  src={
                    ssml_data?.gender?.toLowerCase() === "male"
                      ? male
                      : ssml_data?.gender?.toLowerCase() === "female"
                      ? female
                      : "/default-avatar.png"
                  }
                />
              </TableCell>
              <TableCell>{ssml_data?.label}</TableCell>
              <TableCell>
                {ssml_data?.specifications?.map((items) =>
                  items !== null ? (
                    <Chip
                      sx={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                        margin: "5px",
                      }}
                      size="small"
                      label={items}
                    />
                  ) : null
                )}
              </TableCell>
              <TableCell>
                <IconButton size="small">
                  <Chip
                    size="small"
                    sx={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                    label={
                      ssml_data?.tts_provider === "elevenlabs"
                        ? "ElevenLabs"
                        : "Voicegenie"
                    }
                    color={
                      ssml_data?.tts_provider === "elevenlabs"
                        ? "warning"
                        : "success"
                    }
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton size="small">
                  <Chip
                    size="small"
                    sx={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                    label={
                      ssml_data?.gender
                        ? ssml_data?.gender.charAt(0).toUpperCase() +
                          ssml_data?.gender.slice(1)
                        : "Missing"
                    }
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton size="small">
                  <Chip
                    size="small"
                    sx={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                    }}
                    label={ssml_data?.language ?? selectedLanguage}
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                {ssml_data?.tts_provider === "elevenlabs" &&
                installedAppNameArray?.includes("elevenLabs") ? (
                  <Button
                    onClick={() => {
                      setIsGenerateAudioSampleDialogOpen(true);
                      // handleUseSelectedVoiceFromList(ssml_data);
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      borderRadius: "5px",
                      minWidth: "150px",
                      textTransform: "none",
                    }}
                  >
                    Generate Audio Sample
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {voicesArray?.length === 0 ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>No Accents Available Yet</h1>
        </Grid>
      ) : (
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 500, borderRadius: "10px", overflow: "auto" }}
        >
          <Table stickyHeader sx={{ minWidth: 1200 }} aria-label="voice table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Audio Sample
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Avatar
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Specification
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Provider
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Use Voice
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isVoicesArrayLoading
                ? Array.from(new Array(5)).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton variant="circular" width={30} height={30} />
                      </TableCell>
                      <TableCell>
                        <Skeleton variant="circular" width={40} height={40} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width="60%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton width="40%" style={{ marginRight: 5 }} />
                        <Skeleton width="40%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton width="60%" />
                      </TableCell>
                      <TableCell>
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={35}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                : voicesArray?.map((voice) => (
                    <TableRow key={voice._id}>
                      <TableCell>
                        {voice?.mediaUrl ? (
                          <Grid
                            item
                            xs={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                playPauseHandler(voice);
                              }}
                              style={{ cursor: "pointer", margin: "-10px" }}
                            >
                              {isPlaying === voice.label ? (
                                <PauseIcon fontSize="small" />
                              ) : (
                                <PlayArrowIcon fontSize="small" />
                              )}
                            </IconButton>
                            <audio
                              ref={(ref) => {
                                if (ref) {
                                  audioRefs.current[voice.label] = ref;
                                  ref.addEventListener("ended", () =>
                                    handleAudioEnded(voice)
                                  );
                                }
                              }}
                            />
                          </Grid>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <Avatar
                          alt={voice?.label}
                          src={
                            voice?.gender?.toLowerCase() === "male"
                              ? male
                              : voice?.gender?.toLowerCase() === "female"
                              ? female
                              : "/default-avatar.png"
                          }
                        />
                      </TableCell>
                      <TableCell>{voice.label}</TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          paddingBottom: 3,
                        }}
                      >
                        {voice?.specifications?.map((items) =>
                          items !== null ? (
                            <Chip
                              sx={{
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                margin: "5px",
                              }}
                              size="small"
                              label={items}
                            />
                          ) : null
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton size="small">
                          <Chip
                            size="small"
                            sx={{
                              borderTopRightRadius: 5,
                              borderBottomRightRadius: 5,
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5,
                            }}
                            label={
                              voice.tts_provider === "elevenlabs"
                                ? "ElevenLabs"
                                : "Voicegenie"
                            }
                            color={
                              voice.tts_provider === "elevenlabs"
                                ? "warning"
                                : "success"
                            }
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleUseVoice(voice);
                            dispatch(setShowAccent(true));
                          }}
                          variant="outlined"
                          size="small"
                          sx={{
                            borderRadius: "5px",
                            minWidth: "150px",
                            textTransform: "none",
                          }}
                        >
                          Use Voice
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog
        PaperProps={{
          sx: {
            width: 800,
            maxWidth: 800,
          },
        }}
        open={isGenerateAudioSampleDialogOpen}
        onClose={() => {
          setIsGenerateAudioSampleDialogOpen(false);
          setSampleAudioUrl(null);
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsGenerateAudioSampleDialogOpen(false);
            setSampleAudioUrl(null);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ padding: 0, paddingInline: 3, paddingBlock: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Generate Sample Audio
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} item>
            {ssml_data?.tts_provider === "elevenlabs" ? (
              <GenerateElevenLabsAudioSample
                setSampleAudioUrl={setSampleAudioUrl}
                updatedData={ssml_data}
                sampleAudioLoading={sampleAudioLoading}
                setSampleAudioLoading={setSampleAudioLoading}
              />
            ) : null}

            <Grid container marginTop={3}>
              {sampleAudioLoading ? (
                <Grid item xs={12}>
                  <div
                    style={{
                      backgroundColor: "#f0f0f0",
                      height: "40px",
                      borderRadius: "24px",
                      position: "relative",
                      marginBottom: "12px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#d3d3d3",
                        height: "8px",
                        width: "92%",
                        borderRadius: "4px",
                        position: "absolute",
                        top: "18px",
                        left: "30px",
                      }}
                    />
                  </div>
                </Grid>
              ) : sampleAudioUrl ? (
                <>
                  <Grid item xs={5} display="flex" alignItems="center" pl={1}>
                    <Typography
                      display="flex"
                      alignItems="flex-end"
                      variant="subtitle1"
                    >
                      Generated Sample Audio :
                      <CustomHintPopover
                        size="small"
                        maxWidth={400}
                        transformHorizontalPosition={"left"}
                        transformVerticalPosition={"center"}
                        anchorHorizontalPosition={"right"}
                        anchorVerticalPosition={"top"}
                        hintContent={"Generated audio of the chosen Accent."}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <audio
                      controls
                      key={sampleAudioUrl}
                      style={{ flex: 1, width: "100%" }}
                      src={sampleAudioUrl}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          sx: {
            width: 800,
            maxWidth: 800,
          },
        }}
        open={isElevenLabConsentMsgDialogOpen}
        onClose={() => setIsElevenLabConsentMsgDialogOpen(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsElevenLabConsentMsgDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ padding: 0, paddingInline: 3, paddingBlock: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Consent
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12} item>
            <Grid
              container
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Grid item xs={1}>
                <Checkbox
                  checked={elevenlabsClonedVoiceAgreement}
                  onChange={handleClonedVoiceAgreement}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" color="error" fontWeight="bold">
                  I hereby confirm that I possess all necessary rights or
                  consents to use this cloned voice sample and commit to not
                  using it for any unlawful, fraudulent, or harmful purposes
                </Typography>
              </Grid>
              <Button
                sx={{
                  marginBlock: "10px",
                  borderRadius: "5px",
                  minWidth: "80px",
                  textTransform: "none",
                }}
                size="small"
                variant="contained"
                disabled={!elevenlabsClonedVoiceAgreement}
                onClick={() => handleUseVoice(voiceData ?? {}, true)}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AssistantChooseAccentDialogUI;
