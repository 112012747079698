import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Stack,
  FormControl,
  FormLabel,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomHintPopover from "../../../CustomHintPopover";
import { isValidWebhookUrl } from "../../../../data/configs/regex";
import { enqueueSnackbar } from "notistack";
import WebhookIcon from "@mui/icons-material/Webhook";
import { Link } from "react-router-dom";
import { GET_A_WEBHOOK_CONTENT } from "../../../../data/Constants/tooltip";

const WebhookDialog = ({
  open,
  onClose,
  WebhookCreateAction,
  webhookUrlArray,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!open) {
      setName("");
      setDescription("");
      setWebhookUrl("");
      setError(false);
    }
  }, [open]);

  const handleSave = () => {
    if (!isValidWebhookUrl(webhookUrl)) {
      enqueueSnackbar(`Please enter a valid Webhook Url.`, {
        variant: "error",
      });
      setError(true);
      return;
    }
    if (webhookUrlArray?.includes(webhookUrl)) {
      enqueueSnackbar("This Webhook Url is already present!", {
        variant: "error",
      });
      setError(true);
      return;
    }
    WebhookCreateAction(webhookUrl, name, description);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          padding: "0px",
          paddingTop: 2,
          paddingInline: 3,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Webhook Setup</Typography>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Link
              to={"http://webhook.site"}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <CustomHintPopover
                icon={
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <WebhookIcon fontSize="12px" />
                    <Typography variant="caption">Get a webhook</Typography>
                  </Button>
                }
                showIcon={false}
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"center"}
                anchorHorizontalPosition={"right"}
                anchorVerticalPosition={"top"}
                hintContent={
                  GET_A_WEBHOOK_CONTENT
                }
              ></CustomHintPopover>
            </Link>
            <IconButton size="small" edge="end" onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 1 }}>
        {/* Name Field */}
        <FormControl size="small" fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              color="text.primary"
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
            >
              Name
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={<p>Enter a name for this webhook action.</p>}
            />
          </FormLabel>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
              },
            }}
            size="small"
            placeholder="webhook_action ( Minimum 2 characters )"
            variant="outlined"
            minRows={1}
            value={name}
            onChange={(e) => setName(e.target.value)}
            inputProps={{
              maxLength: 300,
            }}
            required
            fullWidth
          />
        </FormControl>

        {/* Description Field */}
        <FormControl fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              Description
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={
                <p>Provide a detailed description for this webhook action.</p>
              }
            />
          </FormLabel>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
              },
            }}
            size="small"
            placeholder="Optional: Enter a brief description of the webhook for reference (e.g., 'Sends user details to a support team."
            variant="outlined"
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{
              maxLength: 300,
            }}
            fullWidth
          />
          {description?.trim()?.length >= 300 && (
            <Typography color="error" variant="caption" paddingInline={2}>
              Only 300 characters allowed for the description.
            </Typography>
          )}
        </FormControl>

        {/* Webhook URL Field */}
        <FormControl size="small" fullWidth>
          <FormLabel sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              Webhook URL
            </Typography>
            <CustomHintPopover
              size="small"
              maxWidth={400}
              transformHorizontalPosition="left"
              transformVerticalPosition="top"
              anchorHorizontalPosition="right"
              anchorVerticalPosition="top"
              hintContent={<p>Specify the URL for the webhook.</p>}
            />
          </FormLabel>
          <TextField
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
              },
            }}
            size="small"
            placeholder="https://example.com/webhook"
            variant="outlined"
            value={webhookUrl}
            onChange={(e) => setWebhookUrl(e.target.value)}
            inputProps={{
              maxLength: 100,
            }}
            error={error}
            helperText={error ? "Please enter a valid URL" : ""}
            required
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ paddingInline: 3, marginBottom: 1 }}>
        <Button
          size="small"
          sx={{
            borderRadius: "10px",
            minWidth: "80px",
            textTransform: "none",
          }}
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          size="small"
          sx={{
            borderRadius: "10px",
            minWidth: "80px",
            textTransform: "none",
          }}
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={!name || !webhookUrl || name?.trim()?.length < 2}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WebhookDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  WebhookCreateAction: PropTypes.func.isRequired,
};

export default WebhookDialog;
