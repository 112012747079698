import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import initialState from "./initialState";
import {
  GET_ALL_CAMPAIGNS,
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  START_CAMPAIGN,
  GET_CAMPAIGN_OVERVIEW_DETAILS,
  GET_CALL_DETAILS,
  GET_ACTIVE_CALL_DATA,
  GET_CAMPAIGN_ANALYTICS_DATA,
  DOWNLOAD_CAMPAIGN_DATA,
  DOWNLOAD_CONTACT_LIST,
  FORCE_STOP_CAMPAIGN,
  GET_CALL_RECORDING_DETAILS,
  CAMPAIGN_COUNT,
  COPY_CURL_ID,
  VALIDATE_WEBHOOK,
  GET_SINGLE_CAMPAIGN_STATES,
  ADD_CAMPAIGN_CONTACTS,
  UPDATE_CAMPAIGN_STATUS,
  ADD_CAMPAIGN_CONTACTS_MANUALLY,
  START_CALLING_CONTACTS_AGAIN,
  VERIFY_MOBILE_NUMBER,
  AGENT_DASHBOARD_DATA,
  SYNC_INBOUND_WEBHOOK,
} from "./api";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import { METHOD_TYPES } from "../../Constants/hardData";

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    updateCampaignsState(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCampaigns.pending, (state, { meta }) => {
        if (meta?.arg?.skip === 0) {
          state.loading = true;
          state.loadError = false;
        }
      })
      .addCase(getAllCampaigns.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.loadError = false;
        if (payload?.data?.campaigns) {
          if (meta?.arg?.skip === 0) {
            state.campaigns = payload.data.campaigns;
            state.hasMore = payload.data.campaigns.length === meta?.arg?.limit;
          } else {
            state.campaigns = [...state.campaigns, ...payload.data.campaigns];
            state.hasMore = payload.data.campaigns.length === meta?.arg?.limit;
          }
        }
      })
      .addCase(getAllCampaigns.rejected, (state, { meta }) => {
        if (meta?.arg?.skip === 0) {
          state.loading = false;
          state.loadError = true;
        }
      })
      .addCase(createCampaign.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaign) {
          state.campaigns.unshift(payload.data.campaign);
        }
      })
      .addCase(createCampaign.rejected, (state, { payload }) => {
        if (payload?.data?.data?.showWarning) {
          state.showWarningDialog = payload?.data?.data?.showWarning;
        }
      })
      .addCase(updateCampaign.fulfilled, (state, { payload }) => {
        if (payload?.data?.updatedCampaign?.id) {
          const index = state.campaigns.findIndex(
            (item) => item.id === payload.data.updatedCampaign.id
          );
          if (index > -1) state.campaigns[index] = payload.data.updatedCampaign;
          if (state.selectedCampaign)
            state.selectedCampaign.status = payload.data.updatedCampaign.status;
        }
      })
      .addCase(deleteCampaign.fulfilled, (state, { payload }) => {
        if (payload?.data?.deletedCampaignId) {
          state.campaigns = state.campaigns.filter(
            (item) => item.id !== payload.data.deletedCampaignId
          );
        }
      })
      .addCase(startCampaign.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaign?.id) {
          const index = state.campaigns.findIndex(
            (item) => item.id === payload.data.campaign.id
          );
          if (index > -1) state.campaigns[index] = payload.data.campaign;
          if (state.selectedCampaign)
            state.selectedCampaign.status = payload.data.campaign.status;
        }
      })
      .addCase(startCampaign.rejected, (state, { payload }) => {
        if (payload?.data?.data?.showWarning) {
          state.showWarningDialog = payload?.data?.data?.showWarning;
        }
      })
      .addCase(
        getCampaignOverviewDetails.pending,
        (state, { meta, payload }) => {
          if (!meta?.arg?.hideLoader) {
            state.overviewLoading = true;
            state.overviewLoadError = false;
          }
        }
      )
      .addCase(getCampaignOverviewDetails.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaignCallData) {
          state.selectedCampaign = payload.data.campaign;
          state.campaignCallData = payload.data.campaignCallData;
          state.overviewTotalCount = payload.data.documentCount;
          state.overviewLoading = false;
          state.overviewLoadError = false;
        }
      })
      .addCase(getCampaignOverviewDetails.rejected, (state, { payload }) => {
        state.overviewLoading = false;
        state.overviewLoadError = true;
      })
      .addCase(getActiveCallData.fulfilled, (state, { payload }) => {
        if (payload?.data?.ongoingCalls)
          state.activeCallData = payload?.data?.ongoingCalls;
      })
      .addCase(getCampaignAnalytics.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaignAnalyticsData)
          state.analyticsData = payload?.data?.campaignAnalyticsData;
        state.analytics = payload?.data?.campaignAnalytics;
      })
      .addCase(getSingleCampaignStates.fulfilled, (state, { payload }) => {
        if (payload?.data?.campaign)
          state.singleCampaignStates = payload?.data?.campaign;
        state.endState = payload?.data?.campaign?.endState;
        state.campaignContactListId = payload?.data?.campaign?.contactListId;
      })
      .addCase(agentDashboard.fulfilled, (state, { payload }) => {
        if (payload?.data?.call) state.agentDetails = payload?.data?.call;
      })
      .addCase(getCurlId.fulfilled, (state, action) => {
        state.copyCurlDataLoading = false;
      })
      .addCase(addCampaignContactsViaFile.fulfilled, (state, { payload }) => {
        if (payload?.data?.contactList?.contactListId) {
          const index = state.contactList.findIndex(
            (item) =>
              item.contactListId === payload.data.contactList.contactListId
          );
          if (index > -1) {
            state.contactList = [
              ...state.contactList.slice(0, index),
              payload.data.contactList,
              ...state.contactList.slice(index + 1),
            ];
          }
        }
      });
  },
});

export const getAllCampaigns = createAsyncThunk(
  "campaigns/getAllCampaigns",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ALL_CAMPAIGNS,
        loader: false,
        data,
      },
      helpers
    )
);

export const getSingleCampaignStates = createAsyncThunk(
  "campaigns/getSingleCampaignStates",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_SINGLE_CAMPAIGN_STATES,
        method: METHOD_TYPES.GET,
        loader: false,
        data,
      },
      helpers
    )
);

export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_CAMPAIGN,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const campaignCount = createAsyncThunk(
  "campaigns/campaignCount",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CAMPAIGN_COUNT,
        method: METHOD_TYPES.GET,
        modalLoader: true,
        data,
      },
      helpers
    )
);

export const updateCampaign = createAsyncThunk(
  "campaigns/updateCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_CAMPAIGN,
        method: METHOD_TYPES.PUT,
        data,
        modalLoader: !data?.updateStatus,
      },
      helpers
    )
);

export const syncInboundWebhook = createAsyncThunk(
  "campaigns/syncInboundWebhook",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SYNC_INBOUND_WEBHOOK,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const downloadCampaignData = createAsyncThunk(
  "campaigns/downloadCampaignData",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DOWNLOAD_CAMPAIGN_DATA,
        method: METHOD_TYPES.POST,
        data,
        loader: true,
      },
      helpers
    )
);

export const exportContacts = createAsyncThunk(
  "campaigns/exportContacts",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DOWNLOAD_CONTACT_LIST,
        method: METHOD_TYPES.POST,
        data,
        loader: true,
      },
      helpers
    )
);

export const verifyMobileNumber = createAsyncThunk(
  "verifyMobileNumber",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VERIFY_MOBILE_NUMBER,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/deleteCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_CAMPAIGN,
        method: METHOD_TYPES.DELETE,
        data,
      },
      helpers
    )
);

export const startCampaign = createAsyncThunk(
  "campaigns/startCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: START_CAMPAIGN,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const getCampaignOverviewDetails = createAsyncThunk(
  "campaigns/getCampaignOverviewDetails",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_CAMPAIGN_OVERVIEW_DETAILS,
        data,
        method: METHOD_TYPES.POST,
        loader: false,
      },
      helpers
    )
);

export const getCallDetails = createAsyncThunk(
  "campaigns/getCallDetails",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_CALL_DETAILS,
        data,
      },
      helpers
    )
);

export const getActiveCallData = createAsyncThunk(
  "campaigns/getActiveCallData",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ACTIVE_CALL_DATA,
        data,
        loader: !data?.hideLoader,
      },
      helpers
    )
);

export const getCampaignAnalytics = createAsyncThunk(
  "campaigns/getCampaignAnalytics",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_CAMPAIGN_ANALYTICS_DATA,
        data,
        loader: !data?.hideLoader,
      },
      helpers
    )
);

export const forceStopCampaign = createAsyncThunk(
  "/campaigns/forceStopCampaign",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: FORCE_STOP_CAMPAIGN,
        method: METHOD_TYPES.POST,
        data,
        loader: true,
      },
      helpers
    )
);
export const addCampaignContactsViaFile = createAsyncThunk(
  "campaigns/addCampaignContactsViaFile",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ADD_CAMPAIGN_CONTACTS,
        data,
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        modalLoader: true,
        timeout: 0,
        forceFEHideEnquebar: true,
        loader: false,
      },
      helpers
    )
);
export const updateCampaignStatus = createAsyncThunk(
  "campaigns/updateCampaignStatus",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: UPDATE_CAMPAIGN_STATUS,
        method: METHOD_TYPES.PUT,
        data,
        modalLoader: !data?.updateStatus,
      },
      helpers
    )
);

export const addContact = createAsyncThunk(
  "campaigns/addContact",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ADD_CAMPAIGN_CONTACTS_MANUALLY,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const startCallingContactsAgain = createAsyncThunk(
  "campaigns/startCallingContactsAgain",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: START_CALLING_CONTACTS_AGAIN,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);

export const validateWebhookUrl = createAsyncThunk(
  "/campaigns/webhookTesting",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VALIDATE_WEBHOOK,
        method: METHOD_TYPES.POST,
        data,
        loader: false,
        forceFEHideEnquebar: true,
      },
      helpers
    )
);

export const getCurlId = createAsyncThunk(
  "campaigns/getSample_pushCallToCampaign_request",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: COPY_CURL_ID,
        method: METHOD_TYPES.GET,
        data,
        loader: true,
      },
      helpers
    )
);

export const agentDashboard = createAsyncThunk(
  "campaigns/agentDashboard",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: AGENT_DASHBOARD_DATA,
        method: METHOD_TYPES.GET,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const { updateCampaignsState } = campaignsSlice.actions;
export default campaignsSlice.reducer;
