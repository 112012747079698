import React, { useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import * as _ from "lodash";
import SettingsOverscanOutlinedIcon from "@mui/icons-material/SettingsOverscanOutlined";
import { CLEVERTAP_EVENT,EXISTING_SCRIPT_CALL_OPENING_MESSAGE,EXISTING_SCRIPT_GOAL,SCRIPT_TEMPLATES,GTAGS,SCRIPT_GUIDELINES} from "../../../../data/Constants/hardData";
import { ROUTE_PATH } from "../../../../data/Constants/url";
import ComponentWithSteps from "./GenerativeAI";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Tooltip,
} from "@mui/material";
import VariableInputEditor from "../../../MentionEditor";
import { handleCleverTapBtnClickWithPathname } from "../../../../data/configs/utils";
import { useSelector } from "react-redux";
import CustomHintPopover from "../../../CustomHintPopover";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
const ScriptExpandingCard = ({
  // script,
  handleChange,
  isExpanded,
  handleExpandClick,
  assistant,
  isSaveClicked,
  setIsSavedClicked,
  fromOnboarding,
  mentionList,
  preBot,
  handleSaveChanges,
  handleChangeAssistantChangedData,
  applySampleScriptForOnboarding,
}) => {
  const state=useSelector((state)=>state);
  const assistantUpdatedInfo = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo
  );
  ///////////////////////////////////
  const assistantScript = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.script
  );
  const themeMode = useSelector((state) => state.pageDetails.themeMode);

  const [script, setScript] = useState(assistantScript ?? "");
  useEffect(() => {
    setScript(assistantScript ?? "");
  }, [assistantScript]);
  /////////////////////////////////////////////////
  const {pathname} = useLocation()
  const [saveLoading, setSaveLoading] = useState(false);
  const user = useSelector((state) => state.authDetails.user);
  const scriptTextFieldRef = useRef(null);
  const [isOpenGenerateAssistantScript, setIsOpenGenerateAssistantScript] =
    useState(false);
  const [preBotState, setPreBotState] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("current");
  const [isTamplateChoosen, setIsTamplateChoosen] = useState(false);
  const [template, setTemplate] = useState(script);
  const [onceCliked, setOnceCliked] = useState(false);
  const editorRef = useRef(null);
  const [isAIScriptClosed, setsetAIScriptClosed] = useState(true);
  const [isScriptDialogOpen, setIsScriptDialogOpen] = useState(false);
  const [createManually, setCreateManually] = useState(() => {
    const storedValue = localStorage.getItem("createManually");
    // If value is present in local storage, parse it to boolean
    return JSON.parse(storedValue);
  });
  const [scriptDialogue, setScriptDialogue] = useState(false)
  useEffect(() => {
    if (!_.isEmpty(assistantUpdatedInfo)) {
      setSaveLoading(true);
    } else setSaveLoading(false);
  }, [assistantUpdatedInfo]);

  const handleSaveChangeAndTamplateChange = (newScript) => {
    setTemplate(newScript);
    if (newScript && newScript?.length > 0) handleChange({ script: newScript });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function replaceNewlinesWithBr(inputString) {
    return inputString.replace(/\n/g, "<br/>");
  }

  const handleOpenGenerateAssistantScript = () => {
    setIsOpenGenerateAssistantScript(true);
    handleCleverTapBtnClickWithPathname("Generate Assistant Script", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    // handleCleverTapBtnClick("Generate Assistant Script");
  };

  const handleUseExistingTamplates = () => {
    handleCleverTapBtnClickWithPathname("Use Existing Templates", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    // handleCleverTapBtnClick("Use Existing Templates");
    window.gtag("event", GTAGS.USE_EXISTING_TEMPLATE, {
      userID: user.userId,
    });
    setOnceCliked(true);
    setIsOpenGenerateAssistantScript(false);
    handleSetToValue(false);
    setTemplate(SCRIPT_TEMPLATES["existing script"]);
    handleChange({ script: SCRIPT_TEMPLATES["existing script"] });
    handleChange({ callOpeningMessage: EXISTING_SCRIPT_CALL_OPENING_MESSAGE });
    handleChange({ goal: EXISTING_SCRIPT_GOAL });
  };
  const handleApplyExistingTamplates = () => {
    handleCleverTapBtnClickWithPathname("Use Existing Templates", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    handleSaveChanges(
      true,
      {
        script: SCRIPT_TEMPLATES["existing script"],
        callOpeningMessage: EXISTING_SCRIPT_CALL_OPENING_MESSAGE,
        goal: EXISTING_SCRIPT_GOAL,
      },
      true
    );
    setScript(SCRIPT_TEMPLATES["existing script"]);
  };

  useEffect(() => {
    if (applySampleScriptForOnboarding) {
      handleApplyExistingTamplates();
    }
  }, [applySampleScriptForOnboarding]);

  const handleUseGenerativeAI = () => {
    handleCleverTapBtnClickWithPathname("Use Generative AI", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    setIsOpenGenerateAssistantScript(false);
    toggleModal();
    handleSetToValue(false);
  };
  const handleCreateManually = () => {
    window.gtag("event", GTAGS.SCRIPT_MANUALLY_CREATED, {
      userID: user.userId,
    });
    handleCleverTapBtnClickWithPathname("Create Script Manually", pathname, ROUTE_PATH.UPDATE_AND_TEST_ASSISTANT)
    // handleCleverTapBtnClick("Create Script Manually");
    setIsOpenGenerateAssistantScript(false);
    handleSetToValue(true);
    handleChange({ script: "" });
  };

  const handleClickOpenScriptDocumentation = () => {
    window.open(process.env.REACT_APP_SCRIPT_DOCUMENTATION_URL, "_blank");
  };

  const handleSetToValue = (value) => {
    setCreateManually(value);
    localStorage.setItem("createManually", JSON.stringify(value));
  };

  useEffect(() => {
    if (editorRef.current) editorRef.current.editor.focus();
  }, [createManually]);

  // useEffect(() => {
  //   if (editorRef.current) {
  //     const editorElement = editorRef.current.editor.root;
  //     console.log(editorElement, "editorElement");
  //     if (editorElement) {
  //       setTimeout(() => {
  //         editorElement.scrollTo(0,editorElement.scrollHeight);
  //       }, 0);
  //     }
  //   }
  // }, [script]);

  useEffect(() => {
    localStorage.setItem("createManually", JSON.stringify(createManually));
  }, [createManually]);

  useEffect(() => {
    if (isSaveClicked) {
      setSelectedTemplate("current");
      setIsSavedClicked(false);
    }
    setSelectedTemplate("current");
    setIsTamplateChoosen(false);
  }, [isSaveClicked, script]);

  useEffect(() => {
    if (scriptTextFieldRef.current) {
      scriptTextFieldRef.current.focus();
      scriptTextFieldRef.current.setSelectionRange(0, 0);
      scriptTextFieldRef.current.scrollTop = 0;
    }
  }, [isTamplateChoosen, selectedTemplate]);

  // useEffect(() => {
  //   if (!createManually)
  //     if (
  //       (script === "<p><br></p>" || script === "") &&
  //       isAIScriptClosed &&
  //       isSaveClicked
  //     ) {
  //       setIsOpenGenerateAssistantScript(true);
  //     }
  // }, [script, isAIScriptClosed, isSaveClicked]);

  // useEffect(() => {
  //   if (!createManually)
  //     if ((script === "<p><br></p>" || script === "") && isAIScriptClosed) {
  //       setIsOpenGenerateAssistantScript(true);
  //     }
  // }, [script]);

  useEffect(() => {
    if (preBot) {
      handleUseExistingTamplates();
      handleSaveChanges(true, {
        script: script !== "" ? script : SCRIPT_TEMPLATES["existing script"],
      });
    }
  }, [preBot]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        mt={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item xs={6}>
          <Typography
            display="flex"
            alignItems="center"
            variant="subtitle2"
            color="text.secondary"
          >
            Script
            <CustomHintPopover
              size="small"
              maxWidth={400}
              // hintTitle={'Bot Temperature Setting'}
              transformHorizontalPosition={"left"}
              transformVerticalPosition={"center"}
              anchorHorizontalPosition={"right"}
              anchorVerticalPosition={"top"}
              hintContent={
                "Compose a script outlining the dialogue flow of for your assistant's interactions with users. Include prompts, responses, and any relevant actions or decisions the assitant should take based on user input."
              }
            />
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            gap: 1,
          }}
        >
          <Box marginLeft={2} marginBottom={0.5}>
            <CustomHintPopover
              size="small"
              icon={<PsychologyAltOutlinedIcon />}
              maxWidth={400}
              // hintTitle={'Bot Temperature Setting'}
              transformHorizontalPosition={"left"}
              transformVerticalPosition={"center"}
              anchorHorizontalPosition={"right"}
              anchorVerticalPosition={"top"}
              hintContent={
                <Box sx={{ margin: 1 }}>
                  <Typography variant="subtitle2">
                    <strong>Add / Remove</strong> more
                    <strong>variables</strong> to contacts. These
                    <strong>variables</strong> can be injected into the
                    assistant script using <strong>@ symbol</strong>.
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    During call the AI assistant will pick values according to
                    the individual contact and will give a personalised
                    experience to the customer.
                  </Typography>
                  <br />
                  <Typography variant="subtitle2">
                    <strong>Example :</strong> Address, Purchase Date, Time,
                    etc.
                  </Typography>
                </Box>
              }
            />
          </Box>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderRadius: "5px",
              marginBottom: "5px",
              textTransform: "none",
            }}
            disabled={saveLoading || get(state, "assistantsDetails.assistantInitialInfo.defaultAssistant", false)}
            onClick={() => setScriptDialogue(true)}
          >
            Apply Sample Script
          </Button>
          <Dialog
        PaperProps={{
          sx: {
            maxWidth: 650,
            borderRadius: '10px',
            textTransform: 'none'
          },
        }}
        aria-labelledby="vg-apply-sample-script"
        open={scriptDialogue}
        onClose={() => setScriptDialogue(false)}
        fullWidth
      > 
              <IconButton
          aria-label="close"
          onClick={() => setScriptDialogue(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle fullWidth sx={{ justifyContent: "center" }}>
          Apply Sample Script
        </DialogTitle>
        <DialogContent>
          <Typography align="left">
            Your script will be overwritten by our sample script. Do you really wish to continue ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 2, justifyContent: "center" }}>
          <Button
          disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
            onClick={(e) => {
              e.stopPropagation();
              setScriptDialogue(false)
            }}
            color="primary"
            variant="outlined"
            sx={{
              textTransform: 'none',
              borderRadius: '10px',
              minWidth: '80px'
            }}
          >
            Cancel
          </Button>
          <Button
          disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
            onClick={(e) => {
              e.stopPropagation();
              handleApplyExistingTamplates()
              setScriptDialogue(false)
            }}
            color="primary"
            variant="contained"
            sx={{
              textTransform: 'none',
              borderRadius: '10px',
              minWidth: '80px'
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: "5px",
              marginBottom: "5px",
              textTransform: "none",
              color: "themetext.primary"
            }}
            disabled={
              !script?.trim() ||
              script === "<p><br></p>" ||
              saveLoading ||
              assistant.script === script || get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)
            }
            onClick={() => {
              handleSaveChanges(
                true,
                {
                  script: script,
                },
                true
              );
              setIsScriptDialogOpen(false);
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", maxHeight: "410px" }}>
        <Collapse
          in={isExpanded}
          timeout="auto"
          unmountOnExit
          sx={{ marginBottom: 2, position: "relative" }}
        >
          <>
            <VariableInputEditor
              style={{
                paddingBlock: "10px",
                maxHeight: "270px",
                minHeight: "100px",
                overflowY: "scroll",
              }}
              innerStyle={{
                marginInline: 5,
                marginBlock: 0,
              }}
              formattedEnvVariables={mentionList}
              placeholder="Press @ to add a dynamic variable"
              value={
                (script || template) &&
                replaceNewlinesWithBr(script ?? template)
              }
              onTextChange={
                (htmlText) => {
                  // handleChangeAssistantChangedData({
                  //   script: htmlText,
                  // });
                  setScript(htmlText);
                }
                // handleSaveChangeAndTamplateChange(htmlText)
              }
              handleChange={handleChange}
              autoFocus
              editorRef={editorRef}
              wordLimit={user?.isSuperUser ? 100000 : 1500}
            />
            <Box sx={{ position: "absolute", bottom: "5px", right: "20px" }}>
              <IconButton onClick={() => setIsScriptDialogOpen(true)}>
                <SettingsOverscanOutlinedIcon />
              </IconButton>
            </Box>

            <Dialog
              open={isScriptDialogOpen}
              onClose={() => setIsScriptDialogOpen(false)}
              PaperProps={{
                sx: {
                  maxWidth: 1200,
                  width: 1200,
                },
              }}
            >
              <DialogTitle>
                Edit Script
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    right: 12,
                    top: 12,
                  }}
                  onClick={() => setIsScriptDialogOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <VariableInputEditor
                  style={{
                    paddingBlock: "10px",
                    maxHeight: "450px",
                    minHeight: "100px",
                    overflowY: "scroll",
                  }}
                  innerStyle={{
                    marginInline: 5,
                    marginBlock: 0,
                  }}
                  formattedEnvVariables={mentionList}
                  placeholder="Press @ to select a variable"
                  value={
                    (script || template) &&
                    replaceNewlinesWithBr(script ?? template)
                  }
                  onTextChange={(htmlText) => {
                    // handleChangeAssistantChangedData({
                    //   script: htmlText,
                    // });
                    setScript(htmlText);
                  }}
                  handleChange={handleChange}
                  autoFocus
                  editorRef={editorRef}
                  wordLimit={user?.isSuperUser ? 100000 : 1500}
                />
              </DialogContent>
              <DialogActions
                sx={{
                  paddingInline: 3,
                  marginTop: "-15px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                  variant="contained"
                  size="small"
                  sx={{
                    borderRadius: "5px",
                    minWidth: "80px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    handleSaveChanges(
                      true,
                      {
                        script: script,
                      },
                      true
                    );
                    setIsScriptDialogOpen(false);
                  }}
                >
                  Save
                </Button>
                <Button
                disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                  variant="outlined"
                  size="small"
                  sx={{
                    borderRadius: "5px",
                    minWidth: "80px",
                    textTransform: "none",
                  }}
                  onClick={() => setIsScriptDialogOpen(false)}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </Collapse>
        {/* Choose Template Dialog */}
        {/* disabled={
                    ((fromOnboarding && onceCliked) ||
                      !assistant?.goal?.trim()) &&
                    !preBot
                  } */}
        <Dialog
          // open={isOpenGenerateAssistantScript}
          open={isOpenGenerateAssistantScript && !preBotState}
          onClose={() => setIsOpenGenerateAssistantScript(false)}
        >
          <Grid margin={2}>
            <DialogTitle>
              <Typography align="center" variant="h5" fontWeight={500} mb={2}>
                Generate Assistant Script
              </Typography>

              {/* Close button */}
              <IconButton
                size="small"
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  right: 12,
                  top: 12,
                  backgroundColor: "background.paper",
                  "&:hover": {
                    backgroundColor: "background.default",
                  },
                }}
                onClick={() => setIsOpenGenerateAssistantScript(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography align="center" variant="body2">
                A script outlines the conversation between the bot and
                customers, guiding the bot's responses and interactions
                throughout the call to make sure the conversation flows as
                required.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Grid container>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="space-between"
                  align="center"
                  mb={2}
                >
                  <Grid item xs={12}>
                    <Tooltip title="Use Existing Template to generate your assistant script">
                      <Button
                        sx={{ minWidth: "220px" }}
                        onClick={handleUseExistingTamplates}
                        variant="contained"
                      >
                        Use Existing Template
                      </Button>
                    </Tooltip>
                  </Grid>
                  {/* <Grid item xs={6}>
                  <Tooltip title="Use Generate AI to generate your assistant script">
                    <span>
                      <Button
                        sx={{ minWidth: "220px" }}
                        // disabled={fromOnboarding}
                        onClick={handleUseGenerativeAI}
                        variant="contained"
                        color="primary"
                      >
                        Use Generative AI
                      </Button>
                    </span>
                  </Tooltip>
                </Grid> */}
                </Grid>
                <Grid item xs={12} align="center" mb={2}>
                  <Typography>OR</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="center"
                  align="center"
                  mb={2}
                >
                  <Grid item xs={6}>
                    <Tooltip title="Create script manually by yourself">
                      <span>
                        <Button
                        disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                          sx={{ minWidth: "220px" }}
                          // disabled={fromOnboarding}
                          onClick={handleCreateManually}
                          variant="contained"
                        >
                          Create Script Manually
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Dialog>
        <ComponentWithSteps
          setsetAIScriptClosed={setsetAIScriptClosed}
          assistant={assistant}
          handleChange={handleChange}
          isModalOpen={isModalOpen}
          closeModal={toggleModal}
          setIsOpenGenerateAssistantScript={setIsOpenGenerateAssistantScript}
        />
      </Box>
    </>
  );
};

export default ScriptExpandingCard;
