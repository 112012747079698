import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PricingRedirectButton from '../PricingRedirectButton'
import { Container, Grid, Tooltip } from '@mui/material'
import { useLocation, NavLink } from 'react-router-dom';
import { ROUTE_PATH } from '../../data/Constants/url';
import CampaignIcon from '@mui/icons-material/Campaign';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
function DynamicBanner() {
  const location = useLocation();
  const dynamicBanner = useSelector(
    (state) => state.authDetails?.dynamicBanner
  );
  const [bannerDetails, setBannerDetails] = useState({
    textColor: dynamicBanner?.textColor || "black",
    backGroundColor: dynamicBanner?.backgroundColor || "#00e379",
    bodyMessage: (dynamicBanner?.bodyMessage?.length > 150 ? `${dynamicBanner.bodyMessage.substring(0, 130)}...` : dynamicBanner?.bodyMessage) || "Welcome to our VoiceGenie Dashboard",
    bannerLink: (dynamicBanner?.bannerLink?.length > 100 ? `${dynamicBanner.bannerLink.substring(0, 100)}...` : dynamicBanner?.bannerLink) || "",
  });
  useEffect(() => {
    setBannerDetails({
      textColor: dynamicBanner?.textColor || "black",
      backGroundColor: dynamicBanner?.backgroundColor || "#00e379",
      bodyMessage: (dynamicBanner?.bodyMessage?.length > 150 ? `${dynamicBanner.bodyMessage.substring(0, 130)}...` : dynamicBanner?.bodyMessage) || "Welcome to our VoiceGenie Dashboard",
      bannerLink: (dynamicBanner?.bannerLink?.length > 100 ? `${dynamicBanner.bannerLink.substring(0, 100)}...` : dynamicBanner?.bannerLink) || "",
    });
  }, [dynamicBanner]);

  return (
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: bannerDetails?.backGroundColor,
        color: bannerDetails?.textColor,
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "bold"
      }}
    >
      <Grid item container xs={12} sx={{
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Grid display={"flex"} item mr={2}> {bannerDetails?.backGroundColor === "red" ? (<ErrorIcon fontSize='small' />) : bannerDetails?.backGroundColor === "orange" ? (<WarningIcon fontSize='small' />) : bannerDetails?.backGroundColor === "#00e379" ? (<CampaignIcon fontSize='small' />) : null} </Grid>
        <Grid display={"flex"} item mr={2} >{bannerDetails?.bodyMessage} </Grid>
        {bannerDetails?.bannerLink ? (<Grid display={"flex"} item mr={4}>
          <Grid display={"flex"} item mr={1}>: </Grid> <Tooltip title={bannerDetails?.bannerLink} placement='top'><a href={bannerDetails?.bannerLink} style={{
            textDecoration: 'underline', color: bannerDetails?.backGroundColor === "#00e379" ? "blue" : "white"
          }}>{bannerDetails?.bannerLink}</a></Tooltip></Grid>) : null}
      </Grid>
    </Container>
  )
}
export default DynamicBanner