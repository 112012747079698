import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, Typography, TextField } from "@mui/material";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import { GENERAL_CONTENT } from "../../../data/Constants/tooltip";
import get from "lodash/get";
export default function GeneralSettings({
  handleChange,
  handleChangeAssistantChangedData
}) {
  const state=useSelector((state)=>state);
  const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const assistantCompanyName = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.companyName
  );

  const [companyName, setcompanyName] = useState(assistantCompanyName ?? "");

  useEffect(() => {
    setcompanyName(assistantCompanyName ?? "");
  }, [assistantCompanyName]);

  return (
    <div>
      <FormControl margin="dense" fullWidth required>
        <FormLabel sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            display="flex"
            alignItems="center"
            variant="subtitle2"
            color="text.primary"
            fontWeight="bold"
          >
            Company Name
          </Typography>
          <CustomHintPopover
            size="small"
            maxWidth={400}
            // hintTitle={'Bot Temperature Setting'}
            transformHorizontalPosition={"left"}
            transformVerticalPosition={"bottom"}
            anchorHorizontalPosition={"left"}
            anchorVerticalPosition={"top"}
            hintContent={
            GENERAL_CONTENT
            }
          />
        </FormLabel>
        {/* <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px", // Applying 10px border-radius inline for the input
            },
          }}
          size="small"
          placeholder="Make sales outbound calls"
          variant="outlined"
          minRows={1}
          multiline
          value={goal ?? ""}
          onChange={(e) => {
            handleChangeAssistantChangedData({ goal: e.target.value });
            setGoal(e.target.value);
          }}
          inputProps={{
            maxLength: 300,
          }}
          required
          fullWidth
        /> */}
        <TextField
          disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px", // Applying 10px border-radius inline for the input
              padding: 0, // Setting padding to zero
              margin: 0,
              paddingBlock: 0.5,
              paddingInline: 1,
            },
            "& .MuiInputBase-input": {
              fontSize: "0.8rem", // Setting text size to match 'caption' typography (typically 12px)
            },
          }}
          size="small"
          placeholder="Enter Assistant's Company name"
          variant="outlined"
          minRows={1}
          value={companyName ?? ""}
          onChange={(e) => {
            handleChangeAssistantChangedData({ companyName: e.target.value });
            setcompanyName(e.target.value);
          }}
          fullWidth
          inputProps={{
            maxLength: 150,
          }}
          required
          multiline
        />
        {assistant?.companyName?.trim()?.length >= 150 && (
          <Typography color="error" variant="caption" paddingInline={2}>
            Only 150 characters allowed for company name.
          </Typography>
        )}
      </FormControl>
    </div>
  );
}
