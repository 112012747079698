import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as Sentry from "@sentry/react";
import "./index.scss";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals"
import { initiateCleverTap } from "./data/configs/clevertap";
import { persistor,store } from "./data/store";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("saas-dashboard-voice-genie")
);

const posthogKey = process.env.REACT_APP_POSTHOG_KEY;
const posthogHost = process.env.REACT_APP_POSTHOG_HOST;

if (posthogKey) {
  posthog.init(posthogKey, {
    api_host: posthogHost,
    capture_pageview: false,
  });
}

root.render(
  posthogKey ? (
    <PostHogProvider client={posthog}>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <App />
        </LocalizationProvider>
        </PersistGate>
      </Provider>
    </PostHogProvider>
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <App />
      </LocalizationProvider>
      </PersistGate>
    </Provider>
  )
);

if (
  process.env.REACT_APP_CLEVERTAP_ENABLE &&
  process.env.REACT_APP_CLEVERTAP_TOKEN
) {
  initiateCleverTap();
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (
      registration &&
      registration.waiting &&
      window.confirm("New version available!  refresh to update your app?")
    ) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(true);
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
