import { Box, Divider, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DynamicVariableForm from "./DynamicVariableForm";
import DynamicVariablesList from "./DynamicVariablesList";
import { useDispatch, useSelector } from "react-redux";
import { getDynamicVariablesList } from "../../data/store/dynamicVariableSlice";
import { enqueueSnackbar } from "notistack";
import { log } from "../../data/configs/utils";
import CloseIcon from '@mui/icons-material/Close';

const DynamicVariablesDrawer = ({ children }) => {
  const dispatch = useDispatch();
  const dynamicVariableList = useSelector((state) => state.dynamicVariables);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      dispatch(getDynamicVariablesList())
        .unwrap()
        .then((res) => log("Response-dynamic_variables: ", res))
        .catch((err) => {
          enqueueSnackbar("Failed to fetch dynamic variables", {
            variant: "error",
          });
          log("ERROR-get_dynamic_variable: ", err);
        });
    }
  }, [dispatch, open]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box>
      <Box onClick={toggleDrawer(true)} sx={{ cursor: "pointer" }}>
        {children}
      </Box>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ padding: 2, display: "flex", flexDirection: "column", gap: 1, maxWidth:'500px' }}
        >
        <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          marginBottom: 2,
        }}
        >
          <Typography variant="h6" fontWeight={'bold'}>Dynamic variables</Typography>
          <CloseIcon onClick={toggleDrawer(false)} sx={{
            cursor: 'pointer',
          }}/>
        </Box>
          <Divider />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb:2 }}>
            <DynamicVariableForm />
            <Divider />
          </Box>
          <DynamicVariablesList
            variables={dynamicVariableList?.dynamicVariables}
          />
        </Box>
      </Drawer>
    </Box>
  );
};

export default DynamicVariablesDrawer;
