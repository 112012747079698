import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import initialState from "./initialState"
import { fetchDataAndProceedWithToolkit } from "../helpers"
import {
  CREATE_KNOWLEDGE_BASE,
  UPDATE_KNOWLEDGE_BASE,
  DELETE_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE_LIST,
  DOWNLOAD_KNOWLEDGE_BASE_DATA,TRAIN_KNOWLEDGE_BASE,EDIT_KNOWLEDGE_BASE,GET_DOCUMENT_KNOWLEDGE_BASE,ADD_DOCUMENT_KNOWLEDGE_BASE,CRAWL_KNOWLEDGE_BASE,GET_KNOWLEDGE_BASE,TRAINING_STATUS,CRAWL_STATUS,DELETE_DOCUMENT,DELETE_FETCHED_URLS
} from "./api"
import { METHOD_TYPES } from "../../Constants/hardData"

const knowledgeBasesSlice = createSlice({
  name: "knowledgeBases",
  initialState,
  reducers: {
    updateKnowledgeBasesState(state, action) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getKnowledgeBaseList.pending, (state) => {
        state.loading = true
        state.loadError = false
      })
      .addCase(getKnowledgeBaseList.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBases) {
          state.knowledgeBaseList = payload.data.knowledgeBases
          state.loading = false
          state.loadError = false
        }
      })
      .addCase(getKnowledgeBaseList.rejected, (state) => {
        state.loading = false
        state.loadError = true
      })
      .addCase(createKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBase)
          state.knowledgeBaseList = [
            ...state.knowledgeBaseList,
            payload.data.knowledgeBase,
          ]
      })
      .addCase(updateKnowledgeBase.fulfilled, (state, { payload }) => { 
        if (payload?.data?._id) {
          const index = state.knowledgeBaseList.findIndex(
            (item) => item._id === payload.data._id
          );
          if (index > -1) {
            const existingKnowledgeBase = state.knowledgeBaseList[index];
            state.knowledgeBaseList = [
              ...state.knowledgeBaseList.slice(0, index),
              {
                ...existingKnowledgeBase,
                ...payload.data,
                notConnectedUrls: payload.data.notConnectedUrls || existingKnowledgeBase.notConnectedUrls,
                connectedUrls: payload.data.connectedUrls || existingKnowledgeBase.connectedUrls,
              },
              ...state.knowledgeBaseList.slice(index + 1),
            ];
            state.notConnectedUrls = payload.data.notConnectedUrls || [];
            state.connectedUrls = payload.data.connectedUrls || [];
          }
        }
      })
      .addCase(deleteKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?._id) {
          const index = state.knowledgeBaseList.findIndex(
            (item) => item._id === payload.data._id
          )
          if (index > -1) {
            state.knowledgeBaseList = [
              ...state.knowledgeBaseList.slice(0, index),
              ...state.knowledgeBaseList.slice(index + 1),
            ]
          }
        }
      })
      .addCase(getKnowledgeBase.pending, (state) => {
        state.singleKnowledgeBaseLoading = true;
        state.singleKnowledgeBaseError = null;
      })
      .addCase(getKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBase) {
          state.singleKnowledgeBase = payload.data
          state.singleKnowledgeBaseLoading = false;
          state.singleKnowledgeBaseError = false;
        }
      })
      .addCase(getKnowledgeBase.rejected, (state, action) => {
        state.singleKnowledgeBaseLoading = false;
        state.singleKnowledgeBaseError = action.payload || 'Failed to fetch data';
      })
      .addCase(trainKnowledgeBase.pending, (state) => {
        state.trainingLoading = true;
        state.trainingError = false;
      })
      .addCase(trainKnowledgeBase.fulfilled, (state, { payload }) => {
        state.trainingLoading = false;
        if (payload?.data) {
          state.trainingResponse = payload.data;
        }
      })
      .addCase(trainKnowledgeBase.rejected, (state, action) => {
        state.trainingLoading = false;
        state.trainingError = action.payload || true;
      })   
      .addCase(getTrainingStatus.pending, (state) => {
        state.trainingLoading = true; 
      })
      .addCase(getTrainingStatus.fulfilled, (state, { payload }) => {
        state.trainingLoading = false;
        state.trainingStatus = payload?.status;
      })
      .addCase(getTrainingStatus.rejected, (state, action) => {
        state.trainingLoading = false;
        state.trainingError = action.payload || true;
      })
      .addCase(addDocumentToKnowledgeBase.fulfilled, (state, { payload }) => {
        if (payload?.data?.knowledgeBaseList?._id) {
          if (Array.isArray(state.knowledgeBaseList)) {
            const index = state.knowledgeBaseList.findIndex(
              (item) => item._id === payload.data.knowledgeBaseList._id
            );
            if (index > -1) {
              state.knowledgeBaseList = [
                ...state.knowledgeBaseList.slice(0, index),
                payload.data.knowledgeBaseList,
                ...state.knowledgeBaseList.slice(index + 1),
              ];
            }
          } else {
            state.knowledgeBaseList = [payload.data.knowledgeBaseList];
          }
        }
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documentUrls = action.payload.data.fileUrls;
      })
      .addCase(getCrawlStatus.pending, (state) => {
        state.crawlLoading = true;
      })
      .addCase(getCrawlStatus.fulfilled, (state, { payload }) => {
        state.crawlLoading = false;
        state.crawlStatus = payload?.status; 
        state.notConnectedUrls=payload?.data?.notConnectedUrls;
      })
      .addCase(getCrawlStatus.rejected, (state, action) => {
        state.crawlLoading = false;
        if (action.payload && action.payload.message) {
          state.crawlError = action.payload.message;
        } else {
          state.crawlError = "An unexpected error occurred. Please try again.";
        }
      })
      
      .addCase(deleteDocument.pending, (state) => {
        state.deleteDocumentLoading = true;
        state.deleteDocumentError = null;
      })
      .addCase(deleteDocument.fulfilled, (state, { payload }) => {
        state.deleteDocumentLoading = false;
        if (payload?.deletedKnowledgeBaseDocument) {
          const deletedUrl = payload.deletedKnowledgeBaseDocument;
          state.documentUrls = state.documentUrls?.filter((url) => url !== deletedUrl);
          state.deleteDocumentSuccess = true;
        }
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.deleteDocumentLoading = false;
        state.deleteDocumentError = action.payload || "Failed to delete document.";
      })
      .addCase(deleteFetchedUrls.pending, (state) => {
        state.deleteUrlsLoading = true;
      })
      .addCase(deleteFetchedUrls.fulfilled, (state, { payload }) => {
        state.deleteUrlsLoading = false;
        if (payload?.success) {
          const deletedUrls = payload?.deletedUrls || [];
          state.notConnectedUrls = state.notConnectedUrls?.filter((url) => !deletedUrls.includes(url));
        }
      })
      .addCase(deleteFetchedUrls.rejected, (state, action) => {
        state.deleteUrlsLoading = false;
        state.deleteUrlsError = action.payload || true;
      });
  },
})

export const createKnowledgeBase = createAsyncThunk(
  "knowledgeBases/createKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: CREATE_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "application/json; charset=utf-8",
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)

export const updateKnowledgeBase = createAsyncThunk(
  "knowledgeBases/updateKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: EDIT_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.PUT,
        loader: false,
        modalLoader: true,
      },
      helpers
    )
)
export const getKnowledgeBaseList = createAsyncThunk(
  "knowledgeBases/getKnowledgeBaseList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_KNOWLEDGE_BASE_LIST,
        data,
        loader: false,
      },
      helpers
    )
)

export const deleteKnowledgeBase = createAsyncThunk(
  "knowledgeBases/deleteKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
);

export const getKnowledgeBase = createAsyncThunk(
  "knowledgeBases/getKnowledgeBase",
  async (knowledgeBaseId, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: `${GET_KNOWLEDGE_BASE}?knowledgeBaseId=${knowledgeBaseId}`,
        method: METHOD_TYPES.GET,
        loader: false, 
      },
      helpers
    )
);
export const trainKnowledgeBase = createAsyncThunk(
  "knowledgeBases/trainKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        modalLoader: true,
        url: TRAIN_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "application/json",
        loader: true,
      },
      helpers
    )
)
export const getTrainingStatus = createAsyncThunk(
  "knowledgeBases/getTrainingStatus",
  async (knowledgeBaseId, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: `${TRAINING_STATUS}?knowledgeBaseId=${knowledgeBaseId}`,
        method: METHOD_TYPES.GET,
        loader: false, 
      },
      helpers
    )
);

export const crawlWebsiteUrl = createAsyncThunk(
  "knowledgeBases/crawlWebsiteUrl",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        modalLoader: true,
        url: CRAWL_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "application/json",
        loader: true,
      },
      helpers
    )
)

export const addDocumentToKnowledgeBase = createAsyncThunk(
  "knowledgeBases/addDocumentToKnowledgeBase",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: ADD_DOCUMENT_KNOWLEDGE_BASE,
        data,
        method: METHOD_TYPES.POST,
        contentType: "multipart/form-data",
        modalLoader: true,
        timeout: 0,
        forceFEHideEnquebar: true,
        loader: false,
      },
      helpers
    )
    
);
export const getDocuments = createAsyncThunk(
  "knowledgeBases/getDocuments",
  async ({ type = "pdf", knowledgeBaseId }, helpers) => {
    try {
      const url = `${GET_DOCUMENT_KNOWLEDGE_BASE}?knowledgeBaseId=${knowledgeBaseId}&type=${type}`;
      return await fetchDataAndProceedWithToolkit(
        {
          url,
          method:METHOD_TYPES.GET, 
          contentType: "application/json; charset=utf-8", 
          loader: true, 
        },
        helpers
      );
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "knowledgeBases/deleteDocument",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_DOCUMENT,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
);

export const getCrawlStatus = createAsyncThunk(
  "knowledgeBases/getCrawlStatus",
  async (knowledgeBaseId, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: `${CRAWL_STATUS}?knowledgeBaseId=${knowledgeBaseId}`,
        method: METHOD_TYPES.GET,
        loader: false, 
      },
      helpers
    )
);

export const downloadKnowledgeData = createAsyncThunk(
  "knowledgeBases/downloadKnowledgeData",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DOWNLOAD_KNOWLEDGE_BASE_DATA,
        data,
      },
      helpers
    )
)

export const deleteFetchedUrls = createAsyncThunk(
  "knowledgeBases/deleteFetchedUrls",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_FETCHED_URLS,
        data,
        method: METHOD_TYPES.DELETE,
      },
      helpers
    )
);

export const { updateKnowledgeBasesState } = knowledgeBasesSlice.actions
export default knowledgeBasesSlice.reducer
