export const THEME_MODE = Object.freeze({
    LIGHT: "light",
    DARK: "dark",
  });
  
  export const THEME_VARIABLE = Object.freeze({
    APP_HEADER_HEIGHT: 64,
    APP_SIDER_COLLAPSE_WIDTH: 64,
    APP_SIDER_WIDTH: 250,
    ONBOARDING_HEADER_HEIGHT: 150,
  });