import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import CustomHintPopover from "../../../CustomHintPopover";
import useAudioStreamElevenLabsAudioSamples from "../../../../data/hooks/useAudioStreamElevenLabsAudioSamples";
import { AUDIO_SAMPLE_CONTENT } from "../../../../data/Constants/tooltip";

const GenerateElevenLabsAudioSample = ({
  updatedData,
  setSampleAudioUrl,
  sampleAudioLoading,
  setSampleAudioLoading,
}) => {
  const token = useSelector((state) => state.authDetails.token);

  const [text, setText] = useState("");
  const [showTextBox, setShowTextBox] = useState(false);
  const [payload, setPayload] = useState(null);

  // Hook to fetch and stream audio
  const { audioUrl, error, loading, fetchAudio } =
    useAudioStreamElevenLabsAudioSamples(payload, token);

  useEffect(() => {
    if (audioUrl) {
      setSampleAudioUrl(audioUrl);
    }
  }, [audioUrl, setSampleAudioUrl]);

  useEffect(() => {
    if (loading) {
      setSampleAudioLoading(true);
    } else setSampleAudioLoading(false);
  }, [loading]);

  useEffect(() => {
    if (payload) {
      fetchAudio(); // Trigger the audio fetching process when payload changes
    }
  }, [payload, fetchAudio]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleGenerateAudio = () => {
    // Ensure text is not empty and updatedData values are present
    if (text.trim() && updatedData?.vtype) {
      setPayload({
        voice_id: updatedData.vtype,
        model_id: "eleven_turbo_v2_5",
        user_text: text.trim() || "",
      });
    }
  };

  useEffect(() => {
    setText("");
    setShowTextBox(false);
    setPayload(null);
  }, [updatedData]);

  return (
    <Grid container spacing={2} mt={2} alignItems="center">
      <Grid
        container
        xs={12}
        item
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <FormControl margin="dense" fullWidth>
            <FormLabel sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle2"
                color="text.secondary"
              >
                Text to Generate
              </Typography>
              <CustomHintPopover
                size="small"
                maxWidth={400}
                // hintTitle={'Bot Temperature Setting'}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"top"}
                anchorHorizontalPosition={"right"}
                anchorVerticalPosition={"top"}
                hintContent={
                AUDIO_SAMPLE_CONTENT
                }
              />
            </FormLabel>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px", // Applying 10px border-radius inline for the input
                  padding: 0, // Setting padding to zero
                  margin: 0,
                  paddingBlock: 0.5,
                  paddingInline: 2,
                },
                "& .MuiInputBase-input": {
                  fontSize: "0.9rem", // Setting text size to match 'caption' typography (typically 12px)
                },
              }}
              size="small"
              placeholder="eg : Hello how are you doing?"
              variant="outlined"
              minRows={1}
              multiline
              value={text}
              onChange={handleTextChange}
              required
              fullWidth
            />
          </FormControl>
          {/* <TextField
              size="small"
              label="Enter Text"
              value={text}
              multiline
              onChange={handleTextChange}
              fullWidth
            /> */}
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          marginBlock={2}
        >
          <Button
            sx={{
              borderRadius: "5px",
              minWidth: "80px",
              textTransform: "none",
            }}
            size="small"
            variant="contained"
            onClick={handleGenerateAudio}
          >
            Generate
          </Button>
        </Grid>
      </Grid>

      {/* {loading && (
        <Grid item>
          <Box mt={2}>
            <CircularProgress />
          </Box>
        </Grid>
      )} */}

      {/* {error && (
        <Grid item>
          <Box mt={2} color="red">
            <Typography variant="body1">Error: {error}</Typography>
          </Box>
        </Grid>
      )} */}
    </Grid>
  );
};

export default GenerateElevenLabsAudioSample;
