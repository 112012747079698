import GroupIcon from "@mui/icons-material/Group";
import TtyIcon from "@mui/icons-material/Tty";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import CampaignIcon from "@mui/icons-material/Campaign";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import TtyOutlinedIcon from "@mui/icons-material/TtyOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HistoryIcon from "@mui/icons-material/History";
import ApiIcon from "@mui/icons-material/Api";
import AppsIcon from "@mui/icons-material/Apps";
import IMG_SIGNUP_1 from "../assets/img/signupCarousel1.png";
import IMG_SIGNUP_2 from "../assets/img/signupCarousel2.png";
import IMG_SIGNUP_3 from "../assets/img/signupCarousel3.png";
import IMG_SIGNUP_4 from "../assets/img/signupCarousel4.png";
import HUBSPOT_LOGO from "../assets/img/hubspot_logo.png";
import DARK_HUBSPOT_LOGO from "../assets/img/HubSpot-Night.png";
import DARK_GOHIGHLEVEL_LOGO from "../assets/img/gohighlevel_dark.jpg";
import GOHIGHLEVEL_LOGO from "../assets/img/gohighlevel.png";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { MenuItem } from "@mui/material";
import React from "react";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PhoneCallbackOutlinedIcon from "@mui/icons-material/PhoneCallbackOutlined";
export const ROUTE_PATH = Object.freeze({
    //---------------- AUTH ROUTES ----------------
    LOGIN: "/login",
    AGENT_DASHBOARD: "/agentdashboard",
    SIGNUP: "/signup",
    APPSUMO_SIGNUP: "/redeem/AppSumo",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    VERIFY_GOOGLE_AUTH: "/verify-google-auth",
    VERIFY_EMAIL: "/verify-email",
    SIGNUP_PASSWORD: "/signup-password",
  
    //-------------- ROOT PAGES ROUTES -------------
    WELCOME: "/onboarding",
    MICRO_SURVEY: "/onboarding/survey",
    CREATE_FIRST_ASSISTANT: "/onboarding/createAssistant",
    UPDATE_AND_TEST_ASSISTANT: "/onboarding/updateAssistant",
  
    //---------------- APP ROOT ROUTES ----------------
  
    ASSISTANTS: "/",
    EDIT_ASSISTANT: "/editAssistant",
    PHONE_NUMBERS: "/phone-numbers",
    CONTACT_LIST: "/contact-list",
    EDIT_CONTACT: "/contact-list/:contactListId",
    SETTINGS: "/settings",
    CAMPAIGNS: "/campaigns",
    EDIT_CAMPAIGN: "/campaigns/:campaignId",
    IN_BOUND: "/in-bound",
    EDIT_IN_BOUND: "/in-bound/:inBoundId",
    KNOWLEDGE_BASES: "/knowledge-bases",
    CALL_HISTORY: "/callhistory",
    CALL_HISTORY_AND_CAMPAIGN_CALL_DETAIL: "/call-details",
    SUPPORT: "/support",
    DEVELOPERSAPI: "/developers-api",
    HUBSPOT_VERIFICATION: "/hubspot-verify",
    GOHIGHLEVEL_VERIFICATION: "/gohigh-verify",
    INTEGRATIONS: "/integrations",
    PRICING: "/pricing",
    BILLING: "/billing",
    THANK_YOU: "/thank-you",
  
    //---------------- APP SELECTED VOICE AGENT ROUTES ----------------
    VA_OVERVIEW: "",
  });

  export const APP_PAGES = Object.freeze({
    //---------------- AUTH PAGES ----------------
    LOGIN: "Login",
    SIGNUP: "Signup",
    APPSUMO_SIGNUP: "AppSumo Signup",
    FORGOT_PASSWORD: "Forgot Password",
    RESET_PASSWORD: "Reset Password",
    VERIFY_EMAIL: "Verify Email",
    INTEGRATION_VERIFICATION: "Verification",
    SIGNUP_PASSWORD: "Signup Password",
    THANK_YOU: "Thank You",
  
    //-------------- ROOT PAGES -------------
    WELCOME: "Welcome",
    MICROSURVEY: "Survey",
    CREATE_FIRST_ASSISTANT: "Create Assistant",
  
    //---------------- APP ROOT PAGES ----------------
    PHONE_NUMBERS: {
      PAGE: "Phone Numbers",
      DESCRIPTION:
        "Acquire a Dialer ( Twilio ) Phone Number to connect with your users. This will facilitate your outbound calls, ensuring seamless communication.",
    },
    SETTINGS: {
      PAGE: "Settings",
      DESCRIPTION: "",
    },
    CAMPAIGNS: {
      PAGE: "Campaigns",
      DESCRIPTION:
        "Campaigns are where you bring everything together including Assistant, Phone Number and Contact List. Using Campaigns you reach your contacts and gather post call insights.",
    },
    IN_BOUND: {
      PAGE: "In-Bound",
      DESCRIPTION:
        "IN-Bound page description with unique searchable text asdfasdasdfsfd",
    },
    CONTACT_LIST: {
      PAGE: "Contact List",
      DESCRIPTION:
        "Simplify audience segmentation by uploading your contact lists. This empowers you to categorize & target your customers effectively.",
    },
    ASSISTANTS: {
      PAGE: "Assistants",
      DESCRIPTION:
        "Assistant is the Generative AI agent that talks to your contacts. It follows it's the assigned script and goal. It can be customised as per the needs ( like the language in which it communicates accent, etc ).",
    },
    KNOWLEDGE_BASES: {
      PAGE: "Knowledge Bases",
      DESCRIPTION:
        "Enrich your bot's capacity to respond to your audience's queries by providing training data & scripts. Customize your knowledge base, and effortlessly connect it to your assistant.",
    },
    CALL_HISTORY: {
      PAGE: "Call History",
      DESCRIPTION:
        "Track and review the history of all calls made through your system. Gain insights into call volume, duration, and outcomes to optimize your communication strategies.",
    },
    CALL_HISTORY_AND_CAMPAIGNS_CALL_DETAIL: {
      PAGE: "Call Details",
      DESCRIPTION:
        "Access detailed information about each individual call, including timestamps, caller information, and call outcomes. Use this data to fine-tune your campaigns and improve engagement with your contacts.",
    },
    DEVELOPERSAPI: {
      PAGE: "Developer APIs",
      DESCRIPTION:
        "Developer APIs provide access to VoiceGenie Functionalities. For example you can add contacts to Recurring campaigns over an API. Users need to generate developer credentials to access these functionalities.",
    },
    SUPPORT: {
      PAGE: "Support",
      DESCRIPTION:
        "Whenever you require assistance, we're here to help. Access the support you need.",
    },
    INTEGRATIONS: {
      PAGE: "Integrations",
      DESCRIPTION:
        "Integrate apps to supercharge VoiceGenie. Connect different tools and services to streamline your workflow and maximize efficiency.",
    },
  
    PRICING: {
      PAGE: "Pricing",
      DESCRIPTION:
        "Explore our range of plans, each designed to meet your specific business requirements. Choose the one that perfectly aligns with your needs.",
    },
    BILLING: {
      PAGE: "Billing",
      DESCRIPTION:
        "Access all the information on your current & previous subscriptions, including plan specifics, usage details, & much more.",
    },
  
    //---------------- APP SELECTED VOICE AGENT PAGES ----------------
    VA_OVERVIEW: "Overview",
    EDIT_ASSISTANT: "Edit Assistant",
    EDIT_CONTACT: "Contacts",
    EDIT_PROFILE: "Edit Profile",
    EDIT_CAMPAIGN: "Overview",
    EDIT_IN_BOUND: "Overview",
  });

  export const SIDER_LIST = [
    {
      icon: <GroupOutlinedIcon />,
      activeIcon: <GroupIcon />,
      appPage: APP_PAGES.ASSISTANTS.PAGE,
      description: APP_PAGES.ASSISTANTS.DESCRIPTION,
      route: ROUTE_PATH.ASSISTANTS,
    },
    {
      icon: <TtyOutlinedIcon />,
      activeIcon: <TtyIcon />,
      appPage: APP_PAGES.PHONE_NUMBERS.PAGE,
      description: APP_PAGES.PHONE_NUMBERS.DESCRIPTION,
      route: ROUTE_PATH.PHONE_NUMBERS,
    },
    {
      icon: <PermContactCalendarOutlinedIcon />,
      activeIcon: <PermContactCalendarIcon />,
      appPage: APP_PAGES.CONTACT_LIST.PAGE,
      description: APP_PAGES.CONTACT_LIST.DESCRIPTION,
      route: ROUTE_PATH.CONTACT_LIST,
    },
    {
      icon: <PsychologyOutlinedIcon />,
      activeIcon: <PsychologyIcon />,
      appPage: APP_PAGES.KNOWLEDGE_BASES.PAGE,
      description: APP_PAGES.KNOWLEDGE_BASES.DESCRIPTION,
      route: ROUTE_PATH.KNOWLEDGE_BASES,
    },
    {
      icon: <CampaignOutlinedIcon />,
      activeIcon: <CampaignIcon />,
      appPage: APP_PAGES.CAMPAIGNS.PAGE,
      description: APP_PAGES.CAMPAIGNS.DESCRIPTION,
      route: ROUTE_PATH.CAMPAIGNS,
    },
    {
      icon: <PhoneCallbackOutlinedIcon />,
      activeIcon: <PhoneCallbackIcon />,
      appPage: APP_PAGES.IN_BOUND.PAGE,
      description: APP_PAGES.IN_BOUND.DESCRIPTION,
      route: ROUTE_PATH.IN_BOUND,
    },
  
    // {
    //   icon: <SettingsOutlinedIcon />,
    //   activeIcon: <SettingsIcon />,
    //   appPage: APP_PAGES.SETTINGS.PAGE,
    //   description: APP_PAGES.SETTINGS.DESCRIPTION,
    //   route: ROUTE_PATH.SETTINGS,
    // },
    {
      icon: <HistoryIcon />,
      activeIcon: <HistoryIcon />,
      appPage: APP_PAGES.CALL_HISTORY.PAGE,
      description: APP_PAGES.CALL_HISTORY.DESCRIPTION,
      route: ROUTE_PATH.CALL_HISTORY,
    },
    {
      icon: <AppsIcon />,
      activeIcon: <AppsIcon />,
      appPage: APP_PAGES.INTEGRATIONS.PAGE,
      description: APP_PAGES.INTEGRATIONS.DESCRIPTION,
      route: ROUTE_PATH.INTEGRATIONS,
    },
    {
      icon: <ApiIcon />,
      activeIcon: <ApiIcon />,
      appPage: APP_PAGES.DEVELOPERSAPI.PAGE,
      description: APP_PAGES.DEVELOPERSAPI.DESCRIPTION,
      route: ROUTE_PATH.DEVELOPERSAPI,
    },
    {
      icon: <SupportAgentIcon />,
      activeIcon: <SupportAgentIcon />,
      appPage: APP_PAGES.SUPPORT.PAGE,
      description: APP_PAGES.SUPPORT.DESCRIPTION,
      route: ROUTE_PATH.SUPPORT,
    },
  ];
  export const PlatformIntegrationStepsData = [
    {
        title: "Sign Up",
        key: "signedUp",
    },
    {
        title: "Creating First Assistant",
        button: "Create First AI Assistant",
        route: ROUTE_PATH.ASSISTANTS,
        howTo:
            "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/assistants",
        key: "everCreatedAssistant",
    },
    {
        title: "Providing Phone Number",
        button: "Add First Phone Number",
        route: ROUTE_PATH.PHONE_NUMBERS,
        howTo:
            "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/phone-numbers",
        key: "everCreatedPhoneNumber",
    },
    {
        title: "Adding Contact List",
        button: "Create First Contact",
        route: ROUTE_PATH.CONTACT_LIST,
        howTo:
            "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/contact-list",
        key: "everCreatedContactList",
    },
    {
        title: "Running your First Campaign!",
        button: "Run Your First Campaign",
        route: ROUTE_PATH.CAMPAIGNS,
        howTo:
            "https://voicegenie.gitbook.io/voicegenie-ai/product-guides/campaigns",
        key: "everStartedCampaign",
    },
];
