import { Box, Grid, Typography, Select, MenuItem, Button ,Menu,Dialog,DialogContent,DialogTitle,IconButton,Tooltip,Divider} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import * as _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { WEBHOOK_TOOL_TIP,INFORMATION_TO_BE_COLLECTED_TOOL_TIP } from "../../../data/Constants/tooltip";
import { ANSWER_TYPE,INITIAL_ENTITIES } from "../../../data/Constants/hardData";
import { POST_CALL_ANALYSIS_CONTENT } from "../../../data/Constants/tooltip";
import AddIcon from "@mui/icons-material/Add";
import QuestionInputComponent from "../../CampaignInputComponent"
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { popAnimation } from "../../../data/configs/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import get from "lodash/get";
const PostCallAnalysisComponent = ({
  handleChange,
  handleChangeAssistantChangedData,handledQuestionDialog,questions,anchorEl,setAnchorEl,handleMenuItemClick,setEditingIndex,setUpdatedQuestion,updatedQuestion,handleQuestionSave,setAddQuestion,setQuestionDialogisOpen,addQuestion,questionDialogisOpen,questionDialogisClose,setQuestionDialogisClose,text,setText,handleQuestionDelete,handleEditQuestionClick,handleSubmit,handleSubmitQuestions
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
     const state=useSelector((state)=>state);
  const assistantInitialData = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  
  const handleMenuOpen = (event, index) => {
    setMenuAnchor(event.currentTarget);
    setMenuOpenIndex(index);
  };
  
  const handleMenuClose = () => {
    setMenuAnchor(null);
    setMenuOpenIndex(null);
  };
  
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Post Call Analysis
              <Box mb={0.3}>
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    POST_CALL_ANALYSIS_CONTENT
                  }
                />
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}  display="flex"
              alignItems="center"
              >
          Information to be collected
          <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                    INFORMATION_TO_BE_COLLECTED_TOOL_TIP
                  }
                />
          </Typography>

          <Button
                  variant="outlined"
                  startIcon={<AddIcon/>}

                  sx={{
                    minWidth: "40px",
                    color: "text.secondary",fontSize:"10px", mb:1
                  }}
                  onClick={(e) => handledQuestionDialog(e)} 
                  disableElevation
                  disabled={questions?.length >= 5 || get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                >
                  Add Question
                  <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {ANSWER_TYPE?.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => handleMenuItemClick(option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Button>
        </Grid>
      </Grid>
      <Grid
                      container
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {questions?.map((question, index) => (
                        <Grid key={index} item xs={12}>
                          <Box sx={{ marginBlock: 1}}>
                            <Grid container >
                              <Grid container item xs={10}>
                                <Grid item xs={12}>
                                  <Typography>
                                    <strong> {question?.question}</strong>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography>
                                    {question?.type}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography>
                                    {question?.shortHand}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={2}
                                sx={{ display: "flex", alignItems: "flex-start" }}
                              >
                                {/* <Grid
                                  container
                                  xs={12}
                                  justifyContent="flex-end"
                                  alignItems="center"
                                >
                                  <Grid item>
                                    <ModeEditIcon
                                      onClick={() => {
                                        handleEditQuestionClick(index);
                                      }}
                                      sx={{
                                        "&:hover": {
                                          animation: `${popAnimation} 0.3s ease-in-out forwards`,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <DeleteIcon
                                      onClick={() => handleQuestionDelete(index)}
                                      aria-label="delete"
                                      sx={{
                                        "&:hover": {
                                          animation: `${popAnimation} 0.3s ease-in-out forwards`,
                                        },
                                      }}
                                    />
                                  </Grid>
                                </Grid> */}
                                <Grid container xs={12} justifyContent="flex-end" alignItems="center">
                                  <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Grid>

                                {/* Popover Menu */}
                                <Menu
                                  anchorEl={menuAnchor}
                                  open={menuOpenIndex === index}
                                  onClose={handleMenuClose}
                                  onClick={(e) => e.stopPropagation()} // Prevent unwanted clicks
                                >
                                  <MenuItem onClick={() => handleEditQuestionClick(index)}>
                                    <ModeEditIcon fontSize="small" sx={{ marginRight: 1 }} />
                                    Edit
                                  </MenuItem>
                                  <MenuItem onClick={() => handleQuestionDelete(index)}>
                                    <DeleteIcon fontSize="small" color="error" sx={{ marginRight: 1 }} />
                                    Delete
                                  </MenuItem>
                                </Menu>

                              </Grid>
                            </Grid>
                          </Box>
                          <Divider/>
                        </Grid>
                        
                      ))}
                    </Grid>

      {addQuestion ? (
                <Grid item xs={12} mt={2} mb={2}>
                  <QuestionInputComponent
                    updatedQuestion={null}
                    editEnable={false}
                    setAddQuestion={setAddQuestion}
                    saveQuestion={handleQuestionSave}
                    questionDialogisOpen={questionDialogisOpen}
                    setQuestionDialogisOpen={setQuestionDialogisOpen}
                    questionDialogisClos={questionDialogisClose}
                    setQuestionDialogisClose={setQuestionDialogisClose}
                    text={text}
                    setText={setText}
                    handleChangeAssistantChangedData={handleChangeAssistantChangedData}
                    handleSubmit={handleSubmitQuestions}
                    questions={questions}
                  />
                </Grid>
              ) : (
                <Grid>
                  <Tooltip
                    title={
                      questions?.length >= 5
                        ? "You can add maximum 5 question. Delete anyone to add another."
                        : ""
                    }
                  >
                    <span>
                      <Button
                        sx={{
                          minWidth: "200px",
                          mt: 0.5,
                          color: "text.secondary",
                        }}
                        onClick={(e) => handledQuestionDialog(e)}
                        disableElevation
                        disabled={questions?.length >= 5}
                      ></Button>
                    </span>
                  </Tooltip>
                </Grid>
              )}

      <Dialog
        PaperProps={{
          style: {
            maxWidth: "800px",
          },
        }}
        aria-labelledby="edit-post-call-analysis-questions"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        fullWidth
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowDialog(false);
            setEditingIndex(null);
            setUpdatedQuestion(null);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Edit Your Question</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <QuestionInputComponent
              updatedQuestion={updatedQuestion}
              editEnable={true}
              saveQuestion={handleQuestionSave}
              setAddQuestion={setAddQuestion}
              setShowDialog={setShowDialog}
              setQuestionDialogisOpen={setQuestionDialogisOpen}
              handleChangeAssistantChangedData={handleChangeAssistantChangedData}
              handleSubmit={handleSubmitQuestions}
              questions={questions}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PostCallAnalysisComponent;
