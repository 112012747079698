import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { filterArrayBySearchText } from "../../../data/configs/utils";
import DynamicVariableNameView from "../DynamicVariableNameView";

const DynamicVariablesList = ({ variables }) => {
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (variables?.length) setSearchResults(variables);
  }, [variables, variables?.length]);

  const handleSearch = (text) => {
    const filteredData =
      variables.length > 0
        ? filterArrayBySearchText(text, variables, "name")
        : variables;
    setSearchResults(filteredData);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}
    >
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', mb:2, width:'100%'}}>
        <Typography variant="h6" fontWeight={'bold'}>Variable list</Typography>
        <TextField
          sx={{borderRadius: 3, width: 200 }}
          id="variable"
          type="text"
          size="small"
          placeholder="Search variables"
          variant="outlined"
          onChange={(e) => handleSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" sx={{ color: "text.disabled" }} />
              </InputAdornment>
            ),
          }}
          fullWidth
          required
        />
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
        {searchResults?.length > 0 ? (
          <Box>
            <TableContainer sx={{ border: "1px solid #ccc", borderRadius: 1 }}>
              <Table
                sx={{
                  overflow: "hidden",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "background.bgContainer",
                    }}
                  >
                    <TableCell sx={{ padding: 1, textAlign: "left" }}>
                      <Typography
                        variant="inherit"
                        sx={{
                          fontWeight: "bold",
                          paddingLeft: 1,
                          color: "primary.main",
                        }}
                      >
                        Variable
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 1, textAlign: "left" }}>
                      <Typography
                        variant="inherit"
                        sx={{ fontWeight: "bold", color: "primary.main" }}
                      >
                        Description
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResults.map((item, index) => (
                    <TableRow key={index} sx={{

                    }}>
                      <TableCell sx={{
                        padding: 1,
                        textAlign: "left",
                        verticalAlign: "top",
                      }}>
                        <DynamicVariableNameView name={item?.name} />
                      </TableCell>
                      <TableCell sx={{
                        padding: 1,
                        textAlign: "left",
                        verticalAlign: "top",
                      }}>
                        <Typography variant="body2">
                          {item?.description}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1">No variables found</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DynamicVariablesList;
