import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Paper from "@mui/material/Paper";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ borderradius }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: borderradius,
      },
      "&:first-of-type": {
        borderRadius: borderradius,
      },
    },
  })
);

const CustomToggleGroup = ({
  children,
  paperSxProps,
  borderRadius,
  ...restProps
}) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        display: "inline-block",
        borderRadius: 5,
        bgcolor: "background.paper",
        ...paperSxProps,
      }}
    >
      <StyledToggleButtonGroup
        size="small"
        color="primary"
        borderradius={borderRadius}
        exclusive
        {...restProps}
      >
        {children}
      </StyledToggleButtonGroup>
    </Paper>
  );
};

CustomToggleGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  paperSxProps: PropTypes.object,
  borderRadius: PropTypes.number,
};

CustomToggleGroup.defaultProps = {
  borderRadius: 40,
  paperSxProps: {},
};

export default CustomToggleGroup;
