import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stack,
  Typography,
  Popover,
  Divider,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import male from "../../../../data/assets/img/male.png";
import female from "../../../../data/assets/img/female.png";
import AssistantChooseAccentDialogUI from "./AssistantChooseAccentDialogUI";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import get from "lodash/get";

const AssistantChooseAccents = ({
  handleSaveChanges,
  handleChangeAssistantChangedData,
}) => {
  
 const state=useSelector((state)=>state);
  const ssml_data = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.ssml_data
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAccents, setSelectedAccents] = useState(ssml_data);
  const [dialogOpen, setDialogOpen] = useState(false);
  const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );

  useEffect(() => {
    setSelectedAccents(ssml_data);
  }, [ssml_data]);
  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-popover" : undefined;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      marginBottom={0}
      gap={2}
      sx={{ width: "100%" }}
    >
      <Button
       disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
        size="small"
        disableRipple
        sx={{ borderRadius: "10px" }}
        variant="outlined"
        onClick={() => setDialogOpen(true)}
        startIcon={
          <Avatar
            alt="Remy Sharp"
            src={
              selectedAccents?.gender?.toLowerCase() === "male"
                ? male
                : selectedAccents?.gender?.toLowerCase() === "female"
                ? female
                : male
            }
            sx={{ width: 25, height: 25 }}
          />
        }
        // endIcon={
        //   <Stack direction="row" alignItems="center" spacing={1}>
        //     <KeyboardArrowDownIcon fontSize="small" />
        //     <Box
        //       onClick={(e) => {
        //         e.stopPropagation();
        //         handleSettingsClick(e);
        //       }}
        //       sx={{
        //         display: "flex",
        //         gap: 1,
        //         alignItems: "center",
        //         cursor: "pointer",
        //       }}
        //     >
        //       <Divider
        //         orientation="vertical"
        //         flexItem
        //         sx={{ bgcolor: "grey.400" }}
        //       />
        //       <IconButton size="medium" sx={{ padding: 0, color: "inherit" }}>
        //         <SettingsIcon fontSize="small" />
        //       </IconButton>
        //     </Box>
        //   </Stack>
        // }
      >
        <Typography variant="body2" textTransform="none">
          {selectedAccents?.label ?? "Accents"}
          {/* {selectedAccents?.gender?.toLowerCase()} */}
        </Typography>
      </Button>

      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack p={2} spacing={1}>
          <Button variant="text" onClick={() => console.log("Add ID")}>
            Add ID
          </Button>
          <Button variant="text" onClick={() => console.log("Change Value")}>
            Change Value
          </Button>
        </Stack>
      </Popover> */}

      <Dialog
        PaperProps={{
          sx: {
            width: 1300,
            maxWidth: 1300,
          },
        }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ padding: 0, paddingInline: 3, paddingBlock: 2 }}>
          <Typography variant="h6" fontWeight="bold">
            Select Voice
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AssistantChooseAccentDialogUI
            handleChangeAssistantChangedData={handleChangeAssistantChangedData}
            handleSaveChanges={handleSaveChanges}
            selectedAccents={selectedAccents}
            setSelectedAccents={setSelectedAccents}
            setDialogOpen={setDialogOpen}
          /> 
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default AssistantChooseAccents;
