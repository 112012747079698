import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";

import { clearAllDataFromLocalStorage } from "../configs/utils";
import { resetReduxState } from "./authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import authMiddleware from "./authSlice/middleware";

import pageSlice from "./pageSlice";
import authSlice from "./authSlice";
import settingsSlice from "./settingsSlice";
import campaignsSlice from "./campaignsSlice";
import contactListSlice from "./contactListSlice";
import phoneNumbersSlice from "./phoneNumbersSlice";
import voiceAgentSlice from "./voiceAgentSlice";
import assistantsSlice from "./assistantsSlice";
import knowledgeBasesSlice from "./knowledgeBasesSlice";
import callHistorySlice from "./callHistorySlice";
import integrationSlice from "./integrationSlice";
import developersSlice from "./developersSlice";
import filterSlice from "./filterSlice";
import scrapedUrlsSlice from "./scrapedUrlsSlice";
import dynamicVariableSlice from "./dynamicVariableSlice";

const filterPersistConfig = {
  key: "filter",
  storage,
  whitelist: ["filterObject", "filters", "appliedFilters", "filterParams"],
};
const assistantPersistConfig = {
  key: "assistant",
  storage,
  whitelist: ["showAccent","questions"],
};
const scrapedUrlsPersistConfig = {
  key: "scrapedUrls",
  storage,
  whitelist: ["page"],
};
const persistedFilterReducer = persistReducer(filterPersistConfig, filterSlice);
const persistedScarpedUrlsReducer = persistReducer(
  scrapedUrlsPersistConfig,
  scrapedUrlsSlice
);
const persistedAssistantsReducer = persistReducer(
  assistantPersistConfig,
  assistantsSlice
);

const appReducer = combineReducers({
  pageDetails: pageSlice,
  authDetails: authSlice,
  settingsDetails: settingsSlice,
  contactListDetails: contactListSlice,
  integrationDetails: integrationSlice,
  developersDetails: developersSlice,
  phoneNumbersDetails: phoneNumbersSlice,
  campaignsDetails: campaignsSlice,
  voiceAgentDetails: voiceAgentSlice,
  assistantsDetails: persistedAssistantsReducer,
  knowledgeBasesDetails: knowledgeBasesSlice,
  callHistoryDetails: callHistorySlice,
  scrapedUrlsDetails: persistedScarpedUrlsReducer,
  filterDetails: persistedFilterReducer,
  dynamicVariables: dynamicVariableSlice,
});

const rootReducer = (state, action) => {
  if (resetReduxState.match(action)) {
    clearAllDataFromLocalStorage();
    return appReducer(action.payload, action);
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware().concat(authMiddleware);
    if (process.env.NODE_ENV !== "production")
      middleware = middleware.concat(logger);
    return middleware;
  },
  devTools: process.env.NODE_ENV !== "production",
});

const persistor = persistStore(store);

export { store, persistor };
