import {
  Box,
  Grid,
  Typography,
  Slider,
  Switch,
  Radio,
  RadioGroup,
  TextField,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomHintPopover from "../../CustomHintPopover";
import { useSelector } from "react-redux";
import { VOICEMAIL_CONTENT } from "../../../data/Constants/tooltip";
import get from "lodash/get";

const CallSettings = ({ handleChangeAssistantChangedData }) => {
  // const [callSettings, setCallSettings] = useState({
  //   max_duration: assistant?.ssml_data?.max_duration ?? 900,
  //   speech_rate: assistant?.ssml_data?.speech_rate ?? 15,
  // });

  // const handleChangeCallSettings = (name, newValue) => {
  //   setCallSettings((prevSettings) => ({
  //     ...prevSettings,
  //     [name]: newValue,
  //   }));
  // };
  const state=useSelector((state)=>state)
 
   const ssml_data = useSelector(
     (state) => state.assistantsDetails?.assistantInitialInfo?.ssml_data
   );
 console.log({ssml_data})
   const updated_ssml_data = useSelector(
     (state) => state.assistantsDetails?.assistantUpdatedInfo?.ssml_data
   );

   const assistant = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo
  );

  const [ssmlDataExtraSetting, setSsmlDataExtraSetting] = useState({
    label: ssml_data?.label ?? "Aria",
    gender: ssml_data?.gender ?? "female",
    country: ssml_data?.country ?? "US",
    vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
    tts_provider: ssml_data?.tts_provider ?? "azure",
    lang: ssml_data?.lang ?? "en",
    max_duration: ssml_data?.max_duration ?? 780,
    rengage_caller: ssml_data?.rengage_caller ?? true,
    rengage_count: ssml_data?.rengage_count ?? 2,
    no_input: ssml_data?.no_input ?? 22,
    speech_rate: ssml_data?.speech_rate ?? -12,
    rengage_message:
      ssml_data?.rengage_message ??
      "Sorry, I didn't get any response from you, are you still there?",
  });

  const handleChangeSetExtraSettings = (name, newValue) => {
    setSsmlDataExtraSetting((prevSettings) => ({
      ...prevSettings,
      [name]: newValue,
    }));
    handleChangeAssistantChangedData({
      ssml_data: {
        ...ssml_data,
        ...(updated_ssml_data || {}),
        [name]: newValue,
      },
    });
  };



   const assistantMachineDetection = useSelector(
     (state) =>
       state.assistantsDetails?.assistantInitialInfo?.additionalConfig
         ?.machine_detection
   );
 // const assistantMachineDetection=get(state,"assistantDetails.assistantInitialInfo.additionalConfig.machine_detection")
  // const assistantAdditionalConfigPresentInUpdatedData = get(
    // state,
    // "assistantsDetails.assistantUpdatedInfo.additionalConfig",
    // null
  // );
  // 
  // const customMessageConfig = get(
    // state,
    // "assistantsDetails.assistantInitialInfo.additionalConfig.custom_message",
    // null
  // );
  // 
  // const voicemailOptionConfig = get(
    // state,
    // "assistantsDetails.assistantInitialInfo.additionalConfig.voicemail_Option",
    // null
  // );
  // 
  // const additionalConfigObj = get(
    // state,
    // "assistantsDetails.assistantInitialInfo.additionalConfig",
    // {})

  
   const assistantAdditionalConfigPresentInUpdatedData = useSelector(
     (state) => state.assistantsDetails?.assistantUpdatedInfo?.additionalConfig
   );
   const customMessageConfig = useSelector(
     (state) =>
       state.assistantsDetails?.assistantInitialInfo?.additionalConfig
         ?.custom_message
   );
   const voicemailOptionConfig = useSelector(
     (state) =>
       state.assistantsDetails?.assistantInitialInfo?.additionalConfig
         ?.voicemail_Option
   );
   const additionalConfigObj = useSelector(
     (state) => state.assistantsDetails?.assistantInitialInfo?.additionalConfig
   );



  const [customMessage, setCustomMessage] = useState(customMessageConfig ?? "");

  const [machineDetection, setMachineDetection] = useState(
    assistantMachineDetection ?? false
  );
  const [radioOption, setRadioOption] = useState(
    (voicemailOptionConfig ? "leaveMessage" : "hangup") || "hangup"
  );

  const handleChangeInAdditionalConfigData = (value) => {
    handleChangeAssistantChangedData({
      additionalConfig: {
        ...(additionalConfigObj || {}),
        ...(assistantAdditionalConfigPresentInUpdatedData || {}),
        ...value,
      },
    });
  };
  useEffect(() => {
    if (radioOption !== "leaveMessage") {
      handleChangeInAdditionalConfigData({
        custom_message: "",
      });
    }
  }, [radioOption]);
  useEffect(() => {
    setRadioOption(
      (voicemailOptionConfig ? "leaveMessage" : "hangup") || "hangup"
    );
    setCustomMessage(customMessageConfig ?? "");
  }, [voicemailOptionConfig, customMessageConfig]);

  useEffect(() => {
    setMachineDetection(assistantMachineDetection ?? false);
  }, [assistantMachineDetection]);

  useEffect(() => {
    setSsmlDataExtraSetting({
      label: ssml_data?.label ?? "Aria",
      gender: ssml_data?.gender ?? "female",
      country: ssml_data?.country ?? "US",
      vtype: ssml_data?.vtype ?? "en-US-AriaNeural",
      tts_provider: ssml_data?.tts_provider ?? "azure",
      lang: ssml_data?.lang ?? "en",
      max_duration: ssml_data?.max_duration ?? 780,
      rengage_caller: ssml_data?.rengage_caller ?? true,
      rengage_count: ssml_data?.rengage_count ?? 2,
      no_input: ssml_data?.no_input ?? 22,
      speech_rate: ssml_data?.speech_rate ?? -12,
      rengage_message:
        ssml_data?.rengage_message ??
        "Sorry, I didn't get any response from you, are you still there?",
    });
  }, [ssml_data]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Voicemail
              <Box mb={0.3}>
                <CustomHintPopover
                  size="small"
                  maxWidth={400}
                  transformHorizontalPosition={"left"}
                  transformVerticalPosition={"bottom"}
                  anchorHorizontalPosition={"left"}
                  anchorVerticalPosition={"top"}
                  hintContent={
                  VOICEMAIL_CONTENT
                  }
                />
              </Box>
            </Typography>
            <Typography variant="caption">
              Detects answering machines or voicemails and disconnects the call
              without deducting minutes from your quota.
            </Typography>
          </Grid>
          <Grid item>
            {/*<Switch*/}
            {/*  size="medium"*/}
            {/*  checked={machineDetection ?? false}*/}
            {/*  onChange={(event) => {*/}
            {/*    handleChangeInAdditionalConfigData({*/}
            {/*      machine_detection: event.target.checked,*/}
            {/*    });*/}
            {/*    setMachineDetection(event.target.checked);*/}
            {/*  }}*/}
            {/*/>*/}
            {machineDetection ? (
              <Grid container direction="column" mt={2}>
                <RadioGroup
                  value={radioOption}
                  onChange={(event) => {
                    setRadioOption(event.target.value);
                    handleChangeInAdditionalConfigData({
                      voicemail_Option: event.target.value === "leaveMessage",
                    });
                  }}
                >
                  <FormControlLabel
                  disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                    value="hangup"
                    control={<Radio />}
                    label="Hang up if reaching voicemail"
                  />
                  <FormControlLabel
                  disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                    value="leaveMessage"
                    control={<Radio />}
                    label="Leave a message if reaching voicemail"
                  />
                </RadioGroup>
                {radioOption === "leaveMessage" && (
                  <TextField
                  disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                    size="small"
                    label="Message content"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={customMessage}
                    onChange={(e) => {
                      setCustomMessage(e.target.value);
                      handleChangeInAdditionalConfigData({
                        custom_message: e.target.value,
                      });
                    }}
                    sx={{
                      mt: 2,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "6px",
                      },
                      "& .MuiInputBase-input": {
                        padding: 0,
                      },
                    }}
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Grid item xs={12}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              fontWeight="bold"
            >
              Max Call Duration:
              <CustomHintPopover
                size="small"
                maxWidth={400}
                transformHorizontalPosition={"left"}
                transformVerticalPosition={"bottom"}
                anchorHorizontalPosition={"left"}
                anchorVerticalPosition={"top"}
                hintContent="Set a time limit for your calls. The voice assistant will end the call once this duration is reached."
              />
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

            {console.log(ssmlDataExtraSetting.max_duration)}
            <Grid item xs={8} display="flex" alignItems="center">
              <Slider
              disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
                size="small"
                valueLabelDisplay="auto"
                value={ssmlDataExtraSetting?.max_duration / 60}
                step={1}
                onChange={(e, newValue) =>
                  handleChangeSetExtraSettings("max_duration", newValue * 60)
                }
                aria-labelledby="max-duration-slider"
                min={5}
                max={20}
              />
            </Grid>
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2" fontWeight="bold">
                ({ssmlDataExtraSetting?.max_duration / 60} minutes)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CallSettings;
