const initialState = {
  loading: false,
  loadError: false,
  assistants: [],
  workspaceContactListCol: [],
  assistantUpdatedInfo: {},
  assistantInitialInfo: {},
  showAccent : false,
  questions : [],
};

export default initialState;
