import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { lighten, darken } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import ToggleButton from "@mui/material/ToggleButton";
import CustomToggleGroup from "../../components/CustomToggleGroup";
import { LOCAL_STORAGE,GTAGS } from "../../data/Constants/hardData";
import { ROUTE_PATH } from "../../data/Constants/url";
import { THEME_MODE } from "../../data/Constants/theme";
import { ONBOARDING_STATE } from "../../data/Constants/hardData";
import {
  submitUserOnBoardingSurvey,
  updateAuthState,
} from "../../data/store/authSlice";
import { log, setDataInLocalStorage } from "../../data/configs/utils";
import IMG_LOGO_FULL_DARK from "../../data/assets/img/logoFullDark.png";
import IMG_LOGO_FULL_LIGHT from "../../data/assets/img/logoFullLight.png";
import { Grid } from "@mui/material";
import { updatePageState } from "../../data/store/pageSlice";
import { handleCleverTapBtnClick } from "../../data/configs/utils";
export const LANG_COUNTRY = {
    en: "US",
    es: "ES",
    fr: "FR",
    de: "DE",
    it: "IT",
    pt: "BR",
    zh: "CN",
    ja: "JP",
    ko: "KR",
    ar: "SA",
    hi: "IN",
    nl: "NL",
    tr: "TR",
    tl: "PH",
    pl: "PL",
    sv: "SE",
    bg: "BG",
    ro: "RO",
    cs: "CZ",
    el: "GR",
    fi: "FI",
    hr: "HR",
    ms: "MY",
    sk: "SK",
    da: "DK",
    ta: "IN",
    uk: "UA",
    // ru: "RU",
    vi: "VN",
    no: "NO",
    hu: "HU",
  };
  export const COUNTRY_NAME_BASED_ON_CODE = {
    US: "American",
    AU: "Australian",
    PT: "Portuguese",
    BR: "Brazilian",
    IN: "Indian",
    ES: "Spanish",
    AR: "Argentinian",
    CO: "Colombian",
    JP: "Japanese",
    AT: "Austrian",
    CH: "Swiss",
    DE: "German",
    FR: "French",
    GB: "British",
    IT: "Italian",
    CN: "Chinese",
    KR: "Korean",
    SA: "Saudi Arabian",
    NL: "Dutch",
    TR: "Turkish",
    PH: "Filipino",
    PL: "Polish",
    SE: "Swedish",
    BG: "Bulgarian",
    RO: "Romanian",
    CZ: "Czech",
    GR: "Greek",
    FI: "Finnish",
    HR: "Croatian",
    MY: "Malaysian",
    SK: "Slovak",
    DK: "Danish",
    UA: "Ukrainian",
    // RU: "Russian",
    VN: "Vietnamese",
    NO: "Norwegian",
    HU: "Hungarian",
  };
  export const LANGUAGES_LIST = [
    {
      label: "English",
      shorthand: "en",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Spanish",
      shorthand: "sp",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "French",
      shorthand: "fr",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "German",
      shorthand: "de",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Italian",
      shorthand: "it",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Portuguese",
      shorthand: "pt",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Chinese",
      shorthand: "zh",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Japanese",
      shorthand: "ja",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Korean",
      shorthand: "ko",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Arabic",
      shorthand: "ar",
      allowedGenders: [{ label: "Male", value: "male" }],
    },
    {
      label: "Hindi",
      shorthand: "hn",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Dutch",
      shorthand: "nl",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Turkish",
      shorthand: "tr",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Filipino",
      shorthand: "tl",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Polish",
      shorthand: "pl",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Swedish",
      shorthand: "sv",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Bulgarian",
      shorthand: "bg",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Romanian",
      shorthand: "ro",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Czech",
      shorthand: "cs",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Greek",
      shorthand: "el",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Finnish",
      shorthand: "fi",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Croatian",
      shorthand: "hr",
      allowedGenders: [{ label: "Male", value: "male" }],
    },
    {
      label: "Malay",
      shorthand: "ms",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Slovak",
      shorthand: "sk",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Danish",
      shorthand: "da",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Tamil",
      shorthand: "ta",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Ukrainian",
      shorthand: "uk",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Vietnamese",
      shorthand: "vi",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
    {
      label: "Norwegian",
      shorthand: "no",
      allowedGenders: [
        { label: "Female", value: "female" },
        { label: "Male", value: "male" },
      ],
    },
    {
      label: "Hungarian",
      shorthand: "hu",
      allowedGenders: [{ label: "Female", value: "female" }],
    },
  ];
  export const MULTI_LANG_CODES = {
    male: [
      {
        label: "Madhur",
        language: ["hi"],
        gender: "male",
        country: "IN",
        vtype: "hi-IN-MadhurNeural",
        tts_provider: "azure",
      },
      {
        label: "Aarav",
        language: ["hi"],
        gender: "male",
        country: "IN",
        vtype: "hi-IN-AaravNeural",
        tts_provider: "azure",
      },
      {
        label: "Kunal",
        language: ["hi"],
        gender: "male",
        country: "IN",
        vtype: "hi-IN-KunalNeural",
        tts_provider: "azure",
      },
      {
        label: "Rehaan",
        language: ["hi"],
        gender: "male",
        country: "IN",
        vtype: "hi-IN-RehaanNeural",
        tts_provider: "azure",
      },
      {
        label: "Steffan",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "en-US-SteffanNeural",
        tts_provider: "azure",
      },
      {
        label: "Jason",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "en-US-JasonNeural",
        tts_provider: "azure",
      },
      {
        label: "Tony",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "en-US-TonyNeural",
        tts_provider: "azure",
      },
      {
        label: "Davis",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "en-US-DavisNeural",
        tts_provider: "azure",
      },
      {
        label: "John",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "John",
        tts_provider: "azure",
      },
      {
        label: "Donald",
        language: ["en"],
        gender: "male",
        country: "US",
        vtype: "Donald",
        tts_provider: "azure",
      },
      {
        label: "William",
        language: ["en"],
        gender: "male",
        country: "AU",
        vtype: "en-AU-WilliamNeural",
        tts_provider: "azure",
      },
      {
        label: "Darren",
        language: ["en"],
        gender: "male",
        country: "AU",
        vtype: "en-AU-DarrenNeural",
        tts_provider: "azure",
      },
      {
        label: "Ryan",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-RyanNeural",
        tts_provider: "azure",
      },
      {
        label: "Alfie",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-AlfieNeural",
        tts_provider: "azure",
      },
      //rejected due to robotic presence
      // {
      //   "label": "Elliot",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "GB",
      //   "vtype": "en-GB-ElliotNeural",
      //   "tts_provider": "azure"
      // },
      {
        label: "Ethan",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-EthanNeural",
        tts_provider: "azure",
      },
      {
        label: "Noah",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-NoahNeural",
        tts_provider: "azure",
      },
      {
        label: "Oliver",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-OliverNeural",
        tts_provider: "azure",
      },
      {
        label: "Thomas",
        language: ["en"],
        gender: "male",
        country: "GB",
        vtype: "en-GB-ThomasNeural",
        tts_provider: "azure",
      },
      //rejected due to robotic presence
      // {
      //   "label": "Ollie",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "GB",
      //   "vtype": "en-GB-OllieMultilingualNeural",
      //   "tts_provider": "azure"
      // },
      // {
      //   "label": "Duncan",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "AU",
      //   "speech_rate": 15,
      //   "loudness": 0,
      //   "vtype": "en-AU-DuncanNeural",
      //   "tts_provider": "azure"
      // },
      // {
      //   "label": "Ken",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "AU",
      //   "speech_rate": 15,
      //   "loudness": 0,
      //   "vtype": "en-AU-KenNeural",
      //   "tts_provider": "azure"
      // },
      // {
      //   "label": "Neil",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "AU",
      //   "speech_rate": 15,
      //   "loudness": 0,
      //   "vtype": "en-AU-NeilNeural",
      //   "tts_provider": "azure"
      // },
      // {
      //   "label": "Tim",
      //   "lang": "en",
      //   "gender": "male",
      //   "country": "AU",
      //   "speech_rate": 15,
      //   "loudness": 0,
      //   "vtype": "en-AU-TimNeural",
      //   "tts_provider": "azure"
      // },
      {
        label: "Henri",
        language: ["fr"],
        gender: "male",
        country: "FR",
        vtype: "fr-FR-HenriNeural",
        tts_provider: "azure",
      },
      // {
      //   "label": "Alain",
      //   "lang": "fr",
      //   "gender": "male",
      //   "country": "FR",
      //   "vtype": "fr-FR-AlainNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Claude",
      //   "lang": "fr",
      //   "gender": "male",
      //   "country": "FR",
      //   "vtype": "fr-FR-ClaudeNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
  
      // {
      //   "label": "Conrad",
      //   "lang": "de",
      //   "gender": "male",
      //   "country": "DE",
      //   "vtype": "de-DE-ConradNeural1",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      {
        label: "Bernd",
        language: ["de"],
        gender: "male",
        country: "DE",
        vtype: "de-DE-BerndNeural",
        tts_provider: "azure",
      },
      // {
      //   "label": "Jonas",
      //   "lang": "de",
      //   "gender": "male",
      //   "country": "AT",
      //   "vtype": "de-AT-JonasNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Jan",
      //   "lang": "de",
      //   "gender": "male",
      //   "country": "CH",
      //   "vtype": "de-CH-JanNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
  
      {
        label: "Alvaro",
        language: ["es"],
        gender: "male",
        country: "ES",
        vtype: "es-ES-AlvaroNeural",
        tts_provider: "azure",
      },
      {
        label: "Arnau",
        language: ["es"],
        gender: "male",
        country: "ES",
        vtype: "es-ES-ArnauNeural",
        tts_provider: "azure",
      },
      // {
      //   "label": "Dario",
      //   "lang": "es",
      //   "gender": "male",
      //   "country": "ES",
      //   "vtype": "es-ES-DarioNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Elisa",
      //   "lang": "es",
      //   "gender": "male",
      //   "country": "ES",
      //   "vtype": "es-ES-EliasNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Tomas",
      //   "lang": "es",
      //   "gender": "male",
      //   "country": "AR",
      //   "vtype": "es-AR-TomasNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Gonzalo",
      //   "lang": "es",
      //   "gender": "male",
      //   "country": "CO",
      //   "vtype": "es-CO-GonzaloNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      {
        label: "Duarte",
        language: ["pt"],
        gender: "male",
        country: "PT",
        vtype: "pt-PT-DuarteNeural",
        tts_provider: "azure",
      },
      {
        label: "Antonio",
        language: ["pt"],
        gender: "male",
        country: "BR",
        vtype: "pt-BR-AntonioNeural",
        tts_provider: "azure",
      },
      // {
      //   "label": "Donato",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-DonatoNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Fabio",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-FabioNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Humberto",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-HumbertoNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Julio",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-JulioNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Nicolau",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-NicolauNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Valerio",
      //   "lang": "pt",
      //   "gender": "male",
      //   "country": "BR",
      //   "vtype": "pt-BR-ValerioNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      // {
      //   "label": "Arnaud",
      //   "lang": "nl",
      //   "gender": "male",
      //   "country": "BE",
      //   "vtype": "nl-BE-ArnaudNeural",
      //   "tts_provider": "azure",
      //   "speech_rate": 15,
      //   "loudness": 0
      // },
      {
        label: "Maarten",
        language: ["nl"],
        gender: "male",
        country: "NL",
        vtype: "nl-NL-MaartenNeural",
        tts_provider: "azure",
      },
      {
        label: "Tamas",
        language: ["hu"],
        gender: "male",
        country: "HU",
        vtype: "hu-HU-TamasNeural",
        tts_provider: "azure",
      },
      {
        label: "Andrew",
        gender: "male",
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        vtype: "en-US-AndrewMultilingualNeural",
        tts_provider: "azure",
      },
      {
        label: "Brian",
        gender: "male",
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        vtype: "en-US-BrianMultilingualNeural",
        tts_provider: "azure",
      },
      {
        vtype: "IKne3meq5aSn9XLyUdCD",
        label: "Charlie",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Charlie",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3",
      },
      {
        vtype: "JBFqnCBsd6RMkjVDRZzb",
        label: "George",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "george",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/e6206d1a-0721-4787-aafb-06a6e705cac5.mp3",
      },
      {
        vtype: "N2lVS1w4EtoT3dr4eOWO",
        label: "Callum",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Callum",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3",
      },
      {
        vtype: "TX3LPaxmHKxFdv7VOQHJ",
        label: "Liam",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Liam",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3",
      },
      {
        vtype: "bIHbv24MWmeRgasZH58o",
        label: "Will",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Will",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/bIHbv24MWmeRgasZH58o/8caf8f3d-ad29-4980-af41-53f20c72d7a4.mp3",
      },
      {
        vtype: "cjVigY5qzO86Huf0OWal",
        label: "Eric",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Eric",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/cjVigY5qzO86Huf0OWal/d098fda0-6456-4030-b3d8-63aa048c9070.mp3",
      },
      {
        vtype: "iP95p4xoKVk53GoZ742B",
        label: "Chris",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Chris",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/3f4bde72-cc48-40dd-829f-57fbf906f4d7.mp3",
      },
      {
        vtype: "nPczCjzI2devNBz1zQrb",
        label: "Brian",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Brian",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/2dd3e72c-4fd3-42f1-93ea-abc5d4e5aa1d.mp3",
      },
      {
        vtype: "onwK4e9ZLuTAKqWW03F9",
        label: "Daniel",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Daniel",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3",
      },
      {
        vtype: "pqHfZKP75CvOlQylNhV4",
        label: "Bill",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "male",
        tts_provider: "elevenlabs",
        voice_recording_id: "Bill",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/d782b3ff-84ba-4029-848c-acf01285524d.mp3",
      },
    ],
    female: [
      {
        label: "Fenna",
        language: ["nl"],
        gender: "female",
        country: "NL",
        vtype: "nl-NL-FennaNeural",
        tts_provider: "azure",
      },
  
      {
        label: "Raquel",
        language: ["pt"],
        gender: "female",
        country: "PT",
        vtype: "pt-PT-RaquelNeural",
        tts_provider: "azure",
      },
      {
        label: "Francisca",
        language: ["pt"],
        gender: "female",
        country: "BR",
        vtype: "pt-BR-FranciscaNeural",
        tts_provider: "azure",
      },
      {
        label: "Elvira",
        language: ["es"],
        gender: "female",
        country: "ES",
        vtype: "es-ES-ElviraNeural",
        tts_provider: "azure",
      },
      {
        label: "Abril",
        language: ["es"],
        gender: "female",
        country: "ES",
        vtype: "es-ES-AbrilNeural",
        tts_provider: "azure",
      },
      {
        label: "Katja",
        language: ["de"],
        gender: "female",
        country: "DE",
        vtype: "de-DE-KatjaNeural",
        tts_provider: "azure",
      },
      {
        label: "Amala",
        language: ["de"],
        gender: "female",
        country: "DE",
        vtype: "de-DE-AmalaNeural",
        tts_provider: "azure",
      },
      {
        label: "Denise",
        language: ["fr"],
        gender: "female",
        country: "FR",
        vtype: "fr-FR-DeniseNeural",
        tts_provider: "azure",
      },
      {
        label: "Sara",
        language: ["en"],
        gender: "female",
        country: "US",
        vtype: "en-US-SaraNeural",
        tts_provider: "azure",
      },
      {
        label: "Nancy",
        language: ["en"],
        gender: "female",
        country: "US",
        vtype: "en-US-NancyNeural",
        tts_provider: "azure",
      },
      {
        label: "Aria",
        language: ["en"],
        gender: "female",
        country: "US",
        vtype: "en-US-AriaNeural",
        tts_provider: "azure",
      },
      {
        label: "Karen",
        language: ["en"],
        gender: "female",
        country: "US",
        vtype: "Karen",
        tts_provider: "azure",
      },
      {
        label: "Natasha",
        language: ["en"],
        gender: "female",
        country: "AU",
        vtype: "en-AU-NatashaNeural",
        tts_provider: "azure",
      },
      {
        label: "Annette",
        language: ["en"],
        gender: "female",
        country: "AU",
        vtype: "en-AU-AnnetteNeural",
        tts_provider: "azure",
      },
      {
        label: "Sonia",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-SoniaNeural",
        tts_provider: "azure",
      },
      {
        label: "Libby",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-LibbyNeural",
        tts_provider: "azure",
      },
      {
        label: "Abbi",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-AbbiNeural",
        tts_provider: "azure",
      },
      {
        label: "Ada",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-AdaMultilingualNeural",
        tts_provider: "azure",
      },
      {
        label: "Hollie",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-HollieNeural",
        tts_provider: "azure",
      },
      {
        label: "Olivia",
        language: ["en"],
        gender: "female",
        country: "GB",
        vtype: "en-GB-OliviaNeural",
        tts_provider: "azure",
      },
      {
        label: "Swara",
        language: ["hi"],
        gender: "female",
        country: "IN",
        vtype: "hi-IN-SwaraNeural",
        tts_provider: "azure",
      },
      {
        label: "Ananya",
        language: ["hi"],
        gender: "female",
        country: "IN",
        vtype: "hi-IN-AnanyaNeural",
        tts_provider: "azure",
      },
      {
        label: "Kavya",
        language: ["hi"],
        gender: "female",
        country: "IN",
        vtype: "hi-IN-KavyaNeural",
        tts_provider: "azure",
      },
      {
        label: "Ava",
        gender: "female",
        vtype: "en-US-AvaMultilingualNeural",
        tts_provider: "azure",
      },
      {
        label: "Emma",
        gender: "female",
        vtype: "en-US-EmmaMultilingualNeural",
        tts_provider: "azure",
      },
      {
        label: "Noemi",
        language: ["hu"],
        gender: "female",
        country: "HU",
        vtype: "hu-HU-NoemiNeural",
        tts_provider: "azure",
      },
      {
        vtype: "EXAVITQu4vr4xnSDxMaL",
        label: "Sarah",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "Sarah",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/01a3e33c-6e99-4ee7-8543-ff2216a32186.mp3",
      },
      {
        vtype: "FGY2WhTYpPnrIDTdsKH5",
        label: "Laura",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "Laura",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/FGY2WhTYpPnrIDTdsKH5/67341759-ad08-41a5-be6e-de12fe448618.mp3",
      },
      {
        vtype: "XB0fDUnXU5powFXDhCwa",
        label: "Charlotte",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "Charlotte",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3",
      },
      {
        vtype: "Xb7hH8MSUJpSbSDYk0k2",
        label: "Alice",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "Alice",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/d10f7534-11f6-41fe-a012-2de1e482d336.mp3",
      },
      {
        vtype: "XrExE9yKIg1WjnnlVkGX",
        label: "Matilda",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "matilda",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3",
      },
      {
        vtype: "cgSgspJ2msm6clMCkdW9",
        label: "Jessica",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "jessica",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/cgSgspJ2msm6clMCkdW9/56a97bf8-b69b-448f-846c-c3a11683d45a.mp3",
      },
      {
        vtype: "pFZP5JQG7iQjIQuC4Bku",
        label: "Lily",
        high_quality_base_model_ids: [
          "eleven_turbo_v2",
          "eleven_multilingual_v2",
          "eleven_turbo_v2_5",
        ],
        language: [
          "en", // English
          "es", // Spanish
          "fr", // French
          "de", // German
          "it", // Italian
          "pt", // Portuguese
          "zh", // Chinese
          "ja", // Japanese
          "ko", // Korean
          "ar", // Arabic
          "hi", // Hindi
          "nl", // Dutch
          "tr", // Turkish
          "tl", // Filipino
          "pl", // Polish
          "sv", // Swedish
          "bg", // Bulgarian
          "ro", // Romanian
          "cs", // Czech
          "el", // Greek
          "fi", // Finnish
          "hr", // Croatian
          "ms", // Malay
          "sk", // Slovak
          "da", // Danish
          "ta", // Tamil
          "uk", // Ukrainian
          "ru", // Russian
          "vi", // Vietnamese
          "no", // Norwegian
          "hu", // Hungarian
        ],
        gender: "female",
        tts_provider: "elevenlabs",
        voice_recording_id: "Lily",
        preview_url:
          "https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/89b68b35-b3dd-4348-a84a-a3c13a3c2b30.mp3",
      },
    ],
  };
  
  export const languageModelMap = {
    // eleven_multilingual_v2: [
    //   "en", // English
    //   "es", // Spanish
    //   "fr", // French
    //   "de", // German
    //   "it", // Italian
    //   "pt", // Portuguese
    //   "zh", // Chinese
    //   "ja", // Japanese
    //   "ko", // Korean
    //   "ar", // Arabic
    //   "hi", // Hindi
    //   "nl", // Dutch
    //   "tr", // Turkish
    //   "tl", // Filipino
    //   "pl", // Polish
    //   "sv", // Swedish
    //   "bg", // Bulgarian
    //   "ro", // Romanian
    //   "cs", // Czech
    //   "el", // Greek
    //   "fi", // Finnish
    //   "hr", // Croatian
    //   "ms", // Malay
    //   "sk", // Slovak
    //   "da", // Danish
    //   "ta", // Tamil
    //   "uk", // Ukrainian
    //   "ru", // Russian
    // ],
    eleven_turbo_v2_5: [
      "en", // English
      "es", // Spanish
      "fr", // French
      "de", // German
      "it", // Italian
      "pt", // Portuguese
      "zh", // Chinese
      "ja", // Japanese
      "ko", // Korean
      "ar", // Arabic
      "hi", // Hindi
      "nl", // Dutch
      "tr", // Turkish
      "tl", // Filipino
      "pl", // Polish
      "sv", // Swedish
      "bg", // Bulgarian
      "ro", // Romanian
      "cs", // Czech
      "el", // Greek
      "fi", // Finnish
      "hr", // Croatian
      "ms", // Malay
      "sk", // Slovak
      "da", // Danish
      "ta", // Tamil
      "uk", // Ukrainian
      "vi", // Vietnamese
      "no", // Norwegian
      "hu", // Hungarian
    ],
    // eleven_turbo_v2: [
    //   "en", // English
    // ],
  };