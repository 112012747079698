import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import CustomHintPopover from "../../CustomHintPopover";
import * as _ from "lodash";

const ExtraCallDetails = ({
  averageResponseTime,
  isUserInterested,
  chatLanguage,
  chatSummary,
}) => {
  return (
    <Grid
      item
      xs={12}
      mb={2}
      display="flex"
      alignItems="center"
      rowSpacing={4}
      paddingBottom={4}
      
    
    >
      <Card
        sx={{
          padding: 2,
          width: "100%",
          display: "block",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 1px 0px",
          height:"100%"
          
        }}
      >
        <Grid xs={12} mb={1}>
          <Typography
            display="flex"
            alignItems="center"
            variant="h6"
            marginLeft={2}
            sx={{ fontWeight: "bold", }}
          >
            Call Analytics{" "}
            <CustomHintPopover
              size="small"
              maxWidth={400}
              // hintTitle={'Bot Temperature Setting'}
              transformHorizontalPosition={"left"}
              transformVerticalPosition={"center"}
              anchorHorizontalPosition={"right"}
              anchorVerticalPosition={"top"}
              hintContent={"Extra Details about the call."}
            />
          </Typography>
        </Grid>
        {averageResponseTime !== "N/A" ? (
          <Grid
            container
            item
            xs={12}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <strong>Assistant's Average Response Time : </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <p style={{ paddingInline: 4 }}>
                  {_.round(averageResponseTime, 2)} seconds
                </p>
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {isUserInterested !== "N/A" ? (
          <Grid
            container
            item
            xs={12}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <strong>Is Callee Interested : </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <p style={{ paddingInline: 4 }}>
                  {isUserInterested ? "Yes" : "No"}{" "}
                </p>
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        {!_.isEmpty(chatLanguage) &&
        chatLanguage !== "N/A" &&
        chatLanguage?.trim()?.length > 0 ? (
          <Grid
            container
            item
            xs={12}
            mb={1}
            display="flex"
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <strong>Chat Language : </strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
                xs={6}
              >
                <p style={{ paddingInline: 4 }}>{chatLanguage} </p>
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {!_.isEmpty(chatSummary) &&
        chatSummary !== "N/A" &&
        chatSummary?.trim()?.length > 0 ? (
          <Box>
            <Grid item xs={12} mb={1}>
              <Typography
                display="flex"
                alignItems="center"
                variant="subtitle1"
                marginLeft={2}
              >
                <strong>Chat Summary :</strong>
              </Typography>
              <Grid item xs={12} mb={1}>
                <Typography
                  display="flex"
                  alignItems="center"
                  variant="subtitle1"
                  marginLeft={2}
                  paddingTop={2}
                  paddingLeft={1}
                >
                  {chatSummary}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Card>
    </Grid>
  );
};

export default ExtraCallDetails;
