import React, { useEffect, useState } from "react";
import { Grid, Typography, FormControl, Select, MenuItem } from "@mui/material";
import CustomHintPopover from "../../../CustomHintPopover";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import get from "lodash/get";
const AssistantWelcomeMsg = ({ handleChangeAssistantChangedData }) => {
  const assistantDelayInCallOpeningMessage = useSelector(
    (state) =>
      state.assistantsDetails?.assistantInitialInfo?.additionalConfig
        ?.delay_callOpeningMessage
  );
  const additionalConfigObj = useSelector(
    (state) => state.assistantsDetails?.assistantInitialInfo?.additionalConfig
  );
  const assistantDelayInCallOpeningMessagePresentInUpdatedData = useSelector(
    (state) => state.assistantsDetails?.assistantUpdatedInfo?.additionalConfig
  );
const state=useSelector((state)=>state);
  const [delay_callOpeningMessage, setDelay_callOpeningMessage] = useState(
    assistantDelayInCallOpeningMessage ?? false
  );

  const handleChangeInAdditionalConfigData = (value) => {
    handleChangeAssistantChangedData({
      additionalConfig: {
        ...(additionalConfigObj || {}),
        ...(assistantDelayInCallOpeningMessagePresentInUpdatedData || {}),
        delay_callOpeningMessage: value,
      },
    });
  };

  useEffect(() => {
    setDelay_callOpeningMessage(assistantDelayInCallOpeningMessage ?? false);
  }, [assistantDelayInCallOpeningMessage]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography
          display="flex"
          alignItems="center"
          variant="subtitle2"
          color="text.secondary"
        >
          Who Speaks First
          <CustomHintPopover
            size="small"
            maxWidth={400}
            transformHorizontalPosition={"left"}
            transformVerticalPosition={"center"}
            anchorHorizontalPosition={"right"}
            anchorVerticalPosition={"top"}
            hintContent={
              "Select the option that best suits your preferences and communication requirements."
            }
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          size="small"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              padding: 0,
              margin: 0,
              paddingBlock: 0.65,
              paddingInline: 1,
            },
            "& .MuiInputBase-input": {
              fontSize: "0.9rem",
              padding: 0,
              paddingInline: 1,
            },
          }}
        >
          <Select
          disabled={get(state,"assistantsDetails.assistantInitialInfo.defaultAssistant",false)}
            labelId="dialer-type-select-label"
            id="dialer-type-select"
            value={delay_callOpeningMessage}
            onChange={(e) => {
              setDelay_callOpeningMessage(e.target.value);
              handleChangeInAdditionalConfigData(e.target.value);
            }}
            displayEmpty
            sx={{
              fontSize: "0.9rem",
              padding: 0,
              paddingBlock: 0.65,
              paddingInline: 2,
              "& .MuiPaper-root": {
                borderRadius: 0,
              },
            }}
          >
            <MenuItem
              value={true}
              sx={{
                fontSize: "0.9rem",
                padding: 0,
                paddingBlock: 0.5,
                paddingInline: 2,
              }}
            >
              User Initiate : Assistant remains silent until user speaks first.
            </MenuItem>
            <MenuItem
              value={false}
              sx={{
                fontSize: "0.9rem",
                padding: 0,
                paddingBlock: 0.5,
                paddingInline: 2,
              }}
            >
              Assistant Initiate : Assistant begins with your Call Opening
              Message.
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AssistantWelcomeMsg;
