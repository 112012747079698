import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { TaskAlt } from "@mui/icons-material";

import IMG_OFFER from "../../data/assets/img/offer.png";
import IMG_BACKGROUND from "../../data/assets/img/background.png";

import { log } from "../../data/configs/utils";
import { THEME_MODE } from "../../data/Constants/theme";

import { updateUserPlan } from "../../data/store/authSlice";

const BlackFridayContent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authDetails.user?.userId);

  const handleBuyNow = () => {
    if (userId)
      dispatch(updateUserPlan({ isSuperSaver: true }))
        .unwrap()
        .then((res) => {
          if (res?.data?.session?.url) {
            window.location.href = res.data.session.url;
          }
        })
        .catch((err) => {
          log("ERR-handleSubscription: ", err);
        });
  };

  return (
    <Box
      pt={8}
      pb={4}
      px={2}
      pr={{ xs: 2, md: 24 }}
      color={
        theme.palette.mode === THEME_MODE.LIGHT
          ? "common.white"
          : "common.black"
      }
      sx={{
        bgcolor: "primary.main",
        position: "relative",
        "&::before": {
          md: {
            background: `url(${IMG_OFFER})`,
            backgroundSize: "auto 100%",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            display: "block",
            content: '""',
            position: "absolute",
            opacity: 1,
            height: "100%",
            width: "100%",
            left: 0,
            top: 0,
          },
        },
        "&::after": {
          background: `url(${IMG_BACKGROUND})`,
          backgroundSize: "auto 100%",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          display: "block",
          content: '""',
          position: "absolute",
          opacity: 0.1,
          height: "100%",
          width: "100%",
          left: 0,
          top: 0,
        },
      }}
    >
      <Box
        bgcolor="common.black"
        color="common.white"
        display="inline-block"
        py={2}
        px={8}
        ml={-4}
        sx={{ borderTopRightRadius: 32, borderBottomRightRadius: 32 }}
      >
        <Typography variant="h6">Black Friday Super Saver Deal!</Typography>
      </Box>
      <Box pl={4}>
        <Typography
          fontSize="h6.fontSize"
          textTransform="capitalize"
          mt={2}
          mb={1}
        >
          Introducing an exceptional, once-in-a-lifetime offer
        </Typography>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          textTransform="capitalize"
          lineHeight={0.9}
        >
          Grab our New Annual Plan at a Flat
          <Typography
            component="span"
            variant="h3"
            fontWeight="fontWeightBold"
            color="secondary"
            lineHeight={0.9}
          >
            60%
          </Typography>
          Discount!
        </Typography>
        <Typography
          variant="h4"
          fontWeight="fontWeightBold"
          color="secondary"
          my={1}
        >
          <Typography
            display="inline-block"
            variant="h5"
            color={
              theme.palette.mode === THEME_MODE.LIGHT
                ? "common.white"
                : "common.black"
            }
            fontWeight="fontWeightBold"
            sx={{ textDecoration: "line-through" }}
            mr={2}
          >
            $240
          </Typography>
          $99
        </Typography>
        <Typography variant="h4" color="secondary" fontWeight="fontWeightBold">
          & Avail:
        </Typography>
        <Typography variant="subtitle1">
          <TaskAlt color="secondary" sx={{ verticalAlign: "middle", mr: 1 }} />
          1200 minutes of calling/year*
        </Typography>
        <Typography variant="subtitle1">
          <TaskAlt color="secondary" sx={{ verticalAlign: "middle", mr: 1 }} />
          12000+ contacts outreach
        </Typography>
        <Typography variant="subtitle1">
          <TaskAlt color="secondary" sx={{ verticalAlign: "middle", mr: 1 }} />
          Unlimited campaigns
        </Typography>
        <Typography variant="subtitle1">
          <TaskAlt color="secondary" sx={{ verticalAlign: "middle", mr: 1 }} />1
          Live agent connection
        </Typography>
        <Typography variant="subtitle1">
          <TaskAlt color="secondary" sx={{ verticalAlign: "middle", mr: 1 }} />
          24*7 Technical support included
        </Typography>

        <Typography mt={2} mb={0.5} fontWeight="fontWeightBold">
          Your last chance to unlock these exclusive benefits!
        </Typography>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleBuyNow}
          sx={{ zIndex: 2 }}
        >
          Buy Now
        </Button>
        <Typography component="p" variant="caption" mt={1}>
          *The calling limit will refresh on a month-to-month basis.
        </Typography>
      </Box>
    </Box>
  );
};

export default BlackFridayContent;
