import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDataAndProceedWithToolkit } from "../helpers";
import {
  DELETE_ELEVENLABS_INTEGRATE_DETAILS,
  FETCH_ALL_CAL_EVENTS_DETAILS,
  GENERATE_ELEVENLAB_VOICES,
  GET__INTEGRATE_ELEVENLABS_DETAILS,
  GET_ELEVENLAB_VOICES,
  GET_PUT_UPDATE_INTEGRATE_CAL_DETAILS,
  INSTALL_HUBSPOT,
  INTEGRATE_CAL,
  INTEGRATE_ELEVENLABS_ADD_EDIT,
  SYNC_CONTACTLIST_FROM_HUBSPOT,
  VERIFY_HUBSPOT,
} from "./api";
import { METHOD_TYPES } from "../../Constants/hardData";
import {
  INSTALL_GOHIGHLEVEL,
  SYNC_CONTACTLIST_FROM_GOHIGHLEVEL,
  VERIFY_GOHIGHLEVEL,
} from "./api";
import initialState from "./initialState";

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    updateIntegrationState(state, action) {
      return { ...state, ...action.payload };
    },
  },
});

export const verifyHubspot = createAsyncThunk(
  "integration/verifyHubspot",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VERIFY_HUBSPOT,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const syncContactList = createAsyncThunk(
  "integration/syncContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SYNC_CONTACTLIST_FROM_HUBSPOT,
        method: METHOD_TYPES.POST,
        data,
        loaderText:
          "Import in progress, please wait. If this takes longer than expected, try refreshing the page.",
        loader: !data?.showLoader,
      },
      helpers
    )
);

export const installHubSpot = createAsyncThunk(
  "integration/installHubSpot",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: INSTALL_HUBSPOT,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
);
export const integrateCal = createAsyncThunk(
  "/integration/meetSchedulers/cal/integrate",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: INTEGRATE_CAL,
        method: METHOD_TYPES.POST,
        data,
      },
      helpers
    )
);
export const integrateElevenLabsAddEdit = createAsyncThunk(
  "/integration/elevenlabs",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: INTEGRATE_ELEVENLABS_ADD_EDIT,
        method: METHOD_TYPES.POST,
        data,
        loaderText: "Integrating...",
        loader: !data?.showLoader,
      },
      helpers
    )
);

export const getElevenLabVoices = createAsyncThunk(
  "/integration/getElevenLabVoices",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_ELEVENLAB_VOICES,
        method: METHOD_TYPES.GET,
        data,
        loaderText: "Loading...",
        loader: !data?.showLoader,
      },
      helpers
    )
);

export const generateElevenLabVoices = createAsyncThunk(
  "/integration/generateElevenLabVoice",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GENERATE_ELEVENLAB_VOICES,
        method: METHOD_TYPES.POST,
        data,
        loaderText: "Loading...",
        loader: !data?.showLoader,
      },
      helpers
    )
);

export const getIntegrateElevenLabsDetails = createAsyncThunk(
  "/integration/showElevenLabsKey",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET__INTEGRATE_ELEVENLABS_DETAILS,
        method: METHOD_TYPES.GET,
        data,
        loaderText: "Loading...",
      },
      helpers
    )
);

export const getIntegrateCalDetails = createAsyncThunk(
  "/integration/meetSchedulers/cal/integration",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_PUT_UPDATE_INTEGRATE_CAL_DETAILS,
        method: METHOD_TYPES.GET,
        loaderText: "Loading...",
        forceFEHideEnquebar:data.forceFEHideEnquebar
      },
      helpers
    )
);
export const fetchallCalEventsDetails = createAsyncThunk(
  "/integration/meetSchedulers/cal/fetchEvents",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: FETCH_ALL_CAL_EVENTS_DETAILS,
        method: METHOD_TYPES.GET,
        loaderText: "Loading...",
        data,
      },
      helpers
    )
);

export const updateIntegrateCalDetails = createAsyncThunk(
  "/integration/meetSchedulers/cal/integration",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_PUT_UPDATE_INTEGRATE_CAL_DETAILS,
        method: METHOD_TYPES.PUT,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const deleteElevenLabsIntegrateDetails = createAsyncThunk(
  "/integration/deleteElevenLabsKey",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: DELETE_ELEVENLABS_INTEGRATE_DETAILS,
        method: METHOD_TYPES.DELETE,
        modalLoader: true,
      },
      helpers
    )
);

export const deleteIntegrateCalDetails = createAsyncThunk(
  "/integration/meetSchedulers/cal/integration",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: GET_PUT_UPDATE_INTEGRATE_CAL_DETAILS,
        method: METHOD_TYPES.DELETE,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const verifyGoHighLevel = createAsyncThunk(
  "integration/verifyGoHighLevel",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: VERIFY_GOHIGHLEVEL,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);
export const syncContactListGoHighLevel = createAsyncThunk(
  "integration/syncContactList",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: SYNC_CONTACTLIST_FROM_GOHIGHLEVEL,
        method: METHOD_TYPES.POST,
        data,
        modalLoader: true,
      },
      helpers
    )
);

export const installGoHighLevel = createAsyncThunk(
  "integration/installGoHighLevel",
  async (data = {}, helpers) =>
    fetchDataAndProceedWithToolkit(
      {
        url: INSTALL_GOHIGHLEVEL,
        method: METHOD_TYPES.GET,
        data,
      },
      helpers
    )
);

export const { updateIntegrationState } = integrationSlice.actions;
export default integrationSlice.reducer;
